@import '../../assets/scss/global.scss';

.section__contact {

    padding: 100px 0px;

    @media (max-width: $breakpoint992) {
        padding: 80px 0px;
    }

    .contact {

        &__title {

            font-family: 'Soyuz Grotesk PRO';
            font-style: normal;
            font-weight: 700;
            font-size: 80px;
            letter-spacing: 0.025em;
            color: #000000;
            z-index: 10;

            @media (max-width: $breakpoint1200) {
                font-size: 55px;
                line-height: 60px;
            }

            @media (max-width: $breakpoint770) {
                font-size: 34px;
                line-height: 140%;
            }

            @media (max-width: $breakpoint480) {
                flex-wrap: wrap;
                line-height: 40px;
                font-size: 27px;
            }

            &-span {

                font-family: 'Street Soul Cyrillic', arial;
                color: #75FB5E;
                font-style: normal;
                font-weight: 400;
                font-size: 150px;
                line-height: 10px;
                letter-spacing: 0.015em;
                margin-right: 0px;
                margin-left: 5px;
                transform: translateY(30%);

                margin-left: 10px;

                @media (max-width: $breakpoint1200) {
                    font-size: 80px;

                }

                @media (max-width: $breakpoint770) {
                    font-size: 50px;
                    margin-right: 2px;
                }

                @media (max-width: $breakpoint480) {
                    font-size: 50px;
                    margin-right: 2px;

                }

            }




        }

        &__text {

            font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 24px;
            line-height: 34px;
            letter-spacing: -1.2px;
            margin-top: 60px;
            color: #141414;

            @media (max-width: $breakpoint770) {
                font-size: 22px;
                margin-top: 25px;
            }


        }

        &__type {

            margin-top: 45px;

            display: flex;
            flex-direction: column;

            @media (max-width: $breakpoint560) {
                margin-top: 25px;
            }

            &-text {

                font-family: 'Roboto';
                font-style: normal;
                font-weight: 400;
                font-size: 24px;
                line-height: 34px;
                letter-spacing: -1.2px;
                color: #141414;
                margin-top: 25px;

                @media (max-width: $breakpoint770) {
                    font-size: 22px;
                }

            }

            &-buttons {
                display: flex;
                align-items: center;
                gap: 13px;
                flex-wrap: wrap;
                margin-top: 20px;


                @media (max-width: $breakpoint1200) {
                    justify-content: flex-start;
                }


                &-inp {
                    width: 32%;
                    height: 60px;
                    font-size: 18px;

                    @media screen and (max-width: 705px) {
                        width: 100%;
                        height: 50px;
                        font-size: 16px;
                    }

                    // padding: 4px 7px;
                }

                &-btn {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 36px;
                    letter-spacing: -0.5px;
                    color: #828282;
                    border-radius: 3px;
                    padding: 4px 7px;
                    background-color: #00000000;
                    border: 1px solid #838383;
                    transition: all ease .3s;
                    white-space: nowrap;

                    // при нажатие на кнопку
                    &:focus,
                    &:active {
                        animation: blinkClick .2s linear;
                    }

                    // создать анимациюс название blink
                    @keyframes blinkClick {
                        0% {
                            transform: scale(1);
                        }

                        50% {
                            transform: scale(.8);
                        }

                        100% {
                            transform: scale(1);
                        }
                    }



                    &.active,
                    &:hover {
                        color: #65a959;
                        border: 1px solid #53de3b;
                    }

                    @media (max-width: $breakpoint770) {
                        flex: 0 1 48%;
                        max-width: 100%;
                        width: 100%;
                        padding: 5px 0px;
                        font-size: 18px;

                    }

                    @media (max-width: $breakpoint480) {
                        min-width: 100%;
                    }



                }

            }

            &-subtitle {
                max-width: 760px;
                width: 100%;
                margin-top: 20px;
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 400;
                font-size: 18px;
                line-height: 36px;
                letter-spacing: -0.5px;
                color: #828282;

                @media (max-width: $breakpoint770) {
                    // padding: 5px 20px;
                    font-size: 22px;
                }

                &::placeholder {
                    font-family: 'Roboto';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 24px;
                    line-height: 36px;
                    letter-spacing: -0.5px;
                    color: #828282;

                    @media (max-width: $breakpoint770) {
                        font-size: 22px;
                    }

                }

            }

            &-footer {
                display: flex;
                align-items: center;
                gap: 23px;

                margin-top: 80px;

                @media (max-width: $breakpoint560) {
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 18px;
                    margin-top: 50px;

                    & .start__copman-button {
                        width: 100% !important;
                    }
                }

                &-info {

                    display: flex;
                    flex-direction: column;

                    &-hr {
                        max-width: 172px;
                        width: 100%;
                        border: 1px solid #757575;
                    }

                    &-text {
                        font-family: 'Roboto';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 20px;
                        line-height: 27px;
                        letter-spacing: -0.5px;
                        color: #828282;
                        max-width: 380px;
                    }

                }

                & .start__copman-button {
                    width: 40%;
                    padding: 0px 60px;

                    &:hover {
                        .start__copman-button-text {
                            transform: translatex(-80%) !important;
                        }

                        ;
                    }
                }

            }

            & .uploadFile {
                margin-top: 50px;
            }
        }



    }


}

.cases {
    margin-top: 3rem;
    grid-column-gap: 60px;
    display: grid;
    grid-template-columns: 1fr 1fr;

    @media (max-width: $breakpoint560) {
        grid-template-columns: 1fr;
    }

    .case_left,
    .case_right {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 1rem;
    }

    .caseLink {
        margin-bottom: 3rem;
        width: 100%;
      // margin-bottom: 60px;
    }

    .case__content {
        padding-top: 1rem;

        .case__title {
            font-family: "Soyuz Grotesk PRO";
            font-style: normal;
            font-size: 2.2rem;
            letter-spacing: 0.025em;
            color: #000000;
            z-index: 10;
            width: 80%;
            gap: 1rem;
        }

        .case__text {
            font-family: "Roboto";
            font-style: normal;
            font-weight: 400;
            font-size: 0.9rem;
            line-height: 1.6rem;
            letter-spacing: -0.5px;
            color: #8F8F8F;
        }
    }
}

.case__img {
    .ant-skeleton {
        width: 100%;
        height: 100%;
    }
}