@import '../../../assets/scss/global.scss';

html.news {
    .NewsPages {
        padding-top: 9.5rem;
        display: flex;
        flex-direction: column;

        @media (max-width: $breakpoint770) {
            width: 95%;
            margin: 0 auto;
        }



        .newsHeader {
            width: 100%;
            height: max(15rem, 22vw);
            // сделать затемнение
            background:
                linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
                url('../../../assets/img/brend__item-img2.jpg') 0 0 no-repeat;
            background-size: cover;

            @media (max-width: $breakpoint1450) {
                height: max(15rem, 25vw);
            }

            @media (max-width: $breakpoint770) {
                height: max(25rem, 40vw);
            }




            .moreInfo {
                height: 100%;
                width: 67vw;
                margin: 0 auto;
                display: flex;
                flex-direction: column;
                justify-content: center;

                font-family: "Soyuz Grotesk PRO", Arial, Helvetica, sans-serif;
                font-style: normal;
                letter-spacing: 0.025em;
                color: #ffffff;
                // gap: 3rem;

                .category {
                    font-size: calc(2rem);
                    font-family: 'Courier New', Courier, monospace;

                    &:hover {
                        cursor: pointer;
                        color: #76fb5e;
                    }
                }

                .title {
                    font-size: 3rem;
                    margin-top: .3rem;
                }

                .desc {
                    font-size: 1.5rem;
                    font-weight: 300;
                    font-family: "Roboto", Arial, Helvetica, sans-serif;
                    margin-top: 2rem;
                    margin-bottom: 2rem;
                }

                .by {
                    font-size: 2rem;
                    font-weight: 800;
                    font-family: "Roboto", Arial, Helvetica, sans-serif;
                }

                .info {
                    font-family: "Roboto", Arial, Helvetica, sans-serif;
                    font-size: 2rem;

                    >span {
                        position: relative;

                        &:not(:first-child) {
                            margin-left: 8px;
                            padding-left: 12px;

                            &::before {
                                content: "";
                                display: block;
                                position: absolute;
                                left: 0px;
                                top: 50%;
                                width: 4px;
                                height: 4px;
                                background: rgb(168, 168, 168);
                                border-radius: 50%;
                                margin-top: -2px;

                            }
                        }
                    }
                }
            }
        }

        .newsblock {
            padding-top: 5rem;
            display: grid;
            width: 75%;
            margin: 0 auto;
            grid-template-columns: 1fr .4fr;
            grid-gap: 3rem;
            padding-bottom: 5rem;

            font-family: "Soyuz Grotesk PRO", Arial, Helvetica, sans-serif;
            margin: 0 auto;

            @media (max-width: $breakpoint770) {
                grid-template-columns: 1fr;
            }


        }

        .panelD {
            display: flex;
            flex-direction: column;
            gap: 5em;

            .author {
                display: flex;
                flex-direction: column;

                .header {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: 2rem;
                    margin-bottom: 2rem;

                    img {
                        width: 10rem;
                    }

                    span {
                        font-size: 24px;
                    }
                }

                .description {
                    font-size: 16px;
                    font-family: 'Roboto', Courier, monospace;
                    margin-bottom: 2rem;
                }

                .links {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: 2rem;

                    a {
                        font-size: 22px;
                        color: #ffffff;

                        &:hover {
                            color: #76fb5e;
                        }
                    }
                }
            }

            .contactUs {
                z-index: 9999;
                padding: 20px 30px;
                background: white;
                color: #000000;
                border-radius: 2px;

                h1 {
                    font-size: 34px;
                    font-weight: bold;
                    // font-size: 16px;
                    font-family: 'Roboto', Courier, monospace;
                    margin-bottom: 2rem;
                }

                p {
                    font-size: 16px;
                    font-family: 'Roboto', Courier, monospace;
                    margin-bottom: 2rem;
                }
                input[type="text"]{
                    width: 100%;
                    padding: 10px;
                    border: 1px solid #000000;
                    border-radius: 2px;
                    margin-bottom: 2rem;
                }
                button{
                    width: 100%;
                    padding: 10px;
                    border: 1px solid #000000;
                    border-radius: 2px;
                    background: #000000;
                    color: #ffffff;
                    font-size: 16px;
                    font-family: 'Roboto', Courier, monospace;
                    margin-bottom: 2rem;
                    &:hover{
                        cursor: pointer;
                        background: #76fb5e;
                        color: #000000;
                    }
                }
                    
                

            }
        }


    }

    &,
    &[data-theme=auto],
    *[data-theme=auto] {
        --color-primary: #1A1919;
        --color-secondary: #949494;
        --color-shadow: rgba(0, 0, 0, .14);
        --color-shadow-2: rgba(0, 0, 0, .1);
        --color-mute: rgba(0, 0, 0, .7);
        --color-mute-frame: rgba(255, 255, 255, .9);
        --color-bg-1: #FFFFFF;
        --color-bg-2: #F6F6F6;
        --color-bg-3: #AEAEAE;
        --color-bg-success: #F2F8F5;
        --color-bg-warning: #FDF5EB;
        --color-bg-alarm: #FFE9E9;
        --color-bg-blur: rgba(255, 255, 255, .6);
        --color-hover: #F6F5F5;
        --color-divider: #EFEFEF;
        --color-border: #EEEEEE;
        --color-highlight: #F7F5FE;
        --color-accent-alarm: #F13939;
        --color-accent-action: #5B41F5;
        --color-accent-warning: #FFC414;
        --color-accent-success: #11C177;
        --color-accent-lebedev: #BFC1FA;
        --color-accent-varlamov: #449DCC;
        --color-on-primary: #FFFFFF;
        --color-on-secondary: #FFFFFF;
        --color-on-accent: #FFFFFF;
        --color-on-social: #FFFFFF;
        --color-social-facebook: #2F4694;
        --color-social-google: #EA4335;
        --color-social-vk: #0077FF;
        --color-social-telegram: #0088CC;
        --color-social-twitch: #8F50FB;
        --color-social-telegraph: #F2F2F2;
        --color-social-livejournal: #00B0EA;
        --color-social-apple: #000000;
        --color-social-dzen: #000000;
        --color-primary-underline: rgba(26, 25, 25, .2);
        --color-accent-action-underline: rgba(91, 65, 245, .3);
        --shadow-main: 0px 18px 36px rgba(0, 0, 0, .14);
        --shadow-card: 0px 12px 52px rgba(0, 0, 0, .1);
        --autocolor-lightness: 60%;
        --autocolor-background-lightness: 95%
    }

    &[data-theme=light],
    *[data-theme=light] {
        --color-primary: #1A1919;
        --color-secondary: #949494;
        --color-shadow: rgba(0, 0, 0, .14);
        --color-shadow-2: rgba(0, 0, 0, .1);
        --color-mute: rgba(0, 0, 0, .7);
        --color-mute-frame: rgba(255, 255, 255, .9);
        --color-bg-1: #FFFFFF;
        --color-bg-2: #F6F6F6;
        --color-bg-3: #AEAEAE;
        --color-bg-success: #F2F8F5;
        --color-bg-warning: #FDF5EB;
        --color-bg-alarm: #FFE9E9;
        --color-bg-blur: rgba(255, 255, 255, .6);
        --color-hover: #F6F5F5;
        --color-divider: #EFEFEF;
        --color-border: #EEEEEE;
        --color-highlight: #F7F5FE;
        --color-accent-alarm: #F13939;
        --color-accent-action: #5B41F5;
        --color-accent-warning: #FFC414;
        --color-accent-success: #11C177;
        --color-accent-lebedev: #BFC1FA;
        --color-accent-varlamov: #449DCC;
        --color-on-primary: #FFFFFF;
        --color-on-secondary: #FFFFFF;
        --color-on-accent: #FFFFFF;
        --color-on-social: #FFFFFF;
        --color-social-facebook: #2F4694;
        --color-social-google: #EA4335;
        --color-social-vk: #0077FF;
        --color-social-telegram: #0088CC;
        --color-social-twitch: #8F50FB;
        --color-social-telegraph: #F2F2F2;
        --color-social-livejournal: #00B0EA;
        --color-social-apple: #000000;
        --color-social-dzen: #000000;
        --color-primary-underline: rgba(26, 25, 25, .2);
        --color-accent-action-underline: rgba(91, 65, 245, .3);
        --shadow-main: 0px 18px 36px rgba(0, 0, 0, .14);
        --shadow-card: 0px 12px 52px rgba(0, 0, 0, .1);
        --autocolor-lightness: 60%;
        --autocolor-background-lightness: 95%
    }

    &[data-theme=dark],
    *[data-theme=dark] {
        --color-primary: #EBEBEB;
        --color-secondary: #696969;
        --color-shadow: rgba(0, 0, 0, .48);
        --color-shadow-2: rgba(0, 0, 0, .1);
        --color-mute-frame: rgba(0, 0, 0, .9);
        --color-bg-1: #232323;
        --color-bg-2: #2B2B2C;
        --color-bg-3: #AEAEAE;
        --color-bg-success: #385B49;
        --color-bg-warning: #70583A;
        --color-bg-alarm: #633636;
        --color-bg-blur: rgba(30, 30, 30, .7);
        --color-hover: #313131;
        --color-divider: #323232;
        --color-border: #393939;
        --color-highlight: #2F2F4C;
        --color-accent-alarm: #F35151;
        --color-accent-action: #6D68E4;
        --color-accent-warning: #FFCB2E;
        --color-accent-success: #13D986;
        --color-accent-lebedev: #262963;
        --color-accent-varlamov: #58A7D1;
        --color-on-primary: #0D0D0D;
        --color-on-secondary: #0D0D0D;
        --color-primary-underline: rgba(235, 235, 235, .2);
        --color-accent-action-underline: rgba(109, 104, 228, .3);
        --shadow-main: 0px 4px 80px rgba(0, 0, 0, .48);
        --shadow-card: 0px 12px 52px rgba(0, 0, 0, .1);
        --autocolor-lightness: 40%;
        --autocolor-background-lightness: 20%
    }

    .article[data-v-9f50c0ab] {
        display: block;
        margin: 0;
        padding: 30px 0
    }

    .article__container[data-v-9f50c0ab] {
        text-decoration: none;
        margin: 0 auto;
        padding: 20px;
        border-bottom: 1px solid var(--color-divider, #EFEFEF);
        display: block;
        --document-column-size: 700px
    }

    body {
        --document-column-size: 700px;
    }

    .article__container[data-v-9f50c0ab]:first-child {
        padding-top: 0
    }

    .article__container[data-v-9f50c0ab]:last-child {
        border-bottom: 0
    }

    .article__container.m_secondary[data-v-9f50c0ab] {
        padding: 20px
    }

    .article__container.m_secondary[data-v-9f50c0ab]:first-child {
        padding-top: 0
    }

    @media screen and (max-width: 680px) {
        .article__container.m_secondary[data-v-9f50c0ab] {
            padding: 16px
        }
    }

    @media screen and (max-width: 680px) {
        .article__container[data-v-9f50c0ab] {
            padding-left: 16px;
            padding-right: 16px
        }
    }

    .article__status[data-v-9f50c0ab] {
        display: block;
        max-width: 700px;
        margin: 0 auto;
        padding-bottom: 30px
    }

    @media screen and (max-width: 680px) {
        .article__status[data-v-9f50c0ab] {
            padding-bottom: 20px
        }

        .article__status[data-v-9f50c0ab]:empty {
            display: none
        }
    }

    .article__author[data-v-9f50c0ab] {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        min-width: 0;
        max-width: var(--document-column-size, auto);
        margin: 0 auto 30px
    }

    .article__author_link[data-v-9f50c0ab] {
        display: flex;
        flex: 1 1 auto;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        min-width: 0;
        max-width: 100%;
        text-decoration: none
    }

    .article__author_userpic[data-v-9f50c0ab] {
        width: 48px;
        height: 48px;
        align-self: flex-start;
        margin-right: 10px
    }

    .article__author_content[data-v-9f50c0ab] {
        display: block;
        min-width: 0;
        max-width: 100%
    }

    .article__author_name[data-v-9f50c0ab] {
        display: block;
        font-family: Inter, Helvetica Neue, Helvetica, sans-serif;
        font-stretch: normal;
        font-weight: 700;
        font-style: normal;
        font-size: 1.8rem;
        line-height: 1.38888889em;
        color: var(--color-primary, #1A1919);
        text-align: left;
        word-wrap: break-word
    }

    .article__author_name_verified[data-v-9f50c0ab] {
        position: relative;
        display: inline-block;
        top: .31111111em;
        width: .77777778em;
        height: .77777778em;
        margin-left: .15em;
        vertical-align: top;
        //background-image: url(./verified.931e1f3b.svg);
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% 100%
    }

    .article__author_username[data-v-9f50c0ab] {
        display: block;
        overflow: hidden;
        font-family: Inter, Helvetica Neue, Helvetica, sans-serif;
        font-stretch: normal;
        font-weight: 700;
        font-style: normal;
        font-size: 1.8rem;
        line-height: 1.38888889em;
        color: var(--color-secondary, #949494);
        white-space: nowrap;
        text-align: left;
        text-overflow: ellipsis
    }

    .article__author_actions[data-v-9f50c0ab] {
        display: flex;
        flex: 0 1 auto;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: flex-end;
        margin: 0 -5px;
        padding-left: 16px
    }

    @media screen and (max-width: 960px) {
        .article__author_actions[data-v-9f50c0ab] {
            justify-content: flex-start;
            align-self: flex-start;
            margin: -5px;
            padding: 16px 0 0
        }
    }

    @media screen and (max-width: 680px) {
        .article__author_actions[data-v-9f50c0ab] {
            align-self: stretch
        }
    }

    .article__author_action[data-v-9f50c0ab] {
        margin: 5px;
        transition: opacity .2s
    }

    .article__author_action.m_hidden[data-v-9f50c0ab] {
        opacity: 0
    }

    @media screen and (max-width: 680px) {
        .article__author_action[data-v-9f50c0ab] {
            flex: 1 1 auto
        }
    }

    @media screen and (max-width: 960px) {
        .article__author[data-v-9f50c0ab] {
            flex-direction: column
        }
    }

    @media screen and (max-width: 680px) {
        .article__author[data-v-9f50c0ab] {
            margin-bottom: 25px
        }
    }

    .article__badges[data-v-9f50c0ab] {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        min-width: 0;
        max-width: var(--document-column-size, auto);
        margin: 0 auto 10px
    }

    .article__badges_item[data-v-9f50c0ab] {
        display: block;
        margin: 0 0 5px;
        font-family: Inter, Helvetica Neue, Helvetica, sans-serif;
        font-stretch: normal;
        font-weight: 500;
        font-style: normal;
        font-size: 1.7rem;
        line-height: 1.41176471em;
        color: var(--color-secondary, #949494);
        text-align: left;
        text-decoration: none
    }

    .article__badges_item.m_clickable[data-v-9f50c0ab] {
        cursor: pointer;
        transition: all .4s
    }

    .article__badges_item.m_clickable[data-v-9f50c0ab]:hover {
        color: var(--color-primary, #1A1919)
    }

    .article__badges.m_align_center[data-v-9f50c0ab] {
        align-items: center
    }

    .article__badges.m_align_right[data-v-9f50c0ab] {
        align-items: flex-end
    }

    .article__header[data-v-9f50c0ab] {
        display: block
    }

    .article__header_title[data-v-9f50c0ab] {
        display: block;
        min-width: 0;
        max-width: var(--document-column-size, auto);
        margin: 0 auto .78947368em;
        font-family: Inter, Helvetica Neue, Helvetica, sans-serif;
        font-stretch: normal;
        font-weight: 800;
        font-style: normal;
        font-size: 3.8rem;
        line-height: 1.15em;
        color: var(--color-primary, #1A1919);
        text-align: left;
        word-wrap: break-word
    }

    .article__header_title.m_align_center[data-v-9f50c0ab] {
        text-align: center
    }

    .article__header_title.m_align_right[data-v-9f50c0ab] {
        text-align: right
    }

    @media screen and (max-width: 680px) {
        .article__header_title[data-v-9f50c0ab] {
            font-size: 2.7rem;
            line-height: 1.4em;
            margin-bottom: .74074074em
        }
    }

    .article__content[data-v-9f50c0ab],
    .article__text[data-v-9f50c0ab] {
        display: block;
        margin-bottom: 20px;
        word-wrap: break-word;
        font-size: 1.7rem;
        line-height: 1.4em
    }

    .article__info[data-v-9f50c0ab] {
        min-width: 0;
        max-width: var(--document-column-size, auto);
        margin-left: auto;
        margin-right: auto;
        margin-top: 20px;
        border-top: 1px solid var(--color-divider, #EFEFEF)
    }

    .article__info_section[data-v-9f50c0ab] {
        margin: 20px 0
    }

    .article__info_section[data-v-9f50c0ab]:last-child {
        margin-bottom: 0
    }

    @media screen and (max-width: 680px) {
        .article__info[data-v-9f50c0ab] {
            margin-top: 16px
        }
    }

    .article__banner[data-v-9f50c0ab] {
        min-width: 0;
        max-width: var(--document-column-size, auto);
        margin-left: auto;
        margin-right: auto
    }

    .article__comments[data-v-9f50c0ab] {
        display: block;
        min-width: 0;
        max-width: var(--document-column-size, auto);
        margin: 0 auto
    }

    .article.m_wide .article__container[data-v-9f50c0ab] {
        display: block;
        --document-column-size: 900px
    }

    .landing[data-v-b3511eb0] {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        justify-content: flex-start;
        width: 100%;
        min-height: 100vh;
        box-sizing: border-box
    }

    .landing__menu[data-v-b3511eb0] {
        display: block
    }

    .landing__art[data-v-b3511eb0] {
        position: relative;
        display: block;
        justify-content: center;
        align-items: center;
        padding: 0;
        margin: 0;
        overflow: hidden;
        background-size: auto 60px;
        background-position: center center
    }

    .landing__art_fade[data-v-b3511eb0] {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(to left, var(--color-bg-1, #FFFFFF) 0%, transparent 20%, transparent 80%, var(--color-bg-1, #FFFFFF) 100%)
    }

    .landing__art_wrap[data-v-b3511eb0] {
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        max-width: 1200px;
        margin: 0 -50px
    }

    .landing__art_img[data-v-b3511eb0] {
        height: auto;
        flex: 1 1 auto;
        min-width: 0;
        max-width: 100%;
        vertical-align: top
    }

    .landing__art_img[data-v-b3511eb0]:first-child {
        margin-left: 0
    }

    @media screen and (max-width: 680px) {
        .landing__art[data-v-b3511eb0] {
            padding-left: 16px;
            padding-right: 16px;
            background-size: auto 30px
        }
    }

    .landing__header[data-v-b3511eb0] {
        display: block;
        margin-bottom: 120px
    }

    .landing__header_art[data-v-b3511eb0] {
        display: flex;
        margin: 100px 0
    }

    @media screen and (max-width: 680px) {
        .landing__header_art[data-v-b3511eb0] {
            margin-top: 50px;
            margin-bottom: 50px
        }
    }

    .landing__header_content[data-v-b3511eb0] {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0 20px
    }

    @media screen and (max-width: 680px) {
        .landing__header_content[data-v-b3511eb0] {
            padding-left: 16px;
            padding-right: 16px
        }
    }

    .landing__header_title[data-v-b3511eb0] {
        display: block;
        margin: 0 auto;
        max-width: 1200px;
        font-family: Inter, Helvetica Neue, Helvetica, sans-serif;
        font-stretch: normal;
        font-weight: 700;
        font-style: normal;
        font-size: 9.6rem;
        line-height: 1.30208333em;
        color: var(--color-primary, #1A1919);
        text-align: center
    }

    @media screen and (max-width: 1200px) {
        .landing__header_title[data-v-b3511eb0] {
            font-size: 7.2rem
        }
    }

    @media screen and (max-width: 960px) {
        .landing__header_title[data-v-b3511eb0] {
            font-size: 6.4rem
        }
    }

    @media screen and (max-width: 680px) {
        .landing__header_title[data-v-b3511eb0] {
            font-size: 3.2rem
        }
    }

    .landing__header_description[data-v-b3511eb0] {
        display: block;
        margin: .75em auto 0;
        max-width: 960px;
        font-family: Inter, Helvetica Neue, Helvetica, sans-serif;
        font-stretch: normal;
        font-weight: 500;
        font-style: normal;
        font-size: 3.2rem;
        line-height: 1.4375em;
        color: var(--color-primary, #1A1919);
        text-align: center
    }

    @media screen and (max-width: 960px) {
        .landing__header_description[data-v-b3511eb0] {
            font-size: 2.5rem
        }
    }

    @media screen and (max-width: 680px) {
        .landing__header_description[data-v-b3511eb0] {
            font-size: 1.8rem
        }
    }

    .landing__header_button[data-v-b3511eb0] {
        display: block;
        margin: 56px auto 0
    }

    @media screen and (max-width: 680px) {
        .landing__header_button[data-v-b3511eb0] {
            margin-top: 32px
        }
    }

    @media screen and (max-width: 680px) {
        .landing__header[data-v-b3511eb0] {
            margin-bottom: 80px
        }
    }

    .landing__content[data-v-b3511eb0] {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0 20px;
        margin: 0 0 120px
    }

    @media screen and (max-width: 680px) {
        .landing__content[data-v-b3511eb0] {
            padding-left: 16px;
            padding-right: 16px;
            margin-bottom: 56px
        }
    }

    .landing__content_features[data-v-b3511eb0] {
        display: block;
        max-width: 960px;
        margin: 0 auto;
        padding: 0;
        list-style-type: none
    }

    .landing__content_button[data-v-b3511eb0] {
        margin-top: 120px
    }

    @media screen and (max-width: 680px) {
        .landing__content_button[data-v-b3511eb0] {
            margin-top: 56px
        }
    }

    .landing__feature[data-v-b3511eb0] {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin: 0 0 56px
    }

    .landing__feature[data-v-b3511eb0]:last-child {
        margin-bottom: 0
    }

    @media screen and (max-width: 680px) {
        .landing__feature[data-v-b3511eb0] {
            margin-bottom: 32px
        }
    }

    .landing__feature_icon[data-v-b3511eb0] {
        display: block;
        flex: 0 0 auto;
        align-self: flex-start;
        width: 120px;
        height: 120px;
        margin-right: 24px;
        border-radius: 100%;
        color: #b0a3fa
    }

    .landing__feature_icon.m_muted[data-v-b3511eb0] {
        opacity: .3
    }

    @media screen and (max-width: 680px) {
        .landing__feature_icon[data-v-b3511eb0] {
            align-self: center;
            width: 100px;
            height: 100px;
            margin-right: 0;
            margin-bottom: 16px
        }
    }

    .landing__feature_content[data-v-b3511eb0] {
        display: block;
        flex: 1 1 auto
    }

    .landing__feature_title[data-v-b3511eb0] {
        display: flex;
        flex-direction: row;
        align-items: center;
        font-family: Inter, Helvetica Neue, Helvetica, sans-serif;
        font-stretch: normal;
        font-weight: 700;
        font-style: normal;
        font-size: 2.8rem;
        line-height: 1.14285714em;
        color: var(--color-primary, #1A1919);
        text-align: left
    }

    .landing__feature_title.m_muted[data-v-b3511eb0],
    .landing__feature_title .m_muted[data-v-b3511eb0] {
        opacity: .3
    }

    @media screen and (max-width: 680px) {
        .landing__feature_title_text[data-v-b3511eb0] {
            order: 1
        }
    }

    .landing__feature_title_soon[data-v-b3511eb0] {
        display: inline-block;
        margin-left: 1em;
        padding: .26666667em .53333333em;
        border-radius: .4em;
        background: var(--color-highlight, #F7F5FE);
        font-family: Inter, Helvetica Neue, Helvetica, sans-serif;
        font-stretch: normal;
        font-weight: 600;
        font-style: normal;
        font-size: 1.5rem;
        line-height: 1.46666667em;
        color: var(--color-accent-action, #5B41F5);
        text-align: left;
        text-decoration: none
    }

    @media screen and (max-width: 680px) {
        .landing__feature_title_soon[data-v-b3511eb0] {
            margin-left: 0;
            margin-bottom: .53333333em
        }
    }

    @media screen and (max-width: 960px) {
        .landing__feature_title[data-v-b3511eb0] {
            font-size: 2.4rem;
            line-height: 1.33333333em
        }
    }

    @media screen and (max-width: 680px) {
        .landing__feature_title[data-v-b3511eb0] {
            flex-direction: column;
            text-align: center
        }
    }

    .landing__feature_text[data-v-b3511eb0] {
        display: block;
        margin-top: 8px;
        font-family: Inter, Helvetica Neue, Helvetica, sans-serif;
        font-stretch: normal;
        font-weight: 500;
        font-style: normal;
        font-size: 2.4rem;
        line-height: 1.45833333em;
        color: var(--color-secondary, #949494);
        text-align: left
    }

    .landing__feature_text.m_muted[data-v-b3511eb0] {
        opacity: .3
    }

    @media screen and (max-width: 960px) {
        .landing__feature_text[data-v-b3511eb0] {
            font-size: 1.8rem;
            line-height: 1.38888889em
        }
    }

    @media screen and (max-width: 680px) {
        .landing__feature_text[data-v-b3511eb0] {
            text-align: center
        }
    }

    .landing__feature_link[data-v-b3511eb0] {
        display: block;
        margin-top: 16px;
        font-family: Inter, Helvetica Neue, Helvetica, sans-serif;
        font-stretch: normal;
        font-weight: 500;
        font-style: normal;
        font-size: 2.4rem;
        line-height: 1.45833333em;
        color: var(--color-accent-action, #5B41F5);
        text-align: left;
        text-decoration: none
    }

    .landing__feature_link.m_muted[data-v-b3511eb0] {
        opacity: .3
    }

    @media screen and (max-width: 960px) {
        .landing__feature_link[data-v-b3511eb0] {
            font-size: 1.8rem;
            line-height: 1.38888889em
        }
    }

    @media screen and (max-width: 680px) {
        .landing__feature_link[data-v-b3511eb0] {
            text-align: center
        }
    }

    @media screen and (max-width: 680px) {
        .landing__feature[data-v-b3511eb0] {
            flex-direction: column;
            margin-bottom: 48px
        }
    }

    .landing__footer[data-v-b3511eb0] {
        display: block;
        padding: 64px 20px 40px;
        border-top: 1px solid var(--color-divider, #EFEFEF)
    }

    .landing__footer_wrap[data-v-b3511eb0] {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: flex-start;
        max-width: 1200px;
        margin: 0 auto
    }

    @media screen and (max-width: 680px) {
        .landing__footer_wrap[data-v-b3511eb0] {
            flex-direction: column;
            align-items: center
        }
    }

    .landing__footer_content[data-v-b3511eb0] {
        display: flex;
        flex: 1 1 auto;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: flex-start;
        margin: 0 auto
    }

    @media screen and (max-width: 680px) {
        .landing__footer_content[data-v-b3511eb0] {
            flex-direction: column;
            align-items: center
        }
    }

    .landing__footer_section[data-v-b3511eb0] {
        display: flex;
        flex: 1 1 120px;
        flex-direction: column;
        max-width: 200px;
        margin: 0 48px 40px 0
    }

    .landing__footer_section.m_last[data-v-b3511eb0] {
        margin-bottom: 0
    }

    .landing__footer_section.m_max[data-v-b3511eb0] {
        width: 100%;
        min-width: 100%;
        margin-right: 0
    }

    @media screen and (max-width: 680px) {
        .landing__footer_section[data-v-b3511eb0] {
            flex-basis: auto;
            margin-right: 0
        }
    }

    .landing__footer_logo[data-v-b3511eb0] {
        display: inline-block
    }

    @media screen and (max-width: 680px) {
        .landing__footer_logo[data-v-b3511eb0] {
            margin-bottom: 32px
        }
    }

    .landing__footer_label[data-v-b3511eb0] {
        display: block;
        margin-bottom: 16px;
        font-family: Inter, Helvetica Neue, Helvetica, sans-serif;
        font-stretch: normal;
        font-weight: 600;
        font-style: normal;
        font-size: 1.5rem;
        line-height: 1.33333333em;
        color: var(--color-secondary, #949494);
        text-align: left
    }

    @media screen and (max-width: 680px) {
        .landing__footer_label[data-v-b3511eb0] {
            text-align: center
        }
    }

    .landing__footer_link[data-v-b3511eb0],
    .landing__footer_item[data-v-b3511eb0] {
        display: block;
        margin: .53333333em 0;
        font-family: Inter, Helvetica Neue, Helvetica, sans-serif;
        font-stretch: normal;
        font-weight: 500;
        font-style: normal;
        font-size: 1.5rem;
        line-height: 1.33333333em;
        color: var(--color-primary, #1A1919);
        text-align: left;
        text-decoration: none
    }

    @media screen and (max-width: 680px) {

        .landing__footer_link[data-v-b3511eb0],
        .landing__footer_item[data-v-b3511eb0] {
            text-align: center
        }
    }

    .landing__footer_note[data-v-b3511eb0] {
        display: inline-block;
        font-family: Inter, Helvetica Neue, Helvetica, sans-serif;
        font-stretch: normal;
        font-weight: 400;
        font-style: normal;
        font-size: 1.5rem;
        line-height: 1.33333333em;
        color: var(--color-secondary, #949494);
        text-align: left;
        text-decoration: none;
        transition: all .4s;
        cursor: pointer
    }

    .landing__footer_note[data-v-b3511eb0]:hover {
        color: var(--color-primary, #1A1919)
    }

    .landing__footer_note_icon[data-v-b3511eb0] {
        display: inline-block;
        vertical-align: top
    }

    @media screen and (max-width: 680px) {
        .landing__footer_note[data-v-b3511eb0] {
            text-align: center
        }
    }

    .landing__footer_socials[data-v-b3511eb0] {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap
    }

    @media screen and (max-width: 680px) {
        .landing__footer_socials[data-v-b3511eb0] {
            justify-content: center
        }
    }

    .landing__footer_social[data-v-b3511eb0] {
        display: inline-block;
        width: 32px;
        height: 32px;
        margin: 0 16px 16px 0;
        border-radius: 100%;
        border: 1px solid var(--color-secondary, #949494);
        color: var(--color-primary, #1A1919);
        transition: all .4s;
        cursor: pointer
    }

    .landing__footer_social[data-v-b3511eb0]:hover {
        border-color: var(--color-primary, #1A1919)
    }

    .landing__footer_social[data-v-b3511eb0]:last-child {
        margin-right: 0
    }

    @media screen and (max-width: 680px) {
        .landing__footer[data-v-b3511eb0] {
            padding-left: 16px;
            padding-right: 16px
        }
    }

    .listCard[data-v-23b902ed] {
        display: block;
        width: 100%;
        padding: 20px;
        box-sizing: border-box;
        border-radius: 24px;
        background-color: var(--color-bg-1, #FFFFFF)
    }

    .listCard__title[data-v-23b902ed] {
        display: block;
        margin-bottom: .83333333em;
        font-family: Inter, Helvetica Neue, Helvetica, sans-serif;
        font-stretch: normal;
        font-weight: 600;
        font-style: normal;
        font-size: 1.8rem;
        line-height: 1.4em;
        color: var(--color-primary, #1A1919);
        text-align: left
    }

    .listCard__empty[data-v-23b902ed] {
        display: block;
        margin: 25px 0;
        color: var(--color-secondary, #949494);
        text-align: center
    }

    .listCard__footer[data-v-23b902ed] {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-top: 20px;
        border-top: 1px solid var(--color-divider, #EFEFEF)
    }

    @media screen and (max-width: 680px) {
        .listCard[data-v-23b902ed] {
            padding: 16px
        }

        .listCard__footer[data-v-23b902ed] {
            margin-left: 16px;
            margin-right: 16px;
            padding-top: 16px
        }
    }

    .feedFeatured__blog[data-v-fdf66c88] {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        margin: 15px 0;
        padding: 5px 20px;
        border-radius: 7px;
        background: transparent;
        text-decoration: none;
        cursor: pointer;
        transition: all .4s
    }

    .feedFeatured__blog_aside[data-v-fdf66c88] {
        display: block;
        flex: 0 0 auto;
        align-self: flex-start;
        margin-right: 10px
    }

    .feedFeatured__blog_content[data-v-fdf66c88] {
        display: flex;
        flex: 1 1 auto;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        max-width: 100%;
        min-width: 0
    }

    .feedFeatured__blog_info[data-v-fdf66c88] {
        display: block;
        flex: 1 1 auto;
        min-width: 0
    }

    .feedFeatured__blog_name[data-v-fdf66c88],
    .feedFeatured__blog_username[data-v-fdf66c88],
    .feedFeatured__blog_bio[data-v-fdf66c88] {
        display: block;
        min-width: 0;
        max-width: 100%;
        overflow: hidden;
        font-family: Inter, Helvetica Neue, Helvetica, sans-serif;
        font-stretch: normal;
        font-weight: 400;
        font-style: normal;
        font-size: 1.5rem;
        line-height: 1.4em;
        color: var(--color-primary, #1A1919);
        text-align: left;
        text-overflow: ellipsis;
        white-space: nowrap
    }

    .feedFeatured__blog_name[data-v-fdf66c88] {
        font-family: Inter, Helvetica Neue, Helvetica, sans-serif;
        font-stretch: normal;
        font-weight: 600;
        font-style: normal
    }

    .feedFeatured__blog_name_verified[data-v-fdf66c88] {
        position: relative;
        display: inline-block;
        top: .23333333em;
        width: .93333333em;
        height: .93333333em;
        margin-left: .15em;
        vertical-align: top;
        //background-image: url(./verified.931e1f3b.svg);
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% 100%
    }

    .feedFeatured__blog_username[data-v-fdf66c88] {
        color: var(--color-secondary, #949494)
    }

    .feedFeatured__blog_action[data-v-fdf66c88] {
        display: block;
        flex: 0 0 auto;
        margin-left: 10px
    }

    .feedFeatured__blog[data-v-fdf66c88]:last-child {
        margin-bottom: 0
    }

    @media screen and (max-width: 680px) {
        .feedFeatured__blog[data-v-fdf66c88] {
            padding-left: 16px;
            padding-right: 16px
        }
    }

    .feedArticleCard[data-v-c09dc619] {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        justify-content: flex-start;
        width: 100%;
        min-height: 0;
        padding: 20px;
        box-sizing: border-box;
        text-decoration: none;
        border-radius: 10px;
        background-color: var(--color-bg-1, #FFFFFF);
        transition: all .2s, transform 0s;
        cursor: pointer
    }

    .feedArticleCard__header[data-v-c09dc619] {
        display: flex;
        flex: 0 0 auto;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 10px
    }

    .feedArticleCard__header_userpic[data-v-c09dc619] {
        display: block;
        flex: 0 0 auto;
        align-self: flex-start;
        width: 40px;
        height: 40px;
        margin-right: 10px
    }

    .feedArticleCard__header_author[data-v-c09dc619] {
        display: block;
        flex: 1 1 auto;
        min-width: 0
    }

    .feedArticleCard__header_name[data-v-c09dc619],
    .feedArticleCard__header_username[data-v-c09dc619] {
        display: block;
        min-width: 0;
        max-width: 100%;
        overflow: hidden;
        font-family: Inter, Helvetica Neue, Helvetica, sans-serif;
        font-stretch: normal;
        font-weight: 500;
        font-style: normal;
        font-size: 1.4rem;
        line-height: 1.4em;
        color: var(--color-primary, #1A1919);
        text-align: left;
        text-overflow: ellipsis;
        white-space: nowrap
    }

    .feedArticleCard__header_name_verified[data-v-c09dc619] {
        position: relative;
        display: inline-block;
        top: .23333333em;
        width: .93333333em;
        height: .93333333em;
        margin-left: .15em;
        vertical-align: top;
        //background-image: url(./verified.931e1f3b.svg);
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% 100%
    }

    .feedArticleCard__header_username[data-v-c09dc619] {
        color: var(--color-secondary, #949494)
    }

    .feedArticleCard__header_date[data-v-c09dc619] {
        display: block;
        flex: 0 0 auto;
        align-self: flex-start;
        font-family: Inter, Helvetica Neue, Helvetica, sans-serif;
        font-stretch: normal;
        font-weight: 500;
        font-style: normal;
        font-size: 1.4rem;
        line-height: 1.4em;
        color: var(--color-secondary, #949494);
        text-align: right;
        opacity: .6
    }

    .feedArticleCard__content[data-v-c09dc619] {
        flex: 1 1 auto;
        min-height: 0
    }

    .feedArticleCard__title[data-v-c09dc619] {
        display: block;
        margin: 0 0 .35em;
        padding: 0;
        font-family: Inter, Helvetica Neue, Helvetica, sans-serif;
        font-stretch: normal;
        font-weight: 700;
        font-style: normal;
        font-size: 2rem;
        line-height: 1.4em;
        color: var(--color-primary, #1A1919);
        text-decoration: none;
        word-wrap: break-word
    }

    .feedArticleCard__text[data-v-c09dc619] {
        display: block;
        margin: 0 0 10px;
        font-size: 1.7rem;
        line-height: 1.4em;
        color: var(--color-primary, #1A1919);
        word-wrap: break-word
    }

    [data-v-c09dc619] .feedArticleCard__text p {
        min-width: 0;
        max-width: var(--document-column-size, auto);
        margin: 0 auto .58823529em;
        min-height: 1.5em;
        font-family: Inter, Helvetica Neue, Helvetica, sans-serif;
        font-stretch: normal;
        font-weight: 400;
        font-style: normal;
        font-size: 1em;
        line-height: 1.5em;
        color: var(--color-primary, #1A1919);
        white-space: pre-wrap
    }

    [data-v-c09dc619] .feedArticleCard__text p:first-child {
        margin-top: 0
    }

    [data-v-c09dc619] .feedArticleCard__text p:last-child {
        margin-bottom: 0
    }

    [data-v-c09dc619] .feedArticleCard__text p[data-align=right] {
        text-align: right
    }

    [data-v-c09dc619] .feedArticleCard__text p[data-align=center] {
        text-align: center
    }

    [data-v-c09dc619] .feedArticleCard__text blockquote {
        position: relative;
        display: block;
        min-width: 0;
        max-width: var(--document-column-size, auto);
        margin: 0 auto .58823529em;
        min-height: 1.5em;
        padding-left: .70588235em;
        box-sizing: border-box;
        font-family: Inter, Helvetica Neue, Helvetica, sans-serif;
        font-stretch: normal;
        font-weight: 400;
        font-style: normal;
        font-size: 1em;
        line-height: 1.5em;
        color: var(--color-primary, #1A1919);
        white-space: pre-wrap;
        opacity: .7
    }

    [data-v-c09dc619] .feedArticleCard__text blockquote:first-child {
        margin-top: 0
    }

    [data-v-c09dc619] .feedArticleCard__text blockquote:last-child {
        margin-bottom: 0
    }

    [data-v-c09dc619] .feedArticleCard__text blockquote[data-align=right] {
        text-align: right
    }

    [data-v-c09dc619] .feedArticleCard__text blockquote[data-align=center] {
        text-align: center
    }

    [data-v-c09dc619] .feedArticleCard__text blockquote:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 2px;
        border-radius: 2px;
        background: var(--color-primary, #1A1919)
    }

    [data-v-c09dc619] .feedArticleCard__text blockquote[data-align=right] {
        padding-left: 0;
        padding-right: .70588235em
    }

    [data-v-c09dc619] .feedArticleCard__text blockquote[data-align=right]:before {
        left: auto;
        right: 0
    }

    [data-v-c09dc619] .feedArticleCard__text blockquote[data-align=center] {
        padding-left: 0;
        padding-top: .58823529em
    }

    [data-v-c09dc619] .feedArticleCard__text blockquote[data-align=center]:before {
        top: 0;
        left: 50%;
        bottom: auto;
        width: 64px;
        height: 2px;
        margin-left: -32px
    }

    [data-v-c09dc619] .feedArticleCard__text pre {
        display: block;
        min-width: 0;
        max-width: var(--document-column-size, auto);
        margin: 0 auto .58823529em;
        min-height: 1.5em;
        padding: .5em;
        box-sizing: border-box;
        border-radius: .5em;
        overflow: auto;
        background-color: var(--color-bg-2, #F6F6F6);
        font-family: FiraCode, SFMono-Regular, Consolas, Liberation Mono, Menlo, Monaco, Courier, monospace;
        font-stretch: normal;
        font-weight: 400;
        font-style: normal;
        letter-spacing: normal;
        font-feature-settings: "ss01"off, "ss02"off, "ss03"off, "ss04"on, "ss05"off, "ss06"on, "ss07"off, "zero"off, "onum"off;
        font-size: .87em;
        color: var(--color-primary, #1A1919);
        line-height: 1.5em;
        text-align: left;
        white-space: pre;
        word-spacing: normal;
        word-break: normal;
        word-wrap: normal;
        -moz-tab-size: 4;
        tab-size: 4;
        -webkit-hyphens: none;
        -moz-hyphens: none;
        hyphens: none
    }

    [data-v-c09dc619] .feedArticleCard__text pre:first-child {
        margin-top: 0
    }

    [data-v-c09dc619] .feedArticleCard__text pre:last-child {
        margin-bottom: 0
    }

    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text code[data-lang],
    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text pre[data-lang] {
        color: #383a42
    }

    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text code[data-lang] .token.comment,
    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .token.comment,
    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text code[data-lang] .token.prolog,
    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .token.prolog,
    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text code[data-lang] .token.cdata,
    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .token.cdata {
        color: #a0a1a7
    }

    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text code[data-lang] .token.doctype,
    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .token.doctype,
    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text code[data-lang] .token.punctuation,
    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .token.punctuation,
    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text code[data-lang] .token.entity,
    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .token.entity {
        color: #383a42
    }

    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text code[data-lang] .token.attr-name,
    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .token.attr-name,
    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text code[data-lang] .token.class-name,
    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .token.class-name,
    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text code[data-lang] .token.boolean,
    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .token.boolean,
    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text code[data-lang] .token.constant,
    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .token.constant,
    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text code[data-lang] .token.number,
    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .token.number,
    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text code[data-lang] .token.atrule,
    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .token.atrule {
        color: #b76b01
    }

    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text code[data-lang] .token.keyword,
    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .token.keyword {
        color: #a626a4
    }

    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text code[data-lang] .token.property,
    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .token.property,
    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text code[data-lang] .token.tag,
    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .token.tag,
    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text code[data-lang] .token.symbol,
    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .token.symbol,
    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text code[data-lang] .token.deleted,
    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .token.deleted,
    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text code[data-lang] .token.important,
    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .token.important {
        color: #e45649
    }

    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text code[data-lang] .token.selector,
    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .token.selector,
    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text code[data-lang] .token.string,
    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .token.string,
    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text code[data-lang] .token.char,
    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .token.char,
    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text code[data-lang] .token.builtin,
    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .token.builtin,
    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text code[data-lang] .token.inserted,
    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .token.inserted,
    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text code[data-lang] .token.regex,
    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .token.regex,
    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text code[data-lang] .token.attr-value,
    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .token.attr-value,
    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text code[data-lang] .token.attr-value>.token.punctuation,
    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .token.attr-value>.token.punctuation {
        color: #50a14f
    }

    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text code[data-lang] .token.variable,
    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .token.variable,
    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text code[data-lang] .token.operator,
    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .token.operator,
    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text code[data-lang] .token.function,
    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .token.function {
        color: #4078f2
    }

    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text code[data-lang] .token.url,
    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .token.url {
        color: #0184bc
    }

    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text code[data-lang] .token.attr-value>.token.punctuation.attr-equals,
    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .token.attr-value>.token.punctuation.attr-equals,
    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text code[data-lang] .token.special-attr>.token.attr-value>.token.value.css,
    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .token.special-attr>.token.attr-value>.token.value.css {
        color: #383a42
    }

    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text code[data-lang][data-lang=css] .token.selector,
    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text pre[data-lang][data-lang=css] .token.selector,
    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text code[data-lang] .language-css .token.selector,
    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .language-css .token.selector {
        color: #e45649
    }

    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text code[data-lang][data-lang=css] .token.property,
    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text pre[data-lang][data-lang=css] .token.property,
    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text code[data-lang] .language-css .token.property,
    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .language-css .token.property {
        color: #383a42
    }

    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text code[data-lang][data-lang=css] .token.function,
    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text pre[data-lang][data-lang=css] .token.function,
    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text code[data-lang] .language-css .token.function,
    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .language-css .token.function,
    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text code[data-lang][data-lang=css] .token.url>.token.function,
    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text pre[data-lang][data-lang=css] .token.url>.token.function,
    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text code[data-lang] .language-css .token.url>.token.function,
    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .language-css .token.url>.token.function {
        color: #0184bc
    }

    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text code[data-lang][data-lang=css] .token.url>.token.string.url,
    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text pre[data-lang][data-lang=css] .token.url>.token.string.url,
    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text code[data-lang] .language-css .token.url>.token.string.url,
    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .language-css .token.url>.token.string.url {
        color: #50a14f
    }

    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text code[data-lang][data-lang=css] .token.important,
    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text pre[data-lang][data-lang=css] .token.important,
    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text code[data-lang] .language-css .token.important,
    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .language-css .token.important,
    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text code[data-lang][data-lang=css] .token.atrule .token.rule,
    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text pre[data-lang][data-lang=css] .token.atrule .token.rule,
    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text code[data-lang] .language-css .token.atrule .token.rule,
    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .language-css .token.atrule .token.rule,
    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text code[data-lang][data-lang=javascript] .token.operator .language-javascript .token.operator,
    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text pre[data-lang][data-lang=javascript] .token.operator .language-javascript .token.operator {
        color: #a626a4
    }

    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text code[data-lang][data-lang=javascript] .token.template-string>.token.interpolation>.token.interpolation-punctuation.punctuation,
    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text pre[data-lang][data-lang=javascript] .token.template-string>.token.interpolation>.token.interpolation-punctuation.punctuation,
    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text code[data-lang] .language-javascript .token.template-string>.token.interpolation>.token.interpolation-punctuation.punctuation,
    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .language-javascript .token.template-string>.token.interpolation>.token.interpolation-punctuation.punctuation {
        color: #ca1243
    }

    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text code[data-lang][data-lang=json] .token.operator,
    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text pre[data-lang][data-lang=json] .token.operator,
    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text code[data-lang] .language-json .token.operator,
    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .language-json .token.operator {
        color: #383a42
    }

    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text code[data-lang][data-lang=json] .token.null.keyword,
    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text pre[data-lang][data-lang=json] .token.null.keyword,
    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text code[data-lang] .language-json .token.null.keyword,
    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .language-json .token.null.keyword {
        color: #b76b01
    }

    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text code[data-lang][data-lang=markdown] .token.url,
    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text pre[data-lang][data-lang=markdown] .token.url,
    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text code[data-lang] .language-markdown .token.url,
    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .language-markdown .token.url,
    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text code[data-lang][data-lang=markdown] .token.url>.token.operator,
    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text pre[data-lang][data-lang=markdown] .token.url>.token.operator,
    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text code[data-lang] .language-markdown .token.url>.token.operator,
    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .language-markdown .token.url>.token.operator,
    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text code[data-lang][data-lang=markdown] .token.url-reference.url>.token.string,
    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text pre[data-lang][data-lang=markdown] .token.url-reference.url>.token.string,
    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text code[data-lang] .language-markdown .token.url-reference.url>.token.string,
    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .language-markdown .token.url-reference.url>.token.string {
        color: #383a42
    }

    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text code[data-lang][data-lang=markdown] .token.url>.token.content,
    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text pre[data-lang][data-lang=markdown] .token.url>.token.content,
    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text code[data-lang] .language-markdown .token.url>.token.content,
    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .language-markdown .token.url>.token.content {
        color: #4078f2
    }

    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text code[data-lang][data-lang=markdown] .token.url>.token.url,
    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text pre[data-lang][data-lang=markdown] .token.url>.token.url,
    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text code[data-lang] .language-markdown .token.url>.token.url,
    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .language-markdown .token.url>.token.url,
    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text code[data-lang][data-lang=markdown] .token.url-reference.url,
    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text pre[data-lang][data-lang=markdown] .token.url-reference.url,
    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text code[data-lang] .language-markdown .token.url-reference.url,
    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .language-markdown .token.url-reference.url {
        color: #0184bc
    }

    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text code[data-lang][data-lang=markdown] .token.blockquote.punctuation,
    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text pre[data-lang][data-lang=markdown] .token.blockquote.punctuation,
    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text code[data-lang] .language-markdown .token.blockquote.punctuation,
    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .language-markdown .token.blockquote.punctuation,
    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text code[data-lang][data-lang=markdown] .token.hr.punctuation,
    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text pre[data-lang][data-lang=markdown] .token.hr.punctuation,
    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text code[data-lang] .language-markdown .token.hr.punctuation,
    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .language-markdown .token.hr.punctuation {
        color: #a0a1a7;
        font-style: italic
    }

    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text code[data-lang][data-lang=markdown] .token.code-snippet,
    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text pre[data-lang][data-lang=markdown] .token.code-snippet,
    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text code[data-lang] .language-markdown .token.code-snippet,
    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .language-markdown .token.code-snippet {
        color: #50a14f
    }

    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text code[data-lang][data-lang=markdown] .token.bold .token.content,
    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text pre[data-lang][data-lang=markdown] .token.bold .token.content,
    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text code[data-lang] .language-markdown .token.bold .token.content,
    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .language-markdown .token.bold .token.content {
        color: #b76b01
    }

    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text code[data-lang][data-lang=markdown] .token.italic .token.content,
    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text pre[data-lang][data-lang=markdown] .token.italic .token.content,
    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text code[data-lang] .language-markdown .token.italic .token.content,
    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .language-markdown .token.italic .token.content {
        color: #a626a4
    }

    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text code[data-lang][data-lang=markdown] .token.strike .token.content,
    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text pre[data-lang][data-lang=markdown] .token.strike .token.content,
    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text code[data-lang] .language-markdown .token.strike .token.content,
    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .language-markdown .token.strike .token.content,
    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text code[data-lang][data-lang=markdown] .token.strike .token.punctuation,
    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text pre[data-lang][data-lang=markdown] .token.strike .token.punctuation,
    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text code[data-lang] .language-markdown .token.strike .token.punctuation,
    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .language-markdown .token.strike .token.punctuation,
    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text code[data-lang][data-lang=markdown] .token.list.punctuation,
    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text pre[data-lang][data-lang=markdown] .token.list.punctuation,
    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text code[data-lang] .language-markdown .token.list.punctuation,
    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .language-markdown .token.list.punctuation,
    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text code[data-lang][data-lang=markdown] .token.title.important>.token.punctuation,
    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text pre[data-lang][data-lang=markdown] .token.title.important>.token.punctuation,
    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text code[data-lang] .language-markdown .token.title.important>.token.punctuation,
    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .language-markdown .token.title.important>.token.punctuation {
        color: #e45649
    }

    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text code[data-lang] .token.bold,
    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .token.bold {
        font-weight: 700
    }

    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text code[data-lang] .token.comment,
    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .token.comment,
    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text code[data-lang] .token.italic,
    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .token.italic {
        font-style: italic
    }

    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text code[data-lang] .token.entity,
    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .token.entity {
        cursor: help
    }

    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text code[data-lang] .token.namespace,
    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .token.namespace {
        opacity: .8
    }

    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text code[data-lang] .token.token.tab:not(:empty):before,
    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .token.token.tab:not(:empty):before,
    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text code[data-lang] .token.token.cr:before,
    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .token.token.cr:before,
    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text code[data-lang] .token.token.lf:before,
    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .token.token.lf:before,
    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text code[data-lang] .token.token.space:before,
    *[data-theme=light][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .token.token.space:before {
        color: #383a4233
    }

    @media (prefers-color-scheme: light) {

        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang],
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang] {
            color: #383a42
        }

        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang] .token.comment,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .token.comment,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang] .token.prolog,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .token.prolog,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang] .token.cdata,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .token.cdata {
            color: #a0a1a7
        }

        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang] .token.doctype,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .token.doctype,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang] .token.punctuation,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .token.punctuation,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang] .token.entity,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .token.entity {
            color: #383a42
        }

        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang] .token.attr-name,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .token.attr-name,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang] .token.class-name,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .token.class-name,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang] .token.boolean,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .token.boolean,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang] .token.constant,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .token.constant,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang] .token.number,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .token.number,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang] .token.atrule,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .token.atrule {
            color: #b76b01
        }

        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang] .token.keyword,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .token.keyword {
            color: #a626a4
        }

        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang] .token.property,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .token.property,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang] .token.tag,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .token.tag,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang] .token.symbol,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .token.symbol,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang] .token.deleted,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .token.deleted,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang] .token.important,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .token.important {
            color: #e45649
        }

        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang] .token.selector,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .token.selector,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang] .token.string,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .token.string,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang] .token.char,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .token.char,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang] .token.builtin,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .token.builtin,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang] .token.inserted,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .token.inserted,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang] .token.regex,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .token.regex,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang] .token.attr-value,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .token.attr-value,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang] .token.attr-value>.token.punctuation,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .token.attr-value>.token.punctuation {
            color: #50a14f
        }

        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang] .token.variable,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .token.variable,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang] .token.operator,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .token.operator,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang] .token.function,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .token.function {
            color: #4078f2
        }

        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang] .token.url,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .token.url {
            color: #0184bc
        }

        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang] .token.attr-value>.token.punctuation.attr-equals,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .token.attr-value>.token.punctuation.attr-equals,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang] .token.special-attr>.token.attr-value>.token.value.css,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .token.special-attr>.token.attr-value>.token.value.css {
            color: #383a42
        }

        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang][data-lang=css] .token.selector,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang][data-lang=css] .token.selector,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang] .language-css .token.selector,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .language-css .token.selector {
            color: #e45649
        }

        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang][data-lang=css] .token.property,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang][data-lang=css] .token.property,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang] .language-css .token.property,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .language-css .token.property {
            color: #383a42
        }

        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang][data-lang=css] .token.function,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang][data-lang=css] .token.function,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang] .language-css .token.function,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .language-css .token.function,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang][data-lang=css] .token.url>.token.function,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang][data-lang=css] .token.url>.token.function,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang] .language-css .token.url>.token.function,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .language-css .token.url>.token.function {
            color: #0184bc
        }

        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang][data-lang=css] .token.url>.token.string.url,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang][data-lang=css] .token.url>.token.string.url,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang] .language-css .token.url>.token.string.url,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .language-css .token.url>.token.string.url {
            color: #50a14f
        }

        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang][data-lang=css] .token.important,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang][data-lang=css] .token.important,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang] .language-css .token.important,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .language-css .token.important,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang][data-lang=css] .token.atrule .token.rule,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang][data-lang=css] .token.atrule .token.rule,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang] .language-css .token.atrule .token.rule,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .language-css .token.atrule .token.rule,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang][data-lang=javascript] .token.operator .language-javascript .token.operator,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang][data-lang=javascript] .token.operator .language-javascript .token.operator {
            color: #a626a4
        }

        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang][data-lang=javascript] .token.template-string>.token.interpolation>.token.interpolation-punctuation.punctuation,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang][data-lang=javascript] .token.template-string>.token.interpolation>.token.interpolation-punctuation.punctuation,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang] .language-javascript .token.template-string>.token.interpolation>.token.interpolation-punctuation.punctuation,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .language-javascript .token.template-string>.token.interpolation>.token.interpolation-punctuation.punctuation {
            color: #ca1243
        }

        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang][data-lang=json] .token.operator,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang][data-lang=json] .token.operator,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang] .language-json .token.operator,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .language-json .token.operator {
            color: #383a42
        }

        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang][data-lang=json] .token.null.keyword,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang][data-lang=json] .token.null.keyword,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang] .language-json .token.null.keyword,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .language-json .token.null.keyword {
            color: #b76b01
        }

        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang][data-lang=markdown] .token.url,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang][data-lang=markdown] .token.url,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang] .language-markdown .token.url,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .language-markdown .token.url,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang][data-lang=markdown] .token.url>.token.operator,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang][data-lang=markdown] .token.url>.token.operator,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang] .language-markdown .token.url>.token.operator,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .language-markdown .token.url>.token.operator,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang][data-lang=markdown] .token.url-reference.url>.token.string,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang][data-lang=markdown] .token.url-reference.url>.token.string,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang] .language-markdown .token.url-reference.url>.token.string,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .language-markdown .token.url-reference.url>.token.string {
            color: #383a42
        }

        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang][data-lang=markdown] .token.url>.token.content,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang][data-lang=markdown] .token.url>.token.content,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang] .language-markdown .token.url>.token.content,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .language-markdown .token.url>.token.content {
            color: #4078f2
        }

        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang][data-lang=markdown] .token.url>.token.url,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang][data-lang=markdown] .token.url>.token.url,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang] .language-markdown .token.url>.token.url,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .language-markdown .token.url>.token.url,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang][data-lang=markdown] .token.url-reference.url,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang][data-lang=markdown] .token.url-reference.url,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang] .language-markdown .token.url-reference.url,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .language-markdown .token.url-reference.url {
            color: #0184bc
        }

        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang][data-lang=markdown] .token.blockquote.punctuation,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang][data-lang=markdown] .token.blockquote.punctuation,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang] .language-markdown .token.blockquote.punctuation,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .language-markdown .token.blockquote.punctuation,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang][data-lang=markdown] .token.hr.punctuation,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang][data-lang=markdown] .token.hr.punctuation,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang] .language-markdown .token.hr.punctuation,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .language-markdown .token.hr.punctuation {
            color: #a0a1a7;
            font-style: italic
        }

        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang][data-lang=markdown] .token.code-snippet,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang][data-lang=markdown] .token.code-snippet,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang] .language-markdown .token.code-snippet,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .language-markdown .token.code-snippet {
            color: #50a14f
        }

        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang][data-lang=markdown] .token.bold .token.content,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang][data-lang=markdown] .token.bold .token.content,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang] .language-markdown .token.bold .token.content,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .language-markdown .token.bold .token.content {
            color: #b76b01
        }

        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang][data-lang=markdown] .token.italic .token.content,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang][data-lang=markdown] .token.italic .token.content,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang] .language-markdown .token.italic .token.content,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .language-markdown .token.italic .token.content {
            color: #a626a4
        }

        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang][data-lang=markdown] .token.strike .token.content,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang][data-lang=markdown] .token.strike .token.content,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang] .language-markdown .token.strike .token.content,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .language-markdown .token.strike .token.content,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang][data-lang=markdown] .token.strike .token.punctuation,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang][data-lang=markdown] .token.strike .token.punctuation,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang] .language-markdown .token.strike .token.punctuation,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .language-markdown .token.strike .token.punctuation,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang][data-lang=markdown] .token.list.punctuation,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang][data-lang=markdown] .token.list.punctuation,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang] .language-markdown .token.list.punctuation,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .language-markdown .token.list.punctuation,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang][data-lang=markdown] .token.title.important>.token.punctuation,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang][data-lang=markdown] .token.title.important>.token.punctuation,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang] .language-markdown .token.title.important>.token.punctuation,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .language-markdown .token.title.important>.token.punctuation {
            color: #e45649
        }

        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang] .token.bold,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .token.bold {
            font-weight: 700
        }

        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang] .token.comment,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .token.comment,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang] .token.italic,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .token.italic {
            font-style: italic
        }

        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang] .token.entity,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .token.entity {
            cursor: help
        }

        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang] .token.namespace,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .token.namespace {
            opacity: .8
        }

        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang] .token.token.tab:not(:empty):before,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .token.token.tab:not(:empty):before,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang] .token.token.cr:before,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .token.token.cr:before,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang] .token.token.lf:before,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .token.token.lf:before,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang] .token.token.space:before,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .token.token.space:before {
            color: #383a4233
        }
    }

    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text code[data-lang],
    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text pre[data-lang] {
        color: #abb2bf;
        text-shadow: 0 1px rgba(0, 0, 0, .3)
    }

    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text code[data-lang] .token.comment,
    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .token.comment,
    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text code[data-lang] .token.prolog,
    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .token.prolog,
    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text code[data-lang] .token.cdata,
    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .token.cdata {
        color: #5c6370
    }

    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text code[data-lang] .token.doctype,
    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .token.doctype,
    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text code[data-lang] .token.punctuation,
    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .token.punctuation,
    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text code[data-lang] .token.entity,
    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .token.entity {
        color: #abb2bf
    }

    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text code[data-lang] .token.attr-name,
    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .token.attr-name,
    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text code[data-lang] .token.class-name,
    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .token.class-name,
    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text code[data-lang] .token.boolean,
    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .token.boolean,
    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text code[data-lang] .token.constant,
    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .token.constant,
    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text code[data-lang] .token.number,
    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .token.number,
    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text code[data-lang] .token.atrule,
    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .token.atrule {
        color: #d19a66
    }

    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text code[data-lang] .token.keyword,
    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .token.keyword {
        color: #c678dd
    }

    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text code[data-lang] .token.property,
    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .token.property,
    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text code[data-lang] .token.tag,
    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .token.tag,
    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text code[data-lang] .token.symbol,
    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .token.symbol,
    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text code[data-lang] .token.deleted,
    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .token.deleted,
    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text code[data-lang] .token.important,
    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .token.important {
        color: #e06c75
    }

    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text code[data-lang] .token.selector,
    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .token.selector,
    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text code[data-lang] .token.string,
    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .token.string,
    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text code[data-lang] .token.char,
    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .token.char,
    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text code[data-lang] .token.builtin,
    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .token.builtin,
    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text code[data-lang] .token.inserted,
    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .token.inserted,
    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text code[data-lang] .token.regex,
    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .token.regex,
    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text code[data-lang] .token.attr-value,
    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .token.attr-value,
    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text code[data-lang] .token.attr-value>.token.punctuation,
    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .token.attr-value>.token.punctuation {
        color: #98c379
    }

    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text code[data-lang] .token.variable,
    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .token.variable,
    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text code[data-lang] .token.operator,
    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .token.operator,
    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text code[data-lang] .token.function,
    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .token.function {
        color: #61afef
    }

    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text code[data-lang] .token.url,
    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .token.url {
        color: #56b6c2
    }

    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text code[data-lang] .token.attr-value>.token.punctuation.attr-equals,
    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .token.attr-value>.token.punctuation.attr-equals,
    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text code[data-lang] .token.special-attr>.token.attr-value>.token.value.css,
    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .token.special-attr>.token.attr-value>.token.value.css {
        color: #abb2bf
    }

    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text code[data-lang][data-lang=css] .token.selector,
    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text pre[data-lang][data-lang=css] .token.selector,
    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text code[data-lang] .language-css .token.selector,
    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .language-css .token.selector {
        color: #e06c75
    }

    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text code[data-lang][data-lang=css] .token.property,
    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text pre[data-lang][data-lang=css] .token.property,
    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text code[data-lang] .language-css .token.property,
    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .language-css .token.property {
        color: #abb2bf
    }

    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text code[data-lang][data-lang=css] .token.function,
    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text pre[data-lang][data-lang=css] .token.function,
    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text code[data-lang] .language-css .token.function,
    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .language-css .token.function,
    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text code[data-lang][data-lang=css] .token.url>.token.function,
    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text pre[data-lang][data-lang=css] .token.url>.token.function,
    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text code[data-lang] .language-css .token.url>.token.function,
    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .language-css .token.url>.token.function {
        color: #56b6c2
    }

    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text code[data-lang][data-lang=css] .token.url>.token.string.url,
    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text pre[data-lang][data-lang=css] .token.url>.token.string.url,
    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text code[data-lang] .language-css .token.url>.token.string.url,
    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .language-css .token.url>.token.string.url {
        color: #98c379
    }

    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text code[data-lang][data-lang=css] .token.important,
    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text pre[data-lang][data-lang=css] .token.important,
    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text code[data-lang] .language-css .token.important,
    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .language-css .token.important,
    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text code[data-lang][data-lang=css] .token.atrule .token.rule,
    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text pre[data-lang][data-lang=css] .token.atrule .token.rule,
    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text code[data-lang] .language-css .token.atrule .token.rule,
    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .language-css .token.atrule .token.rule,
    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text code[data-lang][data-lang=javascript] .token.operator .language-javascript .token.operator,
    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text pre[data-lang][data-lang=javascript] .token.operator .language-javascript .token.operator {
        color: #c678dd
    }

    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text code[data-lang][data-lang=javascript] .token.template-string>.token.interpolation>.token.interpolation-punctuation.punctuation,
    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text pre[data-lang][data-lang=javascript] .token.template-string>.token.interpolation>.token.interpolation-punctuation.punctuation,
    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text code[data-lang] .language-javascript .token.template-string>.token.interpolation>.token.interpolation-punctuation.punctuation,
    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .language-javascript .token.template-string>.token.interpolation>.token.interpolation-punctuation.punctuation {
        color: #be5046
    }

    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text code[data-lang][data-lang=json] .token.operator,
    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text pre[data-lang][data-lang=json] .token.operator,
    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text code[data-lang] .language-json .token.operator,
    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .language-json .token.operator {
        color: #abb2bf
    }

    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text code[data-lang][data-lang=json] .token.null.keyword,
    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text pre[data-lang][data-lang=json] .token.null.keyword,
    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text code[data-lang] .language-json .token.null.keyword,
    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .language-json .token.null.keyword {
        color: #d19a66
    }

    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text code[data-lang][data-lang=markdown] .token.url,
    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text pre[data-lang][data-lang=markdown] .token.url,
    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text code[data-lang] .language-markdown .token.url,
    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .language-markdown .token.url,
    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text code[data-lang][data-lang=markdown] .token.url>.token.operator,
    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text pre[data-lang][data-lang=markdown] .token.url>.token.operator,
    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text code[data-lang] .language-markdown .token.url>.token.operator,
    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .language-markdown .token.url>.token.operator,
    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text code[data-lang][data-lang=markdown] .token.url-reference.url>.token.string,
    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text pre[data-lang][data-lang=markdown] .token.url-reference.url>.token.string,
    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text code[data-lang] .language-markdown .token.url-reference.url>.token.string,
    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .language-markdown .token.url-reference.url>.token.string {
        color: #abb2bf
    }

    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text code[data-lang][data-lang=markdown] .token.url>.token.content,
    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text pre[data-lang][data-lang=markdown] .token.url>.token.content,
    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text code[data-lang] .language-markdown .token.url>.token.content,
    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .language-markdown .token.url>.token.content {
        color: #61afef
    }

    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text code[data-lang][data-lang=markdown] .token.url>.token.url,
    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text pre[data-lang][data-lang=markdown] .token.url>.token.url,
    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text code[data-lang] .language-markdown .token.url>.token.url,
    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .language-markdown .token.url>.token.url,
    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text code[data-lang][data-lang=markdown] .token.url-reference.url,
    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text pre[data-lang][data-lang=markdown] .token.url-reference.url,
    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text code[data-lang] .language-markdown .token.url-reference.url,
    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .language-markdown .token.url-reference.url {
        color: #56b6c2
    }

    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text code[data-lang][data-lang=markdown] .token.blockquote.punctuation,
    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text pre[data-lang][data-lang=markdown] .token.blockquote.punctuation,
    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text code[data-lang] .language-markdown .token.blockquote.punctuation,
    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .language-markdown .token.blockquote.punctuation,
    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text code[data-lang][data-lang=markdown] .token.hr.punctuation,
    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text pre[data-lang][data-lang=markdown] .token.hr.punctuation,
    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text code[data-lang] .language-markdown .token.hr.punctuation,
    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .language-markdown .token.hr.punctuation {
        color: #5c6370;
        font-style: italic
    }

    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text code[data-lang][data-lang=markdown] .token.code-snippet,
    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text pre[data-lang][data-lang=markdown] .token.code-snippet,
    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text code[data-lang] .language-markdown .token.code-snippet,
    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .language-markdown .token.code-snippet {
        color: #98c379
    }

    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text code[data-lang][data-lang=markdown] .token.bold .token.content,
    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text pre[data-lang][data-lang=markdown] .token.bold .token.content,
    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text code[data-lang] .language-markdown .token.bold .token.content,
    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .language-markdown .token.bold .token.content {
        color: #d19a66
    }

    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text code[data-lang][data-lang=markdown] .token.italic .token.content,
    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text pre[data-lang][data-lang=markdown] .token.italic .token.content,
    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text code[data-lang] .language-markdown .token.italic .token.content,
    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .language-markdown .token.italic .token.content {
        color: #c678dd
    }

    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text code[data-lang][data-lang=markdown] .token.strike .token.content,
    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text pre[data-lang][data-lang=markdown] .token.strike .token.content,
    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text code[data-lang] .language-markdown .token.strike .token.content,
    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .language-markdown .token.strike .token.content,
    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text code[data-lang][data-lang=markdown] .token.strike .token.punctuation,
    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text pre[data-lang][data-lang=markdown] .token.strike .token.punctuation,
    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text code[data-lang] .language-markdown .token.strike .token.punctuation,
    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .language-markdown .token.strike .token.punctuation,
    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text code[data-lang][data-lang=markdown] .token.list.punctuation,
    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text pre[data-lang][data-lang=markdown] .token.list.punctuation,
    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text code[data-lang] .language-markdown .token.list.punctuation,
    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .language-markdown .token.list.punctuation,
    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text code[data-lang][data-lang=markdown] .token.title.important>.token.punctuation,
    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text pre[data-lang][data-lang=markdown] .token.title.important>.token.punctuation,
    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text code[data-lang] .language-markdown .token.title.important>.token.punctuation,
    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .language-markdown .token.title.important>.token.punctuation {
        color: #e06c75
    }

    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text code[data-lang] .token.bold,
    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .token.bold {
        font-weight: 700
    }

    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text code[data-lang] .token.comment,
    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .token.comment,
    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text code[data-lang] .token.italic,
    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .token.italic {
        font-style: italic
    }

    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text code[data-lang] .token.entity,
    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .token.entity {
        cursor: help
    }

    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text code[data-lang] .token.namespace,
    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .token.namespace {
        opacity: .8
    }

    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text code[data-lang] .token.token.tab:not(:empty):before,
    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .token.token.tab:not(:empty):before,
    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text code[data-lang] .token.token.cr:before,
    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .token.token.cr:before,
    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text code[data-lang] .token.token.lf:before,
    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .token.token.lf:before,
    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text code[data-lang] .token.token.space:before,
    *[data-theme=dark][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .token.token.space:before {
        color: #abb2bf26;
        text-shadow: none
    }



    @media (prefers-color-scheme: dark) {

        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang],
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang] {
            color: #abb2bf;
            text-shadow: 0 1px rgba(0, 0, 0, .3)
        }

        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang] .token.comment,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .token.comment,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang] .token.prolog,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .token.prolog,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang] .token.cdata,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .token.cdata {
            color: #5c6370
        }

        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang] .token.doctype,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .token.doctype,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang] .token.punctuation,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .token.punctuation,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang] .token.entity,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .token.entity {
            color: #abb2bf
        }

        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang] .token.attr-name,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .token.attr-name,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang] .token.class-name,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .token.class-name,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang] .token.boolean,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .token.boolean,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang] .token.constant,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .token.constant,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang] .token.number,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .token.number,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang] .token.atrule,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .token.atrule {
            color: #d19a66
        }

        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang] .token.keyword,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .token.keyword {
            color: #c678dd
        }

        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang] .token.property,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .token.property,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang] .token.tag,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .token.tag,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang] .token.symbol,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .token.symbol,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang] .token.deleted,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .token.deleted,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang] .token.important,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .token.important {
            color: #e06c75
        }

        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang] .token.selector,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .token.selector,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang] .token.string,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .token.string,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang] .token.char,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .token.char,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang] .token.builtin,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .token.builtin,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang] .token.inserted,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .token.inserted,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang] .token.regex,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .token.regex,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang] .token.attr-value,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .token.attr-value,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang] .token.attr-value>.token.punctuation,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .token.attr-value>.token.punctuation {
            color: #98c379
        }

        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang] .token.variable,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .token.variable,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang] .token.operator,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .token.operator,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang] .token.function,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .token.function {
            color: #61afef
        }

        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang] .token.url,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .token.url {
            color: #56b6c2
        }

        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang] .token.attr-value>.token.punctuation.attr-equals,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .token.attr-value>.token.punctuation.attr-equals,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang] .token.special-attr>.token.attr-value>.token.value.css,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .token.special-attr>.token.attr-value>.token.value.css {
            color: #abb2bf
        }

        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang][data-lang=css] .token.selector,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang][data-lang=css] .token.selector,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang] .language-css .token.selector,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .language-css .token.selector {
            color: #e06c75
        }

        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang][data-lang=css] .token.property,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang][data-lang=css] .token.property,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang] .language-css .token.property,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .language-css .token.property {
            color: #abb2bf
        }

        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang][data-lang=css] .token.function,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang][data-lang=css] .token.function,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang] .language-css .token.function,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .language-css .token.function,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang][data-lang=css] .token.url>.token.function,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang][data-lang=css] .token.url>.token.function,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang] .language-css .token.url>.token.function,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .language-css .token.url>.token.function {
            color: #56b6c2
        }

        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang][data-lang=css] .token.url>.token.string.url,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang][data-lang=css] .token.url>.token.string.url,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang] .language-css .token.url>.token.string.url,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .language-css .token.url>.token.string.url {
            color: #98c379
        }

        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang][data-lang=css] .token.important,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang][data-lang=css] .token.important,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang] .language-css .token.important,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .language-css .token.important,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang][data-lang=css] .token.atrule .token.rule,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang][data-lang=css] .token.atrule .token.rule,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang] .language-css .token.atrule .token.rule,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .language-css .token.atrule .token.rule,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang][data-lang=javascript] .token.operator .language-javascript .token.operator,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang][data-lang=javascript] .token.operator .language-javascript .token.operator {
            color: #c678dd
        }

        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang][data-lang=javascript] .token.template-string>.token.interpolation>.token.interpolation-punctuation.punctuation,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang][data-lang=javascript] .token.template-string>.token.interpolation>.token.interpolation-punctuation.punctuation,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang] .language-javascript .token.template-string>.token.interpolation>.token.interpolation-punctuation.punctuation,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .language-javascript .token.template-string>.token.interpolation>.token.interpolation-punctuation.punctuation {
            color: #be5046
        }

        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang][data-lang=json] .token.operator,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang][data-lang=json] .token.operator,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang] .language-json .token.operator,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .language-json .token.operator {
            color: #abb2bf
        }

        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang][data-lang=json] .token.null.keyword,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang][data-lang=json] .token.null.keyword,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang] .language-json .token.null.keyword,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .language-json .token.null.keyword {
            color: #d19a66
        }

        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang][data-lang=markdown] .token.url,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang][data-lang=markdown] .token.url,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang] .language-markdown .token.url,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .language-markdown .token.url,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang][data-lang=markdown] .token.url>.token.operator,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang][data-lang=markdown] .token.url>.token.operator,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang] .language-markdown .token.url>.token.operator,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .language-markdown .token.url>.token.operator,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang][data-lang=markdown] .token.url-reference.url>.token.string,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang][data-lang=markdown] .token.url-reference.url>.token.string,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang] .language-markdown .token.url-reference.url>.token.string,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .language-markdown .token.url-reference.url>.token.string {
            color: #abb2bf
        }

        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang][data-lang=markdown] .token.url>.token.content,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang][data-lang=markdown] .token.url>.token.content,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang] .language-markdown .token.url>.token.content,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .language-markdown .token.url>.token.content {
            color: #61afef
        }

        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang][data-lang=markdown] .token.url>.token.url,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang][data-lang=markdown] .token.url>.token.url,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang] .language-markdown .token.url>.token.url,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .language-markdown .token.url>.token.url,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang][data-lang=markdown] .token.url-reference.url,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang][data-lang=markdown] .token.url-reference.url,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang] .language-markdown .token.url-reference.url,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .language-markdown .token.url-reference.url {
            color: #56b6c2
        }

        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang][data-lang=markdown] .token.blockquote.punctuation,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang][data-lang=markdown] .token.blockquote.punctuation,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang] .language-markdown .token.blockquote.punctuation,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .language-markdown .token.blockquote.punctuation,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang][data-lang=markdown] .token.hr.punctuation,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang][data-lang=markdown] .token.hr.punctuation,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang] .language-markdown .token.hr.punctuation,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .language-markdown .token.hr.punctuation {
            color: #5c6370;
            font-style: italic
        }

        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang][data-lang=markdown] .token.code-snippet,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang][data-lang=markdown] .token.code-snippet,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang] .language-markdown .token.code-snippet,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .language-markdown .token.code-snippet {
            color: #98c379
        }

        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang][data-lang=markdown] .token.bold .token.content,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang][data-lang=markdown] .token.bold .token.content,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang] .language-markdown .token.bold .token.content,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .language-markdown .token.bold .token.content {
            color: #d19a66
        }

        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang][data-lang=markdown] .token.italic .token.content,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang][data-lang=markdown] .token.italic .token.content,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang] .language-markdown .token.italic .token.content,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .language-markdown .token.italic .token.content {
            color: #c678dd
        }

        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang][data-lang=markdown] .token.strike .token.content,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang][data-lang=markdown] .token.strike .token.content,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang] .language-markdown .token.strike .token.content,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .language-markdown .token.strike .token.content,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang][data-lang=markdown] .token.strike .token.punctuation,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang][data-lang=markdown] .token.strike .token.punctuation,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang] .language-markdown .token.strike .token.punctuation,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .language-markdown .token.strike .token.punctuation,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang][data-lang=markdown] .token.list.punctuation,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang][data-lang=markdown] .token.list.punctuation,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang] .language-markdown .token.list.punctuation,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .language-markdown .token.list.punctuation,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang][data-lang=markdown] .token.title.important>.token.punctuation,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang][data-lang=markdown] .token.title.important>.token.punctuation,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang] .language-markdown .token.title.important>.token.punctuation,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .language-markdown .token.title.important>.token.punctuation {
            color: #e06c75
        }

        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang] .token.bold,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .token.bold {
            font-weight: 700
        }

        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang] .token.comment,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .token.comment,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang] .token.italic,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .token.italic {
            font-style: italic
        }

        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang] .token.entity,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .token.entity {
            cursor: help
        }

        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang] .token.namespace,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .token.namespace {
            opacity: .8
        }

        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang] .token.token.tab:not(:empty):before,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .token.token.tab:not(:empty):before,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang] .token.token.cr:before,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .token.token.cr:before,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang] .token.token.lf:before,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .token.token.lf:before,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang] .token.token.space:before,
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang] .token.token.space:before {
            color: #abb2bf26;
            text-shadow: none
        }
    }

    @media (prefers-color-scheme: dark) {

        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text code[data-lang],
        *[data-theme=auto][data-v-c09dc619] .feedArticleCard__text pre[data-lang] {
            text-shadow: none
        }
    }

    [data-v-c09dc619] .feedArticleCard__text figure {
        position: relative;
        display: block;
        min-width: 0;
        margin: 0 auto .58823529em;
        width: 100%;
        min-width: 100px;
        max-width: 1900px;
        padding: 0;
        font-size: 1em;
        text-align: center;
        vertical-align: top
    }

    [data-v-c09dc619] .feedArticleCard__text figure .wrap {
        position: relative;
        display: inline-block;
        max-width: 100%;
        margin: 0 -20px;
        text-align: center;
        vertical-align: top
    }

    [data-v-c09dc619] .feedArticleCard__text figure .wrap .twitter-tweet {
        width: 550px !important;
        max-width: 100% !important
    }

    @media screen and (max-width: 680px) {
        [data-v-c09dc619] .feedArticleCard__text figure .wrap {
            margin-left: -16px;
            margin-right: -16px
        }
    }

    [data-v-c09dc619] .feedArticleCard__text figure .spacer {
        display: block;
        min-width: 100px;
        max-width: 100%;
        min-height: 15px;
        box-sizing: border-box;
        vertical-align: top
    }

    [data-v-c09dc619] .feedArticleCard__text figure .loader {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        -webkit-backdrop-filter: blur(10px);
        -moz-backdrop-filter: blur(10px);
        backdrop-filter: blur(10px);
        transition: all .4s;
        color: var(--color-secondary, #949494)
    }

    [data-v-c09dc619] .feedArticleCard__text figure .loader.m_hidden {
        opacity: 0
    }

    [data-v-c09dc619] .feedArticleCard__text figure.m_column {
        min-width: 0;
        max-width: var(--document-column-size, auto);
        margin-left: auto;
        margin-right: auto
    }

    [data-v-c09dc619] .feedArticleCard__text figure.m_column .wrap {
        margin: 0;
        width: 100%
    }

    [data-v-c09dc619] .feedArticleCard__text figure:first-child {
        margin-top: 0
    }

    [data-v-c09dc619] .feedArticleCard__text figure:last-child {
        margin-bottom: 0
    }

    [data-v-c09dc619] .feedArticleCard__text figure img,
    [data-v-c09dc619] .feedArticleCard__text figure video {
        position: relative;
        display: inline-block;
        height: auto;
        max-width: 100%;
        vertical-align: top;
        border: 0;
        transition: opacity .2s
    }

    [data-v-c09dc619] .feedArticleCard__text figure img.m_hidden,
    [data-v-c09dc619] .feedArticleCard__text figure video.m_hidden {
        opacity: 0
    }

    [data-v-c09dc619] .feedArticleCard__text iframe {
        display: block;
        margin: .58823529em auto;
        border: 0;
        background-color: transparent
    }

    [data-v-c09dc619] .feedArticleCard__text figure iframe {
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        width: 0;
        height: 0;
        min-width: 100%;
        min-height: 100%;
        margin: 0;
        vertical-align: top
    }

    [data-v-c09dc619] .feedArticleCard__text figure .embed-instagram {
        box-sizing: border-box;
        border-radius: 7px;
        border: 1px solid var(--color-divider, #EFEFEF)
    }

    [data-v-c09dc619] .feedArticleCard__text figure blockquote {
        visibility: hidden
    }

    [data-v-c09dc619] .feedArticleCard__text figure>[draggable] iframe {
        position: relative;
        display: inline-block;
        min-width: 0;
        max-width: var(--document-column-size, auto);
        height: 4.86111111px;
        border: 0;
        background-color: transparent;
        margin: 0;
        outline: 0;
        vertical-align: top
    }

    [data-v-c09dc619] .feedArticleCard__text figure figcaption {
        display: block;
        min-width: 0;
        max-width: var(--document-column-size, auto);
        margin: .83333333em auto 0;
        min-height: 1.5em;
        font-family: Inter, Helvetica Neue, Helvetica, sans-serif;
        font-stretch: normal;
        font-weight: 400;
        font-style: normal;
        font-size: .70588235em;
        line-height: 1.5em;
        color: var(--color-secondary, #949494);
        text-align: left;
        line-height: normal;
        white-space: pre-wrap
    }

    [data-v-c09dc619] .feedArticleCard__text figure figcaption[data-align=right] {
        text-align: right
    }

    [data-v-c09dc619] .feedArticleCard__text figure figcaption[data-align=center] {
        text-align: center
    }

    [data-v-c09dc619] .feedArticleCard__text figure[data-caption-align=right] figcaption {
        text-align: right
    }

    [data-v-c09dc619] .feedArticleCard__text figure[data-caption-align=center] figcaption {
        text-align: center
    }

    [data-v-c09dc619] .feedArticleCard__text a,
    [data-v-c09dc619] .feedArticleCard__text .link {
        border: 0;
        color: var(--color-primary, #1A1919);
        text-decoration: underline;
        text-decoration-color: var(--color-primary-underline, rgba(26, 25, 25, .2));
        text-decoration-skip: ink;
        text-decoration-thickness: 1px;
        text-underline-offset: .15em;
        transition: all .4s;
        cursor: pointer
    }

    [data-v-c09dc619] .feedArticleCard__text a:hover,
    [data-v-c09dc619] .feedArticleCard__text .link:hover {
        color: var(--color-primary, #1A1919);
        text-decoration-color: var(--color-primary, #1A1919)
    }

    [data-v-c09dc619] .feedArticleCard__text b,
    [data-v-c09dc619] .feedArticleCard__text strong,
    [data-v-c09dc619] .feedArticleCard__text .bold {
        font-weight: 700
    }

    [data-v-c09dc619] .feedArticleCard__text pre b,
    [data-v-c09dc619] .feedArticleCard__text pre strong,
    [data-v-c09dc619] .feedArticleCard__text code b,
    [data-v-c09dc619] .feedArticleCard__text code strong {
        font-family: FiraCode, SFMono-Regular, Consolas, Liberation Mono, Menlo, Monaco, Courier, monospace;
        font-stretch: normal;
        font-weight: 700;
        font-style: normal;
        letter-spacing: normal;
        font-feature-settings: "ss01"off, "ss02"off, "ss03"off, "ss04"on, "ss05"off, "ss06"on, "ss07"off, "zero"off, "onum"off
    }

    [data-v-c09dc619] .feedArticleCard__text i,
    [data-v-c09dc619] .feedArticleCard__text .italic {
        font-style: italic
    }

    [data-v-c09dc619] .feedArticleCard__text u,
    [data-v-c09dc619] .feedArticleCard__text .underline {
        text-decoration: underline
    }

    [data-v-c09dc619] .feedArticleCard__text s,
    [data-v-c09dc619] .feedArticleCard__text .strike {
        text-decoration: line-through
    }

    [data-v-c09dc619] .feedArticleCard__text u s,
    [data-v-c09dc619] .feedArticleCard__text s u,
    [data-v-c09dc619] .feedArticleCard__text .underline.strike {
        text-decoration: underline line-through
    }

    [data-v-c09dc619] .feedArticleCard__text code,
    [data-v-c09dc619] .feedArticleCard__text .code {
        display: inline;
        border-radius: .3em;
        background-color: var(--color-bg-2, #F6F6F6);
        font-family: FiraCode, SFMono-Regular, Consolas, Liberation Mono, Menlo, Monaco, Courier, monospace;
        font-stretch: normal;
        font-weight: 400;
        font-style: normal;
        letter-spacing: normal;
        font-feature-settings: "ss01"off, "ss02"off, "ss03"off, "ss04"on, "ss05"off, "ss06"on, "ss07"off, "zero"off, "onum"off;
        font-size: .87em;
        color: var(--color-primary, #1A1919)
    }

    [data-v-c09dc619] .feedArticleCard__text code:before,
    [data-v-c09dc619] .feedArticleCard__text .code:before,
    [data-v-c09dc619] .feedArticleCard__text code:after,
    [data-v-c09dc619] .feedArticleCard__text .code:after {
        content: "\a0";
        letter-spacing: -.3em
    }

    [data-v-c09dc619] .feedArticleCard__text .tt-tag {
        display: inline-block;
        margin-right: 10px;
        border: 0;
        color: var(--color-accent-action, #5B41F5);
        text-decoration: none;
        cursor: pointer;
        white-space: nowrap
    }

    .feedArticleCard__text[data-v-c09dc619] img {
        display: block;
        width: 100%;
        max-width: 100%;
        margin: 0;
        padding: 0;
        border-radius: 7px
    }

    .feedArticleCard__text[data-v-c09dc619] figure {
        text-align: left !important
    }

    .feedArticleCard__text[data-v-c09dc619] figure .wrap {
        margin-left: 0 !important;
        margin-right: 0 !important;
        text-align: left !important
    }

    .feedArticleCard__footer[data-v-c09dc619] {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        flex: 0 0 auto;
        margin-top: 10px
    }

    .feedArticleCard[data-v-c09dc619]:hover {
        box-shadow: var(--shadow-card, 0px 12px 52px rgba(0, 0, 0, .1))
    }

    .feedArticleCard.m_hidden[data-v-c09dc619],
    .feedArticleCard.m_unready[data-v-c09dc619] {
        opacity: 0;
        transform: translateY(20px)
    }

    .feedArticleCard[data-v-c09dc619]:nth-child(3n) {
        transition-delay: .05s
    }

    .feedArticleCard[data-v-c09dc619]:nth-child(3n+1) {
        transition-delay: .1s
    }

    @media screen and (max-width: 680px) {
        .feedArticleCard__header_userpic[data-v-c09dc619] {
            width: 30px;
            height: 30px;
            margin-right: 7px
        }

        .feedArticleCard__header_name[data-v-c09dc619],
        .feedArticleCard__header_username[data-v-c09dc619] {
            font-size: 1.3rem;
            line-height: 1.4em
        }

        .feedArticleCard__header_action[data-v-c09dc619] {
            display: none
        }

        .feedArticleCard__title[data-v-c09dc619] {
            font-size: 1.9rem;
            line-height: 1.4em
        }

        .feedArticleCard__text[data-v-c09dc619] {
            font-size: 1.6rem;
            line-height: 1.4em
        }
    }

    .feedWidgetArticles[data-v-3ca1d5d7] {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        justify-content: flex-start;
        width: 100%;
        min-height: 0;
        padding: 16px;
        box-sizing: border-box;
        text-decoration: none;
        border-radius: 10px;
        background-color: var(--color-bg-1, #FFFFFF);
        transition: all .2s, transform 0s
    }

    .feedWidgetArticles__title[data-v-3ca1d5d7] {
        display: block;
        margin-bottom: .4em;
        font-family: Inter, Helvetica Neue, Helvetica, sans-serif;
        font-stretch: normal;
        font-weight: 700;
        font-style: normal;
        font-size: 2rem;
        line-height: 1.4em;
        color: var(--color-secondary, #949494);
        text-align: center
    }

    .feedWidgetArticles__list[data-v-3ca1d5d7] {
        margin-bottom: 5px
    }

    .feedWidgetArticles__item[data-v-3ca1d5d7] {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-start;
        padding: 10px;
        margin-bottom: 8px;
        border-radius: 4px;
        text-decoration: none;
        transition: all .4s;
        cursor: pointer
    }

    .feedWidgetArticles__item_position[data-v-3ca1d5d7] {
        display: block;
        min-width: 15px;
        flex: 0 0 auto;
        margin-right: 10px;
        font-family: Inter, Helvetica Neue, Helvetica, sans-serif;
        font-stretch: normal;
        font-weight: 700;
        font-style: normal;
        font-size: 1.9rem;
        line-height: 1.05263158em;
        color: var(--color-secondary, #949494);
        text-align: center;
        opacity: .6
    }

    .feedWidgetArticles__item_content[data-v-3ca1d5d7] {
        display: block;
        flex: 1 1 auto;
        min-width: 0
    }

    .feedWidgetArticles__item_author[data-v-3ca1d5d7] {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 8px
    }

    .feedWidgetArticles__item_userpic[data-v-3ca1d5d7] {
        display: block;
        flex: 0 0 auto;
        align-self: flex-start;
        margin-right: 4px
    }

    .feedWidgetArticles__item_name[data-v-3ca1d5d7] {
        display: block;
        min-width: 0;
        max-width: 100%;
        overflow: hidden;
        font-family: Inter, Helvetica Neue, Helvetica, sans-serif;
        font-stretch: normal;
        font-weight: 500;
        font-style: normal;
        font-size: 1.4rem;
        line-height: 1.4em;
        color: var(--color-secondary, #949494);
        text-align: left;
        text-overflow: ellipsis;
        white-space: nowrap
    }

    .feedWidgetArticles__item_name_verified[data-v-3ca1d5d7] {
        color: var(--color-primary, #1A1919);
        position: relative;
        display: inline-block;
        top: .23333333em;
        width: .93333333em;
        height: .93333333em;
        margin-left: .15em;
        vertical-align: top;
        //background-image: url(./verified.931e1f3b.svg);
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% 100%
    }

    .feedWidgetArticles__item_title[data-v-3ca1d5d7] {
        display: block;
        font-family: Inter, Helvetica Neue, Helvetica, sans-serif;
        font-stretch: normal;
        font-weight: 600;
        font-style: normal;
        font-size: 1.4rem;
        line-height: 1.4em;
        color: var(--color-primary, #1A1919)
    }

    .feedWidgetArticles__item_text[data-v-3ca1d5d7] {
        display: block;
        margin-top: 5px;
        font-family: Inter, Helvetica Neue, Helvetica, sans-serif;
        font-stretch: normal;
        font-weight: 400;
        font-style: normal;
        font-size: 1.4rem;
        line-height: 1.4em;
        color: var(--color-secondary, #949494)
    }

    .feedWidgetArticles__item[data-v-3ca1d5d7]:last-child {
        margin-bottom: 0
    }

    .feedWidgetArticles__item[data-v-3ca1d5d7]:hover {
        background-color: var(--color-hover, #F6F5F5)
    }

    .feedWidgetArticles__empty[data-v-3ca1d5d7] {
        display: flex;
        flex-direction: center;
        align-items: center;
        min-height: 120px
    }

    .feedWidgetArticles.m_hidden[data-v-3ca1d5d7],
    .feedWidgetArticles.m_unready[data-v-3ca1d5d7] {
        opacity: 0;
        transform: translateY(20px)
    }

    .feedWidgetBlogs[data-v-9b9b0f08] {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        justify-content: flex-start;
        width: 100%;
        min-height: 0;
        padding: 20px;
        box-sizing: border-box;
        text-decoration: none;
        border-radius: 10px;
        background-color: var(--color-bg-1, #FFFFFF);
        transition: all .2s, transform 0s
    }

    .feedWidgetBlogs__title[data-v-9b9b0f08] {
        display: block;
        margin-bottom: .75em;
        font-family: Inter, Helvetica Neue, Helvetica, sans-serif;
        font-stretch: normal;
        font-weight: 700;
        font-style: normal;
        font-size: 2rem;
        line-height: 1.4em;
        color: var(--color-secondary, #949494);
        text-align: center
    }

    .feedWidgetBlogs__list[data-v-9b9b0f08] {
        margin-bottom: 5px
    }

    .feedWidgetBlogs__item[data-v-9b9b0f08] {
        display: block;
        padding: 10px;
        margin: 0 -10px 8px;
        border-radius: 4px;
        text-decoration: none;
        transition: all .4s;
        cursor: pointer
    }

    .feedWidgetBlogs__item_header[data-v-9b9b0f08] {
        display: flex;
        flex: 0 0 auto;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start
    }

    .feedWidgetBlogs__item_userpic[data-v-9b9b0f08] {
        display: block;
        flex: 0 0 auto;
        width: 40px;
        height: 40px;
        align-self: flex-start;
        margin-right: 10px
    }

    .feedWidgetBlogs__item_author[data-v-9b9b0f08] {
        display: block;
        flex: 1 1 auto;
        min-width: 0
    }

    .feedWidgetBlogs__item_name[data-v-9b9b0f08],
    .feedWidgetBlogs__item_username[data-v-9b9b0f08] {
        display: block;
        min-width: 0;
        max-width: 100%;
        overflow: hidden;
        font-family: Inter, Helvetica Neue, Helvetica, sans-serif;
        font-stretch: normal;
        font-weight: 500;
        font-style: normal;
        font-size: 1.4rem;
        line-height: 1.4em;
        color: var(--color-primary, #1A1919);
        text-align: left;
        text-overflow: ellipsis;
        white-space: nowrap
    }

    .feedWidgetBlogs__item_name_verified[data-v-9b9b0f08] {
        position: relative;
        display: inline-block;
        top: .23333333em;
        width: .93333333em;
        height: .93333333em;
        margin-left: .15em;
        vertical-align: top;
        //background-image: url(./verified.931e1f3b.svg);
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% 100%
    }

    .feedWidgetBlogs__item_username[data-v-9b9b0f08] {
        color: var(--color-secondary, #949494)
    }

    .feedWidgetBlogs__item_action[data-v-9b9b0f08] {
        display: block;
        flex: 0 0 auto;
        margin-left: 10px
    }

    .feedWidgetBlogs__item_bio[data-v-9b9b0f08] {
        display: block;
        margin-top: 5px;
        font-family: Inter, Helvetica Neue, Helvetica, sans-serif;
        font-stretch: normal;
        font-weight: 400;
        font-style: normal;
        font-size: 1.5rem;
        line-height: 1.4em;
        color: var(--color-primary, #1A1919)
    }

    .feedWidgetBlogs__item[data-v-9b9b0f08]:last-child {
        margin-bottom: 0
    }

    .feedWidgetBlogs__item[data-v-9b9b0f08]:hover {
        background-color: var(--color-hover, #F6F5F5)
    }

    .feedWidgetBlogs__empty[data-v-9b9b0f08] {
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 120px
    }

    .feedWidgetBlogs.m_hidden[data-v-9b9b0f08],
    .feedWidgetBlogs.m_unready[data-v-9b9b0f08] {
        opacity: 0;
        transform: translateY(20px)
    }

    .feed[data-v-0bc564be] {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        margin: 0;
        padding: 30px 20px;
        box-sizing: border-box
    }

    .feed__content[data-v-0bc564be] {
        display: block;
        width: 100%;
        max-width: 1150px;
        margin: 0 auto;
        transition: transform .4s
    }

    .feed__content_header[data-v-0bc564be] {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 50px
    }

    .feed__content_title[data-v-0bc564be] {
        display: block;
        font-family: Inter, Helvetica Neue, Helvetica, sans-serif;
        font-stretch: normal;
        font-weight: 700;
        font-style: normal;
        font-size: 4rem;
        line-height: 1.4em;
        color: var(--color-secondary, #949494);
        text-align: center
    }

    .feed__content_title_unread[data-v-0bc564be] {
        display: inline-block;
        margin-left: .33333333em;
        opacity: .3
    }

    .feed__content_search[data-v-0bc564be] {
        display: block;
        margin-top: 20px
    }

    .feed__content.m_central[data-v-0bc564be] {
        max-width: 640px;
        margin: auto
    }

    @media screen and (max-width: 680px) {
        .feed__content.m_central[data-v-0bc564be] {
            margin-top: 0;
            margin-bottom: 0
        }
    }

    .feed__content.m_article[data-v-0bc564be] {
        transform: translate(-50%)
    }

    @media screen and (max-width: 680px) {
        .feed__content.m_article[data-v-0bc564be] {
            transform: none
        }
    }

    .feed__list[data-v-0bc564be] {
        display: block;
        margin: 30px 0
    }

    .feed__list_items[data-v-0bc564be] {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
        grid-auto-rows: 20px;
        grid-gap: 20px;
        align-items: start
    }

    .feed__list_more[data-v-0bc564be] {
        display: block;
        margin: 30px;
        text-align: center;
        grid-column-start: 0;
        grid-column-end: -1
    }

    .feed__empty[data-v-0bc564be] {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center
    }

    .feed__empty_title[data-v-0bc564be] {
        display: block;
        font-family: Inter, Helvetica Neue, Helvetica, sans-serif;
        font-stretch: normal;
        font-weight: 700;
        font-style: normal;
        font-size: 3rem;
        line-height: 1.4em;
        color: var(--color-primary, #1A1919);
        text-align: center
    }

    .feed__empty_text[data-v-0bc564be] {
        display: block;
        margin-top: .5em;
        font-family: Inter, Helvetica Neue, Helvetica, sans-serif;
        font-stretch: normal;
        font-weight: 400;
        font-style: normal;
        font-size: 2rem;
        line-height: 1.4em;
        color: var(--color-primary, #1A1919);
        text-align: center;
        opacity: .6
    }

    .feed__empty_search[data-v-0bc564be] {
        display: block;
        margin-top: 20px
    }

    @media screen and (max-width: 680px) {
        .feed__empty_search[data-v-0bc564be] {
            margin-top: 16px
        }
    }

    .feed__empty_featured[data-v-0bc564be] {
        display: block;
        width: 100%;
        margin-top: 35px
    }

    @media screen and (max-width: 680px) {
        .feed[data-v-0bc564be] {
            padding-left: 16px;
            padding-right: 16px
        }
    }

    .feedArticle[data-v-caa5968c] {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        justify-content: flex-start;
        flex: 1 0 auto;
        min-height: 100%;
        border-radius: 0;
        background-color: var(--color-bg-1, #FFFFFF);
        box-shadow: var(--shadow-main, 0px 18px 36px rgba(0, 0, 0, .14));
        color: var(--color-primary, #1A1919);
        box-shadow: -22px 0 74px var(--color-shadow, rgba(0, 0, 0, .14))
    }

    .feedArticle__header[data-v-caa5968c] {
        position: -webkit-sticky;
        position: sticky;
        display: block;
        top: 0;
        left: 0;
        flex: 0 0 auto;
        width: 100%;
        z-index: 1
    }

    .feedArticle__header[data-v-caa5968c]:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: 20px 20px 0 0;
        background-color: var(--color-bg-blur, rgba(255, 255, 255, .6));
        -webkit-backdrop-filter: blur(10px);
        -moz-backdrop-filter: blur(10px);
        backdrop-filter: blur(10px)
    }

    @supports (not ((backdrop-filter: blur(10px)) or (-webkit-backdrop-filter: blur(10px)) or (-moz-backdrop-filter: blur(10px)))) {
        .feedArticle__header[data-v-caa5968c]:before {
            background-color: var(--color-bg-1, #FFFFFF)
        }
    }

    .feedArticle__header_menu[data-v-caa5968c] {
        position: relative;
        display: block;
        z-index: 1;
        transition: all .2s .2s
    }

    .feedArticle__header_menu.m_hidden[data-v-caa5968c] {
        opacity: 0;
        transition: all .2s;
        pointer-events: none
    }

    .feedArticle__header_scroll[data-v-caa5968c] {
        position: absolute;
        display: none;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 0 16px;
        z-index: 1;
        transition: all .2s .2s
    }

    .feedArticle__header_scroll.m_hidden[data-v-caa5968c] {
        opacity: 0;
        transition: all .2s;
        pointer-events: none
    }

    .feedArticle__header_title[data-v-caa5968c] {
        display: block;
        max-width: 100%;
        min-width: 0;
        overflow: hidden;
        font-family: Inter, Helvetica Neue, Helvetica, sans-serif;
        font-stretch: normal;
        font-weight: 500;
        font-style: normal;
        font-size: 1.5rem;
        line-height: 1.4em;
        color: var(--color-primary, #1A1919);
        text-align: center;
        text-overflow: ellipsis;
        white-space: nowrap
    }

    .feedArticle__header_author[data-v-caa5968c] {
        display: block;
        max-width: 100%;
        min-width: 0;
        overflow: hidden;
        font-family: Inter, Helvetica Neue, Helvetica, sans-serif;
        font-stretch: normal;
        font-weight: 400;
        font-style: normal;
        font-size: 1.3rem;
        line-height: 1.4em;
        color: var(--color-secondary, #949494);
        text-align: center;
        text-overflow: ellipsis;
        white-space: nowrap
    }

    .feedArticle__header_button[data-v-caa5968c] {
        display: block;
        margin-right: 10px
    }

    .feedArticle__header_button[data-v-caa5968c]:last-child {
        margin-right: 0
    }

    .feedArticle__wrap[data-v-caa5968c] {
        display: block;
        flex: 1 1 auto;
        min-height: 0;
        overflow-x: visible;
        overflow-y: auto
    }

    .feedArticle__content[data-v-caa5968c] {
        display: block;
        padding: 16px;
        box-sizing: border-box;
        border-top: 1px solid var(--color-divider, #EFEFEF)
    }

    .feedArticle__content[data-v-caa5968c]:first-child {
        border-top: 0
    }

    .feedArticle__author[data-v-caa5968c] {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        max-width: 700px;
        margin: 0 auto 30px
    }

    .feedArticle__author_link[data-v-caa5968c] {
        display: flex;
        flex: 1 1 auto;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        min-width: 0;
        max-width: 100%;
        text-decoration: none
    }

    .feedArticle__author_userpic[data-v-caa5968c] {
        width: 48px;
        height: 48px;
        align-self: flex-start;
        margin-right: 10px
    }

    .feedArticle__author_content[data-v-caa5968c] {
        display: block;
        min-width: 0;
        max-width: 100%
    }

    .feedArticle__author_name[data-v-caa5968c] {
        display: block;
        font-family: Inter, Helvetica Neue, Helvetica, sans-serif;
        font-stretch: normal;
        font-weight: 700;
        font-style: normal;
        font-size: 1.8rem;
        line-height: 1.38888889em;
        color: var(--color-primary, #1A1919);
        text-align: left;
        word-wrap: break-word
    }

    .feedArticle__author_name_verified[data-v-caa5968c] {
        position: relative;
        display: inline-block;
        top: .31111111em;
        width: .77777778em;
        height: .77777778em;
        margin-left: .15em;
        vertical-align: top;
        //background-image: url(./verified.931e1f3b.svg);
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% 100%
    }

    .feedArticle__author_username[data-v-caa5968c] {
        display: block;
        overflow: hidden;
        font-family: Inter, Helvetica Neue, Helvetica, sans-serif;
        font-stretch: normal;
        font-weight: 700;
        font-style: normal;
        font-size: 1.8rem;
        line-height: 1.38888889em;
        color: var(--color-secondary, #949494);
        white-space: nowrap;
        text-align: left;
        text-overflow: ellipsis
    }

    .feedArticle__author_actions[data-v-caa5968c] {
        display: flex;
        flex: 0 1 auto;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: flex-end;
        margin: 0 -5px;
        padding-left: 16px
    }

    @media screen and (max-width: 960px) {
        .feedArticle__author_actions[data-v-caa5968c] {
            justify-content: flex-start;
            align-self: flex-start;
            margin: -5px;
            padding: 16px 0 0
        }
    }

    @media screen and (max-width: 680px) {
        .feedArticle__author_actions[data-v-caa5968c] {
            align-self: stretch
        }
    }

    .feedArticle__author_action[data-v-caa5968c] {
        margin: 5px;
        transition: opacity .2s
    }

    .feedArticle__author_action.m_hidden[data-v-caa5968c] {
        opacity: 0
    }

    @media screen and (max-width: 680px) {
        .feedArticle__author_action[data-v-caa5968c] {
            flex: 1 1 auto
        }
    }

    @media screen and (max-width: 960px) {
        .feedArticle__author[data-v-caa5968c] {
            flex-direction: column
        }
    }

    @media screen and (max-width: 680px) {
        .feedArticle__author[data-v-caa5968c] {
            margin-bottom: 25px
        }
    }

    .feedArticle__badges[data-v-caa5968c] {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        max-width: 700px;
        margin: 0 auto 10px
    }

    .feedArticle__badges_item[data-v-caa5968c] {
        display: block;
        margin: 0 0 5px;
        font-family: Inter, Helvetica Neue, Helvetica, sans-serif;
        font-stretch: normal;
        font-weight: 500;
        font-style: normal;
        font-size: 1.7rem;
        line-height: 1.41176471em;
        color: var(--color-secondary, #949494);
        text-align: left;
        text-decoration: none
    }

    .feedArticle__badges_item.m_clickable[data-v-caa5968c] {
        cursor: pointer;
        transition: all .4s
    }

    .feedArticle__badges_item.m_clickable[data-v-caa5968c]:hover {
        color: var(--color-primary, #1A1919)
    }

    .feedArticle__title[data-v-caa5968c] {
        display: block;
        max-width: 700px;
        margin: 0 auto .78947368em;
        font-family: Inter, Helvetica Neue, Helvetica, sans-serif;
        font-stretch: normal;
        font-weight: 800;
        font-style: normal;
        font-size: 3.8rem;
        line-height: 1.15em;
        color: var(--color-primary, #1A1919);
        text-align: left;
        word-wrap: break-word
    }

    .feedArticle__text[data-v-caa5968c] {
        margin-bottom: 20px;
        word-wrap: break-word;
        font-size: 1.7rem;
        line-height: 1.4em;
        display: block;
        --document-column-size: 700px
    }

    .feedArticle__info[data-v-caa5968c] {
        display: block;
        max-width: 700px;
        margin: 16px auto 0;
        border-top: 1px solid var(--color-divider, #EFEFEF)
    }

    .feedArticle__info_section[data-v-caa5968c] {
        display: block;
        margin-top: 16px
    }

    .feedArticle__comments[data-v-caa5968c] {
        display: block;
        max-width: 700px;
        margin: 0 auto
    }

    .feedArticle.m_center[data-v-caa5968c] {
        align-items: center;
        justify-content: center
    }

    @media screen and (max-width: 680px) {
        .feedArticle[data-v-caa5968c] {
            position: relative;
            flex: 1 1 auto;
            height: auto;
            max-height: 100%;
            min-height: 0;
            margin-top: 65px;
            border: 0;
            border-radius: 20px 20px 0 0;
            box-shadow: none;
            transition: margin .4s cubic-bezier(.18, .89, .32, 1.28)
        }

        .feedArticle__header[data-v-caa5968c] {
            position: absolute
        }

        .feedArticle__header_scroll[data-v-caa5968c] {
            display: flex
        }

        .feedArticle__header_button.m_mobile_hidden[data-v-caa5968c] {
            display: none
        }

        .feedArticle__header[data-v-caa5968c]:after {
            content: "";
            position: absolute;
            top: -13px;
            left: 50%;
            width: 40px;
            height: 6px;
            margin-left: -20px;
            border-radius: 6px;
            background-color: var(--color-divider, #EFEFEF);
            opacity: .7
        }

        .feedArticle__wrap[data-v-caa5968c] {
            padding-top: 51px
        }

        .feedArticle__title[data-v-caa5968c] {
            font-size: 2.7rem;
            line-height: 1.4em;
            margin-bottom: .74074074em
        }

        .feedArticle.m_touching[data-v-caa5968c] {
            transition: none
        }
    }

    .error[data-v-82c9dab4] {
        display: block;
        max-width: 400px;
        margin: 30px 0 80px;
        padding: 0 20px;
        text-align: center
    }

    .error__art[data-v-82c9dab4] {
        display: block;
        width: 100%;
        max-width: 150px;
        margin: 0 auto 30px
    }

    .error__header[data-v-82c9dab4] {
        display: block;
        margin: 0 0 .68181818em;
        font-family: Inter, Helvetica Neue, Helvetica, sans-serif;
        font-stretch: normal;
        font-weight: 700;
        font-style: normal;
        font-size: 2.2rem;
        line-height: 1.4em;
        color: var(--color-primary, #1A1919);
        text-align: center
    }

    .error__text[data-v-82c9dab4] {
        display: block;
        margin: 0 0 .58823529em;
        font-family: Inter, Helvetica Neue, Helvetica, sans-serif;
        font-stretch: normal;
        font-weight: 400;
        font-style: normal;
        font-size: 1.7rem;
        line-height: 1.4em;
        color: var(--color-primary, #1A1919);
        text-align: center
    }

    .error__text a[data-v-82c9dab4],
    .error__text .link[data-v-82c9dab4] {
        border: 0;
        color: var(--color-primary, #1A1919);
        text-decoration: underline;
        text-decoration-color: var(--color-primary-underline, rgba(26, 25, 25, .2));
        text-decoration-skip: ink;
        text-decoration-thickness: 1px;
        text-underline-offset: .15em;
        transition: all .4s;
        cursor: pointer
    }

    .error__text a[data-v-82c9dab4]:hover,
    .error__text .link[data-v-82c9dab4]:hover {
        color: var(--color-primary, #1A1919);
        text-decoration-color: var(--color-primary, #1A1919)
    }

    .error__link[data-v-82c9dab4] {
        display: block;
        margin: 0 0 .58823529em;
        font-family: Inter, Helvetica Neue, Helvetica, sans-serif;
        font-stretch: normal;
        font-weight: 400;
        font-style: normal;
        font-size: 1.7rem;
        line-height: 1.4em;
        color: var(--color-accent-action, #5B41F5);
        text-align: center
    }

    .error__link a[data-v-82c9dab4],
    .error__link .link[data-v-82c9dab4] {
        border: 0;
        color: var(--color-accent-action, #5B41F5);
        text-decoration: underline;
        text-decoration-color: var(--color-accent-action-underline, rgba(91, 65, 245, .3));
        text-decoration-skip: ink;
        text-decoration-thickness: 1px;
        text-underline-offset: .15em;
        transition: all .4s;
        cursor: pointer
    }

    .error__link a[data-v-82c9dab4]:hover,
    .error__link .link[data-v-82c9dab4]:hover {
        color: var(--color-accent-action, #5B41F5);
        text-decoration-color: var(--color-accent-action, #5B41F5)
    }

    @media screen and (max-width: 680px) {
        .error[data-v-82c9dab4] {
            padding-left: 16px;
            padding-right: 16px
        }
    }

    // @font-face {
    //     font-family: Inter;
    //     font-weight: 400;
    //     font-style: normal;
    //     font-display: swap;
    //     src: local("Inter-Regular"), local("Inter Regular"), url(./inter_regular.d612f121.woff2) format("woff2"), url(./inter_regular.ef1f23c0.woff) format("woff")
    // }

    // @font-face {
    //     font-family: Inter;
    //     font-weight: 400;
    //     font-style: italic;
    //     font-display: swap;
    //     src: local("Inter-Italic"), local("Inter Italic"), url(./inter_italic.900058df.woff2) format("woff2"), url(./inter_italic.cd1eda97.woff) format("woff")
    // }

    // @font-face {
    //     font-family: Inter;
    //     font-weight: 500;
    //     font-style: normal;
    //     font-display: swap;
    //     src: local("Inter-Medium"), local("Inter Medium"), url(./inter_medium.1b498b95.woff2) format("woff2"), url(./inter_medium.53deda46.woff) format("woff")
    // }

    // @font-face {
    //     font-family: Inter;
    //     font-weight: 500;
    //     font-style: italic;
    //     font-display: swap;
    //     src: local("Inter-MediumItalic"), local("Inter Medium Italic"), url(./inter_medium_italic.81600858.woff2) format("woff2"), url(./inter_medium_italic.205c8989.woff) format("woff")
    // }

    // @font-face {
    //     font-family: Inter;
    //     font-weight: 600;
    //     font-style: normal;
    //     font-display: swap;
    //     src: local("Inter-SemiBold"), local("Inter Semi Bold"), url(./inter_semibold.15226129.woff2) format("woff2"), url(./inter_semibold.653fed7a.woff) format("woff")
    // }

    // @font-face {
    //     font-family: Inter;
    //     font-weight: 700;
    //     font-style: normal;
    //     font-display: swap;
    //     src: local("Inter-Bold"), local("Inter Bold"), url(./inter_bold.c63158ba.woff2) format("woff2"), url(./inter_bold.3e242080.woff) format("woff")
    // }

    // @font-face {
    //     font-family: Inter;
    //     font-weight: 700;
    //     font-style: italic;
    //     font-display: swap;
    //     src: local("Inter-BoldItalic"), local("Inter Bold Italic"), url(./inter_bold_italic.3f211964.woff2) format("woff2"), url(./inter_bold_italic.ace8e094.woff) format("woff")
    // }

    // @font-face {
    //     font-family: Inter;
    //     font-weight: 800;
    //     font-style: normal;
    //     font-display: swap;
    //     src: local("Inter-ExtraBold"), local("Inter Extra Bold"), url(./inter_extrabold.307d9809.woff2) format("woff2"), url(./inter_extrabold.f053602c.woff) format("woff")
    // }

    // @font-face {
    //     font-family: FiraCode;
    //     font-weight: 400;
    //     font-style: normal;
    //     font-display: swap;
    //     src: local("FiraCode-Regular"), local("FiraCode Regular"), local("Fira Code"), url(./firacode_regular.105c876c.woff2) format("woff2"), url(./firacode_regular.33b9f771.woff) format("woff")
    // }

    // @font-face {
    //     font-family: FiraCode;
    //     font-weight: 200;
    //     font-style: normal;
    //     font-display: swap;
    //     src: local("FiraCode-Light"), local("FiraCode Light"), local("Fira Code"), url(./firacode_light.7ee25282.woff2) format("woff2"), url(./firacode_light.38774058.woff) format("woff")
    // }

    // @font-face {
    //     font-family: FiraCode;
    //     font-weight: 500;
    //     font-style: normal;
    //     font-display: swap;
    //     src: local("FiraCode-Medium"), local("FiraCode Medium"), local("Fira Code"), url(./firacode_medium.5067be62.woff2) format("woff2"), url(./firacode_medium.12bc481f.woff) format("woff")
    // }

    // @font-face {
    //     font-family: FiraCode;
    //     font-weight: 700;
    //     font-style: normal;
    //     font-display: swap;
    //     src: local("FiraCode-Bold"), local("FiraCode Bold"), local("Fira Code"), url(./firacode_bold.72e84e14.woff2) format("woff2"), url(./firacode_bold.78408fee.woff) format("woff")
    // }

    // @font-face {
    //     font-family: Ranua;
    //     font-weight: 700;
    //     font-style: normal;
    //     font-display: swap;
    //     src: local("Ranua Bold"), local("Ranua-Bold"), url(./ranua_bold.633db49a.woff2) format("woff2"), url(./ranua_bold.ea44fd85.woff) format("woff")
    // }



    @media (prefers-color-scheme: dark) {

        &[data-theme=auto],
        &:not([data-theme]) {
            --color-primary: #EBEBEB;
            --color-secondary: #696969;
            --color-shadow: rgba(0, 0, 0, .48);
            --color-shadow-2: rgba(0, 0, 0, .1);
            --color-mute-frame: rgba(0, 0, 0, .9);
            --color-bg-1: #232323;
            --color-bg-2: #2B2B2C;
            --color-bg-3: #AEAEAE;
            --color-bg-success: #385B49;
            --color-bg-warning: #70583A;
            --color-bg-alarm: #633636;
            --color-bg-blur: rgba(30, 30, 30, .7);
            --color-hover: #313131;
            --color-divider: #323232;
            --color-border: #393939;
            --color-highlight: #2F2F4C;
            --color-accent-alarm: #F35151;
            --color-accent-action: #6D68E4;
            --color-accent-warning: #FFCB2E;
            --color-accent-success: #13D986;
            --color-accent-lebedev: #262963;
            --color-accent-varlamov: #58A7D1;
            --color-on-primary: #0D0D0D;
            --color-on-secondary: #0D0D0D;
            --color-primary-underline: rgba(235, 235, 235, .2);
            --color-accent-action-underline: rgba(109, 104, 228, .3);
            --shadow-main: 0px 4px 80px rgba(0, 0, 0, .48);
            --shadow-card: 0px 12px 52px rgba(0, 0, 0, .1);
            --autocolor-lightness: 40%;
            --autocolor-background-lightness: 20%
        }
    }

    @keyframes preloader {
        000% {
            transform: rotate(0)
        }

        to {
            transform: rotate(360deg)
        }
    }

    & {
        background-color: var(--color-bg-1, #FFFFFF);
        border-color: var(--color-primary, #1A1919);
        color: var(--color-primary, #1A1919);
        font-family: Inter, Helvetica Neue, Helvetica, sans-serif;
        font-stretch: normal;
        font-weight: 400;
        font-style: normal;
        font-feature-settings: "kern"on, "liga"on, "calt"on, "tnum"off, "case"off, "rlig"on, "zero"off, "ss01"off;
        font-size: 10px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        -o-font-smoothing: antialiased;
        font-smoothing: antialiased
    }

    &.m_frame {
        background-color: var(--color-bg-2, #F6F6F6)
    }

    &,
    body {
        width: 100%;
        min-height: 100vh;
        min-width: 320px;
        margin: 0;
        padding: 0;
        box-sizing: border-box
    }

    &.m_fixed,
    body.m_fixed {
        position: fixed;
        height: 100vh;
        height: -webkit-fill-available;
        overflow: hidden;
        -webkit-overflow-scrolling: touch
    }

    body {
        position: relative;
        overflow-y: scroll;
        -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
        -webkit-focus-ring-color: rgba(255, 255, 255, 0);
        outline: none;
        -webkit-text-size-adjust: none
    }

    img {
        border: 0;
        vertical-align: top
    }

    #app {
        display: block;
        width: 100%;
        height: 100%;
        min-height: 100vh;
        margin: 0;
        padding: 0;
        box-sizing: border-box
    }

    .text h1,
    .text h2 {
        min-width: 0;
        max-width: var(--document-column-size, auto);
        margin: .53571429em auto .17857143em;
        min-height: 1.5em;
        font-family: Inter, Helvetica Neue, Helvetica, sans-serif;
        font-stretch: normal;
        font-weight: 700;
        font-style: normal;
        font-size: 1.64705882em;
        line-height: 1.5em;
        color: var(--color-primary, #1A1919);
        white-space: pre-wrap
    }

    .text h1:first-child,
    .text h2:first-child {
        margin-top: 0
    }

    .text h1:last-child,
    .text h2:last-child {
        margin-bottom: 0
    }

    .text h1[data-align=right],
    .text h2[data-align=right] {
        text-align: right
    }

    .text h1[data-align=center],
    .text h2[data-align=center] {
        text-align: center
    }

    @media screen and (max-width: 680px) {

        .text h1,
        .text h2 {
            margin-top: .625em;
            margin-bottom: .20833333em;
            font-size: 1.41176471em
        }
    }

    .text h3,
    .text h4,
    .text h5,
    .text h6 {
        min-width: 0;
        max-width: var(--document-column-size, auto);
        margin: .75em auto .25em;
        min-height: 1.5em;
        font-family: Inter, Helvetica Neue, Helvetica, sans-serif;
        font-stretch: normal;
        font-weight: 700;
        font-style: normal;
        font-size: 1.17647059em;
        line-height: 1.5em;
        color: var(--color-primary, #1A1919);
        white-space: pre-wrap
    }

    .text h3:first-child,
    .text h4:first-child,
    .text h5:first-child,
    .text h6:first-child {
        margin-top: 0
    }

    .text h3:last-child,
    .text h4:last-child,
    .text h5:last-child,
    .text h6:last-child {
        margin-bottom: 0
    }

    .text h3[data-align=right],
    .text h4[data-align=right],
    .text h5[data-align=right],
    .text h6[data-align=right] {
        text-align: right
    }

    .text h3[data-align=center],
    .text h4[data-align=center],
    .text h5[data-align=center],
    .text h6[data-align=center] {
        text-align: center
    }

    @media screen and (max-width: 680px) {

        .text h3,
        .text h4,
        .text h5,
        .text h6 {
            margin-top: .75em;
            margin-bottom: .25em;
            font-size: 1.17647059em
        }
    }

    .text h1+h3,
    .text h2+h3,
    .text h1+h4,
    .text h2+h4,
    .text h1+h5,
    .text h2+h5,
    .text h1+h6,
    .text h2+h6 {
        margin-top: .5em
    }

    @media screen and (max-width: 680px) {

        .text h1+h3,
        .text h2+h3,
        .text h1+h4,
        .text h2+h4,
        .text h1+h5,
        .text h2+h5,
        .text h1+h6,
        .text h2+h6 {
            margin-top: .5em
        }
    }

    .text p {
        min-width: 0;
        max-width: var(--document-column-size, auto);
        margin: 0 auto .88235294em;
        min-height: 1.5em;
        font-family: Inter, Helvetica Neue, Helvetica, sans-serif;
        font-stretch: normal;
        font-weight: 400;
        font-style: normal;
        font-size: 1em;
        line-height: 1.5em;
        color: var(--color-primary, #1A1919);
        white-space: pre-wrap
    }

    .text p:first-child {
        margin-top: 0
    }

    .text p:last-child {
        margin-bottom: 0
    }

    .text p[data-align=right] {
        text-align: right
    }

    .text p[data-align=center] {
        text-align: center
    }

    .text blockquote {
        position: relative;
        display: block;
        min-width: 0;
        max-width: var(--document-column-size, auto);
        margin: 0 auto .88235294em;
        min-height: 1.5em;
        padding-left: .70588235em;
        box-sizing: border-box;
        font-family: Inter, Helvetica Neue, Helvetica, sans-serif;
        font-stretch: normal;
        font-weight: 400;
        font-style: normal;
        font-size: 1em;
        line-height: 1.5em;
        color: var(--color-primary, #1A1919);
        white-space: pre-wrap;
        opacity: .7
    }

    .text blockquote:first-child {
        margin-top: 0
    }

    .text blockquote:last-child {
        margin-bottom: 0
    }

    .text blockquote[data-align=right] {
        text-align: right
    }

    .text blockquote[data-align=center] {
        text-align: center
    }

    .text blockquote:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 2px;
        border-radius: 2px;
        background: var(--color-primary, #1A1919)
    }

    .text blockquote[data-align=right] {
        padding-left: 0;
        padding-right: .70588235em
    }

    .text blockquote[data-align=right]:before {
        left: auto;
        right: 0
    }

    .text blockquote[data-align=center] {
        padding-left: 0;
        padding-top: .58823529em
    }

    .text blockquote[data-align=center]:before {
        top: 0;
        left: 50%;
        bottom: auto;
        width: 64px;
        height: 2px;
        margin-left: -32px
    }

    .text ol>li::marker {
        content: counter(list) ". ";
        font-feature-settings: "kern"on, "liga"on, "calt"on, "tnum"on, "case"off, "rlig"on, "zero"off, "ss01"off
    }

    .text ul,
    .text ol {
        min-width: 0;
        max-width: var(--document-column-size, auto);
        margin: 0 auto .88235294em;
        box-sizing: border-box;
        font-size: 1em
    }

    .text ul:first-child,
    .text ol:first-child {
        margin-top: 0
    }

    .text ul:last-child,
    .text ol:last-child {
        margin-bottom: 0
    }

    .text ul>li,
    .text ol>li {
        counter-reset: list 0;
        counter-increment: list;
        position: relative;
        min-height: 1.5em;
        margin-top: 0;
        margin-bottom: .58823529em;
        padding: 0;
        font-family: Inter, Helvetica Neue, Helvetica, sans-serif;
        font-stretch: normal;
        font-weight: 400;
        font-style: normal;
        font-size: 1em;
        line-height: 1.5em;
        color: var(--color-primary, #1A1919);
        white-space: pre-wrap;
        text-align: left
    }

    .text ul>li:first-child,
    .text ol>li:first-child {
        margin-top: 0
    }

    .text ul>li:last-child,
    .text ol>li:last-child {
        margin-bottom: 0
    }

    .text ul>li~li,
    .text ol>li~li {
        counter-reset: none
    }

    .text ul>ul,
    .text ol>ul,
    .text ul>ol,
    .text ol>ol {
        margin-top: 0;
        margin-bottom: .58823529em
    }

    .text ul[data-align=center],
    .text ol[data-align=center] {
        list-style-position: inside;
        text-align: center
    }

    .text ul[data-align=right],
    .text ol[data-align=right] {
        list-style-position: inside;
        direction: rtl
    }

    .text p+ul,
    .text p+ol {
        margin-top: -.29411765em
    }

    .text section {
        min-width: 0;
        max-width: var(--document-column-size, auto);
        margin: 0 auto .88235294em;
        min-height: 1.5em;
        padding: 20px;
        box-sizing: border-box;
        border-radius: 7px;
        background-color: var(--color-bg-2, #F6F6F6);
        font-size: 1em
    }

    .text section:empty:after {
        content: "";
        display: inline-block;
        min-height: 1.5em
    }

    .text section:first-child {
        margin-top: 0
    }

    .text section:last-child {
        margin-bottom: 0
    }

    @media screen and (max-width: 960px) {
        .text section {
            padding: 20px
        }
    }

    @media screen and (max-width: 680px) {
        .text section {
            padding: 16px
        }
    }

    .text .contents {
        position: relative;
        min-width: 0;
        max-width: var(--document-column-size, auto);
        margin: 0 auto .88235294em;
        padding: 0;
        box-sizing: border-box;
        font-size: 1em
    }

    .text .contents:first-child {
        margin-top: 0
    }

    .text .contents:last-child {
        margin-bottom: 0
    }

    .text .contents_list {
        list-style-type: none;
        margin: 0;
        padding: 0
    }

    .text .contents_item {
        display: block;
        margin-top: 0;
        margin-bottom: .58823529em;
        padding: 0;
        white-space: pre-wrap;
        text-align: left
    }

    .text .contents_item:first-child {
        margin-top: 0
    }

    .text .contents_item:last-child {
        margin-bottom: 0
    }

    .text .contents_item.m_level_1 {
        margin-left: 0
    }

    .text .contents_item.m_level_2 {
        margin-left: 16px
    }

    .text .contents_item.m_level_3 {
        margin-left: 32px
    }

    .text .contents_item.m_level_4 {
        margin-left: 48px
    }

    .text .contents_item.m_level_5 {
        margin-left: 64px
    }

    .text .contents a,
    .text .contents_link {
        font-family: Inter, Helvetica Neue, Helvetica, sans-serif;
        font-stretch: normal;
        font-weight: 400;
        font-style: normal;
        font-size: .88235294em;
        line-height: 1.5em;
        border: 0;
        color: var(--color-primary, #1A1919);
        text-decoration: underline;
        text-decoration-color: var(--color-primary-underline, rgba(26, 25, 25, .2));
        text-decoration-skip: ink;
        text-decoration-thickness: 1px;
        text-underline-offset: .15em;
        transition: all .4s;
        cursor: pointer;
        opacity: .6
    }

    .text .contents a:hover,
    .text .contents_link:hover {
        color: var(--color-primary, #1A1919);
        text-decoration-color: var(--color-primary-underline, rgba(26, 25, 25, .2))
    }

    .text .contents a:hover,
    .text .contents_link:hover {
        opacity: 1
    }

    .text hr {
        display: block;
        width: 100%;
        height: 1px;
        min-width: 0;
        max-width: var(--document-column-size, auto);
        margin: 0 auto .88235294em;
        border: 0;
        outline: 0;
        box-shadow: none;
        background: var(--color-divider, #EFEFEF);
        font-size: 1em;
        color: transparent;
        line-height: 1em
    }

    .text hr:after {
        display: block;
        content: " ";
        text-align: center;
        line-height: 1em
    }

    .text figure {
        position: relative;
        display: block;
        min-width: 0;
        margin: 0 auto .88235294em;
        width: 100%;
        min-width: 100px;
        max-width: 1900px;
        padding: 0;
        font-size: 1em;
        text-align: center;
        vertical-align: top
    }

    .text figure .wrap {
        position: relative;
        display: inline-block;
        max-width: 100%;
        margin: 0 -20px;
        text-align: center;
        vertical-align: top
    }

    .text figure .wrap .twitter-tweet {
        width: 550px !important;
        max-width: 100% !important
    }

    @media screen and (max-width: 680px) {
        .text figure .wrap {
            margin-left: -16px;
            margin-right: -16px
        }
    }

    .text figure .spacer {
        display: block;
        min-width: 100px;
        max-width: 100%;
        min-height: 15px;
        box-sizing: border-box;
        vertical-align: top
    }

    .text figure .loader {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        -webkit-backdrop-filter: blur(10px);
        -moz-backdrop-filter: blur(10px);
        backdrop-filter: blur(10px);
        transition: all .4s;
        color: var(--color-secondary, #949494)
    }

    .text figure .loader.m_hidden {
        opacity: 0
    }

    .text figure.m_column {
        min-width: 0;
        max-width: var(--document-column-size, auto);
        margin-left: auto;
        margin-right: auto
    }

    .text figure.m_column .wrap {
        margin: 0;
        width: 100%
    }

    .text figure:first-child {
        margin-top: 0
    }

    .text figure:last-child {
        margin-bottom: 0
    }

    .text figure img,
    .text figure video {
        position: relative;
        display: inline-block;
        height: auto;
        max-width: 100%;
        vertical-align: top;
        border: 0;
        transition: opacity .2s
    }

    .text figure img.m_hidden,
    .text figure video.m_hidden {
        opacity: 0
    }

    .text iframe {
        display: block;
        margin: .88235294em auto;
        border: 0;
        background-color: transparent
    }

    .text figure iframe {
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        width: 0;
        height: 0;
        min-width: 100%;
        min-height: 100%;
        margin: 0;
        vertical-align: top
    }

    .text figure .embed-instagram {
        box-sizing: border-box;
        border-radius: 7px;
        border: 1px solid var(--color-divider, #EFEFEF)
    }

    .text figure blockquote {
        visibility: hidden
    }

    .text figure>[draggable] iframe {
        position: relative;
        display: inline-block;
        min-width: 0;
        max-width: var(--document-column-size, auto);
        height: 4.86111111px;
        border: 0;
        background-color: transparent;
        margin: 0;
        outline: 0;
        vertical-align: top
    }

    .text figure figcaption {
        display: block;
        min-width: 0;
        max-width: var(--document-column-size, auto);
        margin: .83333333em auto 0;
        min-height: 1.5em;
        font-family: Inter, Helvetica Neue, Helvetica, sans-serif;
        font-stretch: normal;
        font-weight: 400;
        font-style: normal;
        font-size: .70588235em;
        line-height: 1.5em;
        color: var(--color-secondary, #949494);
        text-align: left;
        line-height: normal;
        white-space: pre-wrap
    }

    .text figure figcaption[data-align=right] {
        text-align: right
    }

    .text figure figcaption[data-align=center] {
        text-align: center
    }

    .text figure[data-caption-align=right] figcaption {
        text-align: right
    }

    .text figure[data-caption-align=center] figcaption {
        text-align: center
    }

    .text pre {
        display: block;
        min-width: 0;
        max-width: var(--document-column-size, auto);
        margin: 0 auto .88235294em;
        min-height: 1.5em;
        padding: .5em;
        box-sizing: border-box;
        border-radius: .5em;
        overflow: auto;
        background-color: var(--color-bg-2, #F6F6F6);
        font-family: FiraCode, SFMono-Regular, Consolas, Liberation Mono, Menlo, Monaco, Courier, monospace;
        font-stretch: normal;
        font-weight: 400;
        font-style: normal;
        letter-spacing: normal;
        font-feature-settings: "ss01"off, "ss02"off, "ss03"off, "ss04"on, "ss05"off, "ss06"on, "ss07"off, "zero"off, "onum"off;
        font-size: .87em;
        color: var(--color-primary, #1A1919);
        line-height: 1.5em;
        text-align: left;
        white-space: pre;
        word-spacing: normal;
        word-break: normal;
        word-wrap: normal;
        -moz-tab-size: 4;
        tab-size: 4;
        -webkit-hyphens: none;
        -moz-hyphens: none;
        hyphens: none
    }

    .text pre:first-child {
        margin-top: 0
    }

    .text pre:last-child {
        margin-bottom: 0
    }

    *[data-theme=light] .text code[data-lang],
    *[data-theme=light] .text pre[data-lang] {
        color: #383a42
    }

    *[data-theme=light] .text code[data-lang] .token.comment,
    *[data-theme=light] .text pre[data-lang] .token.comment,
    *[data-theme=light] .text code[data-lang] .token.prolog,
    *[data-theme=light] .text pre[data-lang] .token.prolog,
    *[data-theme=light] .text code[data-lang] .token.cdata,
    *[data-theme=light] .text pre[data-lang] .token.cdata {
        color: #a0a1a7
    }

    *[data-theme=light] .text code[data-lang] .token.doctype,
    *[data-theme=light] .text pre[data-lang] .token.doctype,
    *[data-theme=light] .text code[data-lang] .token.punctuation,
    *[data-theme=light] .text pre[data-lang] .token.punctuation,
    *[data-theme=light] .text code[data-lang] .token.entity,
    *[data-theme=light] .text pre[data-lang] .token.entity {
        color: #383a42
    }

    *[data-theme=light] .text code[data-lang] .token.attr-name,
    *[data-theme=light] .text pre[data-lang] .token.attr-name,
    *[data-theme=light] .text code[data-lang] .token.class-name,
    *[data-theme=light] .text pre[data-lang] .token.class-name,
    *[data-theme=light] .text code[data-lang] .token.boolean,
    *[data-theme=light] .text pre[data-lang] .token.boolean,
    *[data-theme=light] .text code[data-lang] .token.constant,
    *[data-theme=light] .text pre[data-lang] .token.constant,
    *[data-theme=light] .text code[data-lang] .token.number,
    *[data-theme=light] .text pre[data-lang] .token.number,
    *[data-theme=light] .text code[data-lang] .token.atrule,
    *[data-theme=light] .text pre[data-lang] .token.atrule {
        color: #b76b01
    }

    *[data-theme=light] .text code[data-lang] .token.keyword,
    *[data-theme=light] .text pre[data-lang] .token.keyword {
        color: #a626a4
    }

    *[data-theme=light] .text code[data-lang] .token.property,
    *[data-theme=light] .text pre[data-lang] .token.property,
    *[data-theme=light] .text code[data-lang] .token.tag,
    *[data-theme=light] .text pre[data-lang] .token.tag,
    *[data-theme=light] .text code[data-lang] .token.symbol,
    *[data-theme=light] .text pre[data-lang] .token.symbol,
    *[data-theme=light] .text code[data-lang] .token.deleted,
    *[data-theme=light] .text pre[data-lang] .token.deleted,
    *[data-theme=light] .text code[data-lang] .token.important,
    *[data-theme=light] .text pre[data-lang] .token.important {
        color: #e45649
    }

    *[data-theme=light] .text code[data-lang] .token.selector,
    *[data-theme=light] .text pre[data-lang] .token.selector,
    *[data-theme=light] .text code[data-lang] .token.string,
    *[data-theme=light] .text pre[data-lang] .token.string,
    *[data-theme=light] .text code[data-lang] .token.char,
    *[data-theme=light] .text pre[data-lang] .token.char,
    *[data-theme=light] .text code[data-lang] .token.builtin,
    *[data-theme=light] .text pre[data-lang] .token.builtin,
    *[data-theme=light] .text code[data-lang] .token.inserted,
    *[data-theme=light] .text pre[data-lang] .token.inserted,
    *[data-theme=light] .text code[data-lang] .token.regex,
    *[data-theme=light] .text pre[data-lang] .token.regex,
    *[data-theme=light] .text code[data-lang] .token.attr-value,
    *[data-theme=light] .text pre[data-lang] .token.attr-value,
    *[data-theme=light] .text code[data-lang] .token.attr-value>.token.punctuation,
    *[data-theme=light] .text pre[data-lang] .token.attr-value>.token.punctuation {
        color: #50a14f
    }

    *[data-theme=light] .text code[data-lang] .token.variable,
    *[data-theme=light] .text pre[data-lang] .token.variable,
    *[data-theme=light] .text code[data-lang] .token.operator,
    *[data-theme=light] .text pre[data-lang] .token.operator,
    *[data-theme=light] .text code[data-lang] .token.function,
    *[data-theme=light] .text pre[data-lang] .token.function {
        color: #4078f2
    }

    *[data-theme=light] .text code[data-lang] .token.url,
    *[data-theme=light] .text pre[data-lang] .token.url {
        color: #0184bc
    }

    *[data-theme=light] .text code[data-lang] .token.attr-value>.token.punctuation.attr-equals,
    *[data-theme=light] .text pre[data-lang] .token.attr-value>.token.punctuation.attr-equals,
    *[data-theme=light] .text code[data-lang] .token.special-attr>.token.attr-value>.token.value.css,
    *[data-theme=light] .text pre[data-lang] .token.special-attr>.token.attr-value>.token.value.css {
        color: #383a42
    }

    *[data-theme=light] .text code[data-lang][data-lang=css] .token.selector,
    *[data-theme=light] .text pre[data-lang][data-lang=css] .token.selector,
    *[data-theme=light] .text code[data-lang] .language-css .token.selector,
    *[data-theme=light] .text pre[data-lang] .language-css .token.selector {
        color: #e45649
    }

    *[data-theme=light] .text code[data-lang][data-lang=css] .token.property,
    *[data-theme=light] .text pre[data-lang][data-lang=css] .token.property,
    *[data-theme=light] .text code[data-lang] .language-css .token.property,
    *[data-theme=light] .text pre[data-lang] .language-css .token.property {
        color: #383a42
    }

    *[data-theme=light] .text code[data-lang][data-lang=css] .token.function,
    *[data-theme=light] .text pre[data-lang][data-lang=css] .token.function,
    *[data-theme=light] .text code[data-lang] .language-css .token.function,
    *[data-theme=light] .text pre[data-lang] .language-css .token.function,
    *[data-theme=light] .text code[data-lang][data-lang=css] .token.url>.token.function,
    *[data-theme=light] .text pre[data-lang][data-lang=css] .token.url>.token.function,
    *[data-theme=light] .text code[data-lang] .language-css .token.url>.token.function,
    *[data-theme=light] .text pre[data-lang] .language-css .token.url>.token.function {
        color: #0184bc
    }

    *[data-theme=light] .text code[data-lang][data-lang=css] .token.url>.token.string.url,
    *[data-theme=light] .text pre[data-lang][data-lang=css] .token.url>.token.string.url,
    *[data-theme=light] .text code[data-lang] .language-css .token.url>.token.string.url,
    *[data-theme=light] .text pre[data-lang] .language-css .token.url>.token.string.url {
        color: #50a14f
    }

    *[data-theme=light] .text code[data-lang][data-lang=css] .token.important,
    *[data-theme=light] .text pre[data-lang][data-lang=css] .token.important,
    *[data-theme=light] .text code[data-lang] .language-css .token.important,
    *[data-theme=light] .text pre[data-lang] .language-css .token.important,
    *[data-theme=light] .text code[data-lang][data-lang=css] .token.atrule .token.rule,
    *[data-theme=light] .text pre[data-lang][data-lang=css] .token.atrule .token.rule,
    *[data-theme=light] .text code[data-lang] .language-css .token.atrule .token.rule,
    *[data-theme=light] .text pre[data-lang] .language-css .token.atrule .token.rule,
    *[data-theme=light] .text code[data-lang][data-lang=javascript] .token.operator .language-javascript .token.operator,
    *[data-theme=light] .text pre[data-lang][data-lang=javascript] .token.operator .language-javascript .token.operator {
        color: #a626a4
    }

    *[data-theme=light] .text code[data-lang][data-lang=javascript] .token.template-string>.token.interpolation>.token.interpolation-punctuation.punctuation,
    *[data-theme=light] .text pre[data-lang][data-lang=javascript] .token.template-string>.token.interpolation>.token.interpolation-punctuation.punctuation,
    *[data-theme=light] .text code[data-lang] .language-javascript .token.template-string>.token.interpolation>.token.interpolation-punctuation.punctuation,
    *[data-theme=light] .text pre[data-lang] .language-javascript .token.template-string>.token.interpolation>.token.interpolation-punctuation.punctuation {
        color: #ca1243
    }

    *[data-theme=light] .text code[data-lang][data-lang=json] .token.operator,
    *[data-theme=light] .text pre[data-lang][data-lang=json] .token.operator,
    *[data-theme=light] .text code[data-lang] .language-json .token.operator,
    *[data-theme=light] .text pre[data-lang] .language-json .token.operator {
        color: #383a42
    }

    *[data-theme=light] .text code[data-lang][data-lang=json] .token.null.keyword,
    *[data-theme=light] .text pre[data-lang][data-lang=json] .token.null.keyword,
    *[data-theme=light] .text code[data-lang] .language-json .token.null.keyword,
    *[data-theme=light] .text pre[data-lang] .language-json .token.null.keyword {
        color: #b76b01
    }

    *[data-theme=light] .text code[data-lang][data-lang=markdown] .token.url,
    *[data-theme=light] .text pre[data-lang][data-lang=markdown] .token.url,
    *[data-theme=light] .text code[data-lang] .language-markdown .token.url,
    *[data-theme=light] .text pre[data-lang] .language-markdown .token.url,
    *[data-theme=light] .text code[data-lang][data-lang=markdown] .token.url>.token.operator,
    *[data-theme=light] .text pre[data-lang][data-lang=markdown] .token.url>.token.operator,
    *[data-theme=light] .text code[data-lang] .language-markdown .token.url>.token.operator,
    *[data-theme=light] .text pre[data-lang] .language-markdown .token.url>.token.operator,
    *[data-theme=light] .text code[data-lang][data-lang=markdown] .token.url-reference.url>.token.string,
    *[data-theme=light] .text pre[data-lang][data-lang=markdown] .token.url-reference.url>.token.string,
    *[data-theme=light] .text code[data-lang] .language-markdown .token.url-reference.url>.token.string,
    *[data-theme=light] .text pre[data-lang] .language-markdown .token.url-reference.url>.token.string {
        color: #383a42
    }

    *[data-theme=light] .text code[data-lang][data-lang=markdown] .token.url>.token.content,
    *[data-theme=light] .text pre[data-lang][data-lang=markdown] .token.url>.token.content,
    *[data-theme=light] .text code[data-lang] .language-markdown .token.url>.token.content,
    *[data-theme=light] .text pre[data-lang] .language-markdown .token.url>.token.content {
        color: #4078f2
    }

    *[data-theme=light] .text code[data-lang][data-lang=markdown] .token.url>.token.url,
    *[data-theme=light] .text pre[data-lang][data-lang=markdown] .token.url>.token.url,
    *[data-theme=light] .text code[data-lang] .language-markdown .token.url>.token.url,
    *[data-theme=light] .text pre[data-lang] .language-markdown .token.url>.token.url,
    *[data-theme=light] .text code[data-lang][data-lang=markdown] .token.url-reference.url,
    *[data-theme=light] .text pre[data-lang][data-lang=markdown] .token.url-reference.url,
    *[data-theme=light] .text code[data-lang] .language-markdown .token.url-reference.url,
    *[data-theme=light] .text pre[data-lang] .language-markdown .token.url-reference.url {
        color: #0184bc
    }

    *[data-theme=light] .text code[data-lang][data-lang=markdown] .token.blockquote.punctuation,
    *[data-theme=light] .text pre[data-lang][data-lang=markdown] .token.blockquote.punctuation,
    *[data-theme=light] .text code[data-lang] .language-markdown .token.blockquote.punctuation,
    *[data-theme=light] .text pre[data-lang] .language-markdown .token.blockquote.punctuation,
    *[data-theme=light] .text code[data-lang][data-lang=markdown] .token.hr.punctuation,
    *[data-theme=light] .text pre[data-lang][data-lang=markdown] .token.hr.punctuation,
    *[data-theme=light] .text code[data-lang] .language-markdown .token.hr.punctuation,
    *[data-theme=light] .text pre[data-lang] .language-markdown .token.hr.punctuation {
        color: #a0a1a7;
        font-style: italic
    }

    *[data-theme=light] .text code[data-lang][data-lang=markdown] .token.code-snippet,
    *[data-theme=light] .text pre[data-lang][data-lang=markdown] .token.code-snippet,
    *[data-theme=light] .text code[data-lang] .language-markdown .token.code-snippet,
    *[data-theme=light] .text pre[data-lang] .language-markdown .token.code-snippet {
        color: #50a14f
    }

    *[data-theme=light] .text code[data-lang][data-lang=markdown] .token.bold .token.content,
    *[data-theme=light] .text pre[data-lang][data-lang=markdown] .token.bold .token.content,
    *[data-theme=light] .text code[data-lang] .language-markdown .token.bold .token.content,
    *[data-theme=light] .text pre[data-lang] .language-markdown .token.bold .token.content {
        color: #b76b01
    }

    *[data-theme=light] .text code[data-lang][data-lang=markdown] .token.italic .token.content,
    *[data-theme=light] .text pre[data-lang][data-lang=markdown] .token.italic .token.content,
    *[data-theme=light] .text code[data-lang] .language-markdown .token.italic .token.content,
    *[data-theme=light] .text pre[data-lang] .language-markdown .token.italic .token.content {
        color: #a626a4
    }

    *[data-theme=light] .text code[data-lang][data-lang=markdown] .token.strike .token.content,
    *[data-theme=light] .text pre[data-lang][data-lang=markdown] .token.strike .token.content,
    *[data-theme=light] .text code[data-lang] .language-markdown .token.strike .token.content,
    *[data-theme=light] .text pre[data-lang] .language-markdown .token.strike .token.content,
    *[data-theme=light] .text code[data-lang][data-lang=markdown] .token.strike .token.punctuation,
    *[data-theme=light] .text pre[data-lang][data-lang=markdown] .token.strike .token.punctuation,
    *[data-theme=light] .text code[data-lang] .language-markdown .token.strike .token.punctuation,
    *[data-theme=light] .text pre[data-lang] .language-markdown .token.strike .token.punctuation,
    *[data-theme=light] .text code[data-lang][data-lang=markdown] .token.list.punctuation,
    *[data-theme=light] .text pre[data-lang][data-lang=markdown] .token.list.punctuation,
    *[data-theme=light] .text code[data-lang] .language-markdown .token.list.punctuation,
    *[data-theme=light] .text pre[data-lang] .language-markdown .token.list.punctuation,
    *[data-theme=light] .text code[data-lang][data-lang=markdown] .token.title.important>.token.punctuation,
    *[data-theme=light] .text pre[data-lang][data-lang=markdown] .token.title.important>.token.punctuation,
    *[data-theme=light] .text code[data-lang] .language-markdown .token.title.important>.token.punctuation,
    *[data-theme=light] .text pre[data-lang] .language-markdown .token.title.important>.token.punctuation {
        color: #e45649
    }

    *[data-theme=light] .text code[data-lang] .token.bold,
    *[data-theme=light] .text pre[data-lang] .token.bold {
        font-weight: 700
    }

    *[data-theme=light] .text code[data-lang] .token.comment,
    *[data-theme=light] .text pre[data-lang] .token.comment,
    *[data-theme=light] .text code[data-lang] .token.italic,
    *[data-theme=light] .text pre[data-lang] .token.italic {
        font-style: italic
    }

    *[data-theme=light] .text code[data-lang] .token.entity,
    *[data-theme=light] .text pre[data-lang] .token.entity {
        cursor: help
    }

    *[data-theme=light] .text code[data-lang] .token.namespace,
    *[data-theme=light] .text pre[data-lang] .token.namespace {
        opacity: .8
    }

    *[data-theme=light] .text code[data-lang] .token.token.tab:not(:empty):before,
    *[data-theme=light] .text pre[data-lang] .token.token.tab:not(:empty):before,
    *[data-theme=light] .text code[data-lang] .token.token.cr:before,
    *[data-theme=light] .text pre[data-lang] .token.token.cr:before,
    *[data-theme=light] .text code[data-lang] .token.token.lf:before,
    *[data-theme=light] .text pre[data-lang] .token.token.lf:before,
    *[data-theme=light] .text code[data-lang] .token.token.space:before,
    *[data-theme=light] .text pre[data-lang] .token.token.space:before {
        color: #383a4233
    }

    @media (prefers-color-scheme: light) {

        *[data-theme=auto] .text code[data-lang],
        *[data-theme=auto] .text pre[data-lang] {
            color: #383a42
        }

        *[data-theme=auto] .text code[data-lang] .token.comment,
        *[data-theme=auto] .text pre[data-lang] .token.comment,
        *[data-theme=auto] .text code[data-lang] .token.prolog,
        *[data-theme=auto] .text pre[data-lang] .token.prolog,
        *[data-theme=auto] .text code[data-lang] .token.cdata,
        *[data-theme=auto] .text pre[data-lang] .token.cdata {
            color: #a0a1a7
        }

        *[data-theme=auto] .text code[data-lang] .token.doctype,
        *[data-theme=auto] .text pre[data-lang] .token.doctype,
        *[data-theme=auto] .text code[data-lang] .token.punctuation,
        *[data-theme=auto] .text pre[data-lang] .token.punctuation,
        *[data-theme=auto] .text code[data-lang] .token.entity,
        *[data-theme=auto] .text pre[data-lang] .token.entity {
            color: #383a42
        }

        *[data-theme=auto] .text code[data-lang] .token.attr-name,
        *[data-theme=auto] .text pre[data-lang] .token.attr-name,
        *[data-theme=auto] .text code[data-lang] .token.class-name,
        *[data-theme=auto] .text pre[data-lang] .token.class-name,
        *[data-theme=auto] .text code[data-lang] .token.boolean,
        *[data-theme=auto] .text pre[data-lang] .token.boolean,
        *[data-theme=auto] .text code[data-lang] .token.constant,
        *[data-theme=auto] .text pre[data-lang] .token.constant,
        *[data-theme=auto] .text code[data-lang] .token.number,
        *[data-theme=auto] .text pre[data-lang] .token.number,
        *[data-theme=auto] .text code[data-lang] .token.atrule,
        *[data-theme=auto] .text pre[data-lang] .token.atrule {
            color: #b76b01
        }

        *[data-theme=auto] .text code[data-lang] .token.keyword,
        *[data-theme=auto] .text pre[data-lang] .token.keyword {
            color: #a626a4
        }

        *[data-theme=auto] .text code[data-lang] .token.property,
        *[data-theme=auto] .text pre[data-lang] .token.property,
        *[data-theme=auto] .text code[data-lang] .token.tag,
        *[data-theme=auto] .text pre[data-lang] .token.tag,
        *[data-theme=auto] .text code[data-lang] .token.symbol,
        *[data-theme=auto] .text pre[data-lang] .token.symbol,
        *[data-theme=auto] .text code[data-lang] .token.deleted,
        *[data-theme=auto] .text pre[data-lang] .token.deleted,
        *[data-theme=auto] .text code[data-lang] .token.important,
        *[data-theme=auto] .text pre[data-lang] .token.important {
            color: #e45649
        }

        *[data-theme=auto] .text code[data-lang] .token.selector,
        *[data-theme=auto] .text pre[data-lang] .token.selector,
        *[data-theme=auto] .text code[data-lang] .token.string,
        *[data-theme=auto] .text pre[data-lang] .token.string,
        *[data-theme=auto] .text code[data-lang] .token.char,
        *[data-theme=auto] .text pre[data-lang] .token.char,
        *[data-theme=auto] .text code[data-lang] .token.builtin,
        *[data-theme=auto] .text pre[data-lang] .token.builtin,
        *[data-theme=auto] .text code[data-lang] .token.inserted,
        *[data-theme=auto] .text pre[data-lang] .token.inserted,
        *[data-theme=auto] .text code[data-lang] .token.regex,
        *[data-theme=auto] .text pre[data-lang] .token.regex,
        *[data-theme=auto] .text code[data-lang] .token.attr-value,
        *[data-theme=auto] .text pre[data-lang] .token.attr-value,
        *[data-theme=auto] .text code[data-lang] .token.attr-value>.token.punctuation,
        *[data-theme=auto] .text pre[data-lang] .token.attr-value>.token.punctuation {
            color: #50a14f
        }

        *[data-theme=auto] .text code[data-lang] .token.variable,
        *[data-theme=auto] .text pre[data-lang] .token.variable,
        *[data-theme=auto] .text code[data-lang] .token.operator,
        *[data-theme=auto] .text pre[data-lang] .token.operator,
        *[data-theme=auto] .text code[data-lang] .token.function,
        *[data-theme=auto] .text pre[data-lang] .token.function {
            color: #4078f2
        }

        *[data-theme=auto] .text code[data-lang] .token.url,
        *[data-theme=auto] .text pre[data-lang] .token.url {
            color: #0184bc
        }

        *[data-theme=auto] .text code[data-lang] .token.attr-value>.token.punctuation.attr-equals,
        *[data-theme=auto] .text pre[data-lang] .token.attr-value>.token.punctuation.attr-equals,
        *[data-theme=auto] .text code[data-lang] .token.special-attr>.token.attr-value>.token.value.css,
        *[data-theme=auto] .text pre[data-lang] .token.special-attr>.token.attr-value>.token.value.css {
            color: #383a42
        }

        *[data-theme=auto] .text code[data-lang][data-lang=css] .token.selector,
        *[data-theme=auto] .text pre[data-lang][data-lang=css] .token.selector,
        *[data-theme=auto] .text code[data-lang] .language-css .token.selector,
        *[data-theme=auto] .text pre[data-lang] .language-css .token.selector {
            color: #e45649
        }

        *[data-theme=auto] .text code[data-lang][data-lang=css] .token.property,
        *[data-theme=auto] .text pre[data-lang][data-lang=css] .token.property,
        *[data-theme=auto] .text code[data-lang] .language-css .token.property,
        *[data-theme=auto] .text pre[data-lang] .language-css .token.property {
            color: #383a42
        }

        *[data-theme=auto] .text code[data-lang][data-lang=css] .token.function,
        *[data-theme=auto] .text pre[data-lang][data-lang=css] .token.function,
        *[data-theme=auto] .text code[data-lang] .language-css .token.function,
        *[data-theme=auto] .text pre[data-lang] .language-css .token.function,
        *[data-theme=auto] .text code[data-lang][data-lang=css] .token.url>.token.function,
        *[data-theme=auto] .text pre[data-lang][data-lang=css] .token.url>.token.function,
        *[data-theme=auto] .text code[data-lang] .language-css .token.url>.token.function,
        *[data-theme=auto] .text pre[data-lang] .language-css .token.url>.token.function {
            color: #0184bc
        }

        *[data-theme=auto] .text code[data-lang][data-lang=css] .token.url>.token.string.url,
        *[data-theme=auto] .text pre[data-lang][data-lang=css] .token.url>.token.string.url,
        *[data-theme=auto] .text code[data-lang] .language-css .token.url>.token.string.url,
        *[data-theme=auto] .text pre[data-lang] .language-css .token.url>.token.string.url {
            color: #50a14f
        }

        *[data-theme=auto] .text code[data-lang][data-lang=css] .token.important,
        *[data-theme=auto] .text pre[data-lang][data-lang=css] .token.important,
        *[data-theme=auto] .text code[data-lang] .language-css .token.important,
        *[data-theme=auto] .text pre[data-lang] .language-css .token.important,
        *[data-theme=auto] .text code[data-lang][data-lang=css] .token.atrule .token.rule,
        *[data-theme=auto] .text pre[data-lang][data-lang=css] .token.atrule .token.rule,
        *[data-theme=auto] .text code[data-lang] .language-css .token.atrule .token.rule,
        *[data-theme=auto] .text pre[data-lang] .language-css .token.atrule .token.rule,
        *[data-theme=auto] .text code[data-lang][data-lang=javascript] .token.operator .language-javascript .token.operator,
        *[data-theme=auto] .text pre[data-lang][data-lang=javascript] .token.operator .language-javascript .token.operator {
            color: #a626a4
        }

        *[data-theme=auto] .text code[data-lang][data-lang=javascript] .token.template-string>.token.interpolation>.token.interpolation-punctuation.punctuation,
        *[data-theme=auto] .text pre[data-lang][data-lang=javascript] .token.template-string>.token.interpolation>.token.interpolation-punctuation.punctuation,
        *[data-theme=auto] .text code[data-lang] .language-javascript .token.template-string>.token.interpolation>.token.interpolation-punctuation.punctuation,
        *[data-theme=auto] .text pre[data-lang] .language-javascript .token.template-string>.token.interpolation>.token.interpolation-punctuation.punctuation {
            color: #ca1243
        }

        *[data-theme=auto] .text code[data-lang][data-lang=json] .token.operator,
        *[data-theme=auto] .text pre[data-lang][data-lang=json] .token.operator,
        *[data-theme=auto] .text code[data-lang] .language-json .token.operator,
        *[data-theme=auto] .text pre[data-lang] .language-json .token.operator {
            color: #383a42
        }

        *[data-theme=auto] .text code[data-lang][data-lang=json] .token.null.keyword,
        *[data-theme=auto] .text pre[data-lang][data-lang=json] .token.null.keyword,
        *[data-theme=auto] .text code[data-lang] .language-json .token.null.keyword,
        *[data-theme=auto] .text pre[data-lang] .language-json .token.null.keyword {
            color: #b76b01
        }

        *[data-theme=auto] .text code[data-lang][data-lang=markdown] .token.url,
        *[data-theme=auto] .text pre[data-lang][data-lang=markdown] .token.url,
        *[data-theme=auto] .text code[data-lang] .language-markdown .token.url,
        *[data-theme=auto] .text pre[data-lang] .language-markdown .token.url,
        *[data-theme=auto] .text code[data-lang][data-lang=markdown] .token.url>.token.operator,
        *[data-theme=auto] .text pre[data-lang][data-lang=markdown] .token.url>.token.operator,
        *[data-theme=auto] .text code[data-lang] .language-markdown .token.url>.token.operator,
        *[data-theme=auto] .text pre[data-lang] .language-markdown .token.url>.token.operator,
        *[data-theme=auto] .text code[data-lang][data-lang=markdown] .token.url-reference.url>.token.string,
        *[data-theme=auto] .text pre[data-lang][data-lang=markdown] .token.url-reference.url>.token.string,
        *[data-theme=auto] .text code[data-lang] .language-markdown .token.url-reference.url>.token.string,
        *[data-theme=auto] .text pre[data-lang] .language-markdown .token.url-reference.url>.token.string {
            color: #383a42
        }

        *[data-theme=auto] .text code[data-lang][data-lang=markdown] .token.url>.token.content,
        *[data-theme=auto] .text pre[data-lang][data-lang=markdown] .token.url>.token.content,
        *[data-theme=auto] .text code[data-lang] .language-markdown .token.url>.token.content,
        *[data-theme=auto] .text pre[data-lang] .language-markdown .token.url>.token.content {
            color: #4078f2
        }

        *[data-theme=auto] .text code[data-lang][data-lang=markdown] .token.url>.token.url,
        *[data-theme=auto] .text pre[data-lang][data-lang=markdown] .token.url>.token.url,
        *[data-theme=auto] .text code[data-lang] .language-markdown .token.url>.token.url,
        *[data-theme=auto] .text pre[data-lang] .language-markdown .token.url>.token.url,
        *[data-theme=auto] .text code[data-lang][data-lang=markdown] .token.url-reference.url,
        *[data-theme=auto] .text pre[data-lang][data-lang=markdown] .token.url-reference.url,
        *[data-theme=auto] .text code[data-lang] .language-markdown .token.url-reference.url,
        *[data-theme=auto] .text pre[data-lang] .language-markdown .token.url-reference.url {
            color: #0184bc
        }

        *[data-theme=auto] .text code[data-lang][data-lang=markdown] .token.blockquote.punctuation,
        *[data-theme=auto] .text pre[data-lang][data-lang=markdown] .token.blockquote.punctuation,
        *[data-theme=auto] .text code[data-lang] .language-markdown .token.blockquote.punctuation,
        *[data-theme=auto] .text pre[data-lang] .language-markdown .token.blockquote.punctuation,
        *[data-theme=auto] .text code[data-lang][data-lang=markdown] .token.hr.punctuation,
        *[data-theme=auto] .text pre[data-lang][data-lang=markdown] .token.hr.punctuation,
        *[data-theme=auto] .text code[data-lang] .language-markdown .token.hr.punctuation,
        *[data-theme=auto] .text pre[data-lang] .language-markdown .token.hr.punctuation {
            color: #a0a1a7;
            font-style: italic
        }

        *[data-theme=auto] .text code[data-lang][data-lang=markdown] .token.code-snippet,
        *[data-theme=auto] .text pre[data-lang][data-lang=markdown] .token.code-snippet,
        *[data-theme=auto] .text code[data-lang] .language-markdown .token.code-snippet,
        *[data-theme=auto] .text pre[data-lang] .language-markdown .token.code-snippet {
            color: #50a14f
        }

        *[data-theme=auto] .text code[data-lang][data-lang=markdown] .token.bold .token.content,
        *[data-theme=auto] .text pre[data-lang][data-lang=markdown] .token.bold .token.content,
        *[data-theme=auto] .text code[data-lang] .language-markdown .token.bold .token.content,
        *[data-theme=auto] .text pre[data-lang] .language-markdown .token.bold .token.content {
            color: #b76b01
        }

        *[data-theme=auto] .text code[data-lang][data-lang=markdown] .token.italic .token.content,
        *[data-theme=auto] .text pre[data-lang][data-lang=markdown] .token.italic .token.content,
        *[data-theme=auto] .text code[data-lang] .language-markdown .token.italic .token.content,
        *[data-theme=auto] .text pre[data-lang] .language-markdown .token.italic .token.content {
            color: #a626a4
        }

        *[data-theme=auto] .text code[data-lang][data-lang=markdown] .token.strike .token.content,
        *[data-theme=auto] .text pre[data-lang][data-lang=markdown] .token.strike .token.content,
        *[data-theme=auto] .text code[data-lang] .language-markdown .token.strike .token.content,
        *[data-theme=auto] .text pre[data-lang] .language-markdown .token.strike .token.content,
        *[data-theme=auto] .text code[data-lang][data-lang=markdown] .token.strike .token.punctuation,
        *[data-theme=auto] .text pre[data-lang][data-lang=markdown] .token.strike .token.punctuation,
        *[data-theme=auto] .text code[data-lang] .language-markdown .token.strike .token.punctuation,
        *[data-theme=auto] .text pre[data-lang] .language-markdown .token.strike .token.punctuation,
        *[data-theme=auto] .text code[data-lang][data-lang=markdown] .token.list.punctuation,
        *[data-theme=auto] .text pre[data-lang][data-lang=markdown] .token.list.punctuation,
        *[data-theme=auto] .text code[data-lang] .language-markdown .token.list.punctuation,
        *[data-theme=auto] .text pre[data-lang] .language-markdown .token.list.punctuation,
        *[data-theme=auto] .text code[data-lang][data-lang=markdown] .token.title.important>.token.punctuation,
        *[data-theme=auto] .text pre[data-lang][data-lang=markdown] .token.title.important>.token.punctuation,
        *[data-theme=auto] .text code[data-lang] .language-markdown .token.title.important>.token.punctuation,
        *[data-theme=auto] .text pre[data-lang] .language-markdown .token.title.important>.token.punctuation {
            color: #e45649
        }

        *[data-theme=auto] .text code[data-lang] .token.bold,
        *[data-theme=auto] .text pre[data-lang] .token.bold {
            font-weight: 700
        }

        *[data-theme=auto] .text code[data-lang] .token.comment,
        *[data-theme=auto] .text pre[data-lang] .token.comment,
        *[data-theme=auto] .text code[data-lang] .token.italic,
        *[data-theme=auto] .text pre[data-lang] .token.italic {
            font-style: italic
        }

        *[data-theme=auto] .text code[data-lang] .token.entity,
        *[data-theme=auto] .text pre[data-lang] .token.entity {
            cursor: help
        }

        *[data-theme=auto] .text code[data-lang] .token.namespace,
        *[data-theme=auto] .text pre[data-lang] .token.namespace {
            opacity: .8
        }

        *[data-theme=auto] .text code[data-lang] .token.token.tab:not(:empty):before,
        *[data-theme=auto] .text pre[data-lang] .token.token.tab:not(:empty):before,
        *[data-theme=auto] .text code[data-lang] .token.token.cr:before,
        *[data-theme=auto] .text pre[data-lang] .token.token.cr:before,
        *[data-theme=auto] .text code[data-lang] .token.token.lf:before,
        *[data-theme=auto] .text pre[data-lang] .token.token.lf:before,
        *[data-theme=auto] .text code[data-lang] .token.token.space:before,
        *[data-theme=auto] .text pre[data-lang] .token.token.space:before {
            color: #383a4233
        }
    }

    *[data-theme=dark] .text code[data-lang],
    *[data-theme=dark] .text pre[data-lang] {
        color: #abb2bf;
        text-shadow: 0 1px rgba(0, 0, 0, .3)
    }

    *[data-theme=dark] .text code[data-lang] .token.comment,
    *[data-theme=dark] .text pre[data-lang] .token.comment,
    *[data-theme=dark] .text code[data-lang] .token.prolog,
    *[data-theme=dark] .text pre[data-lang] .token.prolog,
    *[data-theme=dark] .text code[data-lang] .token.cdata,
    *[data-theme=dark] .text pre[data-lang] .token.cdata {
        color: #5c6370
    }

    *[data-theme=dark] .text code[data-lang] .token.doctype,
    *[data-theme=dark] .text pre[data-lang] .token.doctype,
    *[data-theme=dark] .text code[data-lang] .token.punctuation,
    *[data-theme=dark] .text pre[data-lang] .token.punctuation,
    *[data-theme=dark] .text code[data-lang] .token.entity,
    *[data-theme=dark] .text pre[data-lang] .token.entity {
        color: #abb2bf
    }

    *[data-theme=dark] .text code[data-lang] .token.attr-name,
    *[data-theme=dark] .text pre[data-lang] .token.attr-name,
    *[data-theme=dark] .text code[data-lang] .token.class-name,
    *[data-theme=dark] .text pre[data-lang] .token.class-name,
    *[data-theme=dark] .text code[data-lang] .token.boolean,
    *[data-theme=dark] .text pre[data-lang] .token.boolean,
    *[data-theme=dark] .text code[data-lang] .token.constant,
    *[data-theme=dark] .text pre[data-lang] .token.constant,
    *[data-theme=dark] .text code[data-lang] .token.number,
    *[data-theme=dark] .text pre[data-lang] .token.number,
    *[data-theme=dark] .text code[data-lang] .token.atrule,
    *[data-theme=dark] .text pre[data-lang] .token.atrule {
        color: #d19a66
    }

    *[data-theme=dark] .text code[data-lang] .token.keyword,
    *[data-theme=dark] .text pre[data-lang] .token.keyword {
        color: #c678dd
    }

    *[data-theme=dark] .text code[data-lang] .token.property,
    *[data-theme=dark] .text pre[data-lang] .token.property,
    *[data-theme=dark] .text code[data-lang] .token.tag,
    *[data-theme=dark] .text pre[data-lang] .token.tag,
    *[data-theme=dark] .text code[data-lang] .token.symbol,
    *[data-theme=dark] .text pre[data-lang] .token.symbol,
    *[data-theme=dark] .text code[data-lang] .token.deleted,
    *[data-theme=dark] .text pre[data-lang] .token.deleted,
    *[data-theme=dark] .text code[data-lang] .token.important,
    *[data-theme=dark] .text pre[data-lang] .token.important {
        color: #e06c75
    }

    *[data-theme=dark] .text code[data-lang] .token.selector,
    *[data-theme=dark] .text pre[data-lang] .token.selector,
    *[data-theme=dark] .text code[data-lang] .token.string,
    *[data-theme=dark] .text pre[data-lang] .token.string,
    *[data-theme=dark] .text code[data-lang] .token.char,
    *[data-theme=dark] .text pre[data-lang] .token.char,
    *[data-theme=dark] .text code[data-lang] .token.builtin,
    *[data-theme=dark] .text pre[data-lang] .token.builtin,
    *[data-theme=dark] .text code[data-lang] .token.inserted,
    *[data-theme=dark] .text pre[data-lang] .token.inserted,
    *[data-theme=dark] .text code[data-lang] .token.regex,
    *[data-theme=dark] .text pre[data-lang] .token.regex,
    *[data-theme=dark] .text code[data-lang] .token.attr-value,
    *[data-theme=dark] .text pre[data-lang] .token.attr-value,
    *[data-theme=dark] .text code[data-lang] .token.attr-value>.token.punctuation,
    *[data-theme=dark] .text pre[data-lang] .token.attr-value>.token.punctuation {
        color: #98c379
    }

    *[data-theme=dark] .text code[data-lang] .token.variable,
    *[data-theme=dark] .text pre[data-lang] .token.variable,
    *[data-theme=dark] .text code[data-lang] .token.operator,
    *[data-theme=dark] .text pre[data-lang] .token.operator,
    *[data-theme=dark] .text code[data-lang] .token.function,
    *[data-theme=dark] .text pre[data-lang] .token.function {
        color: #61afef
    }

    *[data-theme=dark] .text code[data-lang] .token.url,
    *[data-theme=dark] .text pre[data-lang] .token.url {
        color: #56b6c2
    }

    *[data-theme=dark] .text code[data-lang] .token.attr-value>.token.punctuation.attr-equals,
    *[data-theme=dark] .text pre[data-lang] .token.attr-value>.token.punctuation.attr-equals,
    *[data-theme=dark] .text code[data-lang] .token.special-attr>.token.attr-value>.token.value.css,
    *[data-theme=dark] .text pre[data-lang] .token.special-attr>.token.attr-value>.token.value.css {
        color: #abb2bf
    }

    *[data-theme=dark] .text code[data-lang][data-lang=css] .token.selector,
    *[data-theme=dark] .text pre[data-lang][data-lang=css] .token.selector,
    *[data-theme=dark] .text code[data-lang] .language-css .token.selector,
    *[data-theme=dark] .text pre[data-lang] .language-css .token.selector {
        color: #e06c75
    }

    *[data-theme=dark] .text code[data-lang][data-lang=css] .token.property,
    *[data-theme=dark] .text pre[data-lang][data-lang=css] .token.property,
    *[data-theme=dark] .text code[data-lang] .language-css .token.property,
    *[data-theme=dark] .text pre[data-lang] .language-css .token.property {
        color: #abb2bf
    }

    *[data-theme=dark] .text code[data-lang][data-lang=css] .token.function,
    *[data-theme=dark] .text pre[data-lang][data-lang=css] .token.function,
    *[data-theme=dark] .text code[data-lang] .language-css .token.function,
    *[data-theme=dark] .text pre[data-lang] .language-css .token.function,
    *[data-theme=dark] .text code[data-lang][data-lang=css] .token.url>.token.function,
    *[data-theme=dark] .text pre[data-lang][data-lang=css] .token.url>.token.function,
    *[data-theme=dark] .text code[data-lang] .language-css .token.url>.token.function,
    *[data-theme=dark] .text pre[data-lang] .language-css .token.url>.token.function {
        color: #56b6c2
    }

    *[data-theme=dark] .text code[data-lang][data-lang=css] .token.url>.token.string.url,
    *[data-theme=dark] .text pre[data-lang][data-lang=css] .token.url>.token.string.url,
    *[data-theme=dark] .text code[data-lang] .language-css .token.url>.token.string.url,
    *[data-theme=dark] .text pre[data-lang] .language-css .token.url>.token.string.url {
        color: #98c379
    }

    *[data-theme=dark] .text code[data-lang][data-lang=css] .token.important,
    *[data-theme=dark] .text pre[data-lang][data-lang=css] .token.important,
    *[data-theme=dark] .text code[data-lang] .language-css .token.important,
    *[data-theme=dark] .text pre[data-lang] .language-css .token.important,
    *[data-theme=dark] .text code[data-lang][data-lang=css] .token.atrule .token.rule,
    *[data-theme=dark] .text pre[data-lang][data-lang=css] .token.atrule .token.rule,
    *[data-theme=dark] .text code[data-lang] .language-css .token.atrule .token.rule,
    *[data-theme=dark] .text pre[data-lang] .language-css .token.atrule .token.rule,
    *[data-theme=dark] .text code[data-lang][data-lang=javascript] .token.operator .language-javascript .token.operator,
    *[data-theme=dark] .text pre[data-lang][data-lang=javascript] .token.operator .language-javascript .token.operator {
        color: #c678dd
    }

    *[data-theme=dark] .text code[data-lang][data-lang=javascript] .token.template-string>.token.interpolation>.token.interpolation-punctuation.punctuation,
    *[data-theme=dark] .text pre[data-lang][data-lang=javascript] .token.template-string>.token.interpolation>.token.interpolation-punctuation.punctuation,
    *[data-theme=dark] .text code[data-lang] .language-javascript .token.template-string>.token.interpolation>.token.interpolation-punctuation.punctuation,
    *[data-theme=dark] .text pre[data-lang] .language-javascript .token.template-string>.token.interpolation>.token.interpolation-punctuation.punctuation {
        color: #be5046
    }

    *[data-theme=dark] .text code[data-lang][data-lang=json] .token.operator,
    *[data-theme=dark] .text pre[data-lang][data-lang=json] .token.operator,
    *[data-theme=dark] .text code[data-lang] .language-json .token.operator,
    *[data-theme=dark] .text pre[data-lang] .language-json .token.operator {
        color: #abb2bf
    }

    *[data-theme=dark] .text code[data-lang][data-lang=json] .token.null.keyword,
    *[data-theme=dark] .text pre[data-lang][data-lang=json] .token.null.keyword,
    *[data-theme=dark] .text code[data-lang] .language-json .token.null.keyword,
    *[data-theme=dark] .text pre[data-lang] .language-json .token.null.keyword {
        color: #d19a66
    }

    *[data-theme=dark] .text code[data-lang][data-lang=markdown] .token.url,
    *[data-theme=dark] .text pre[data-lang][data-lang=markdown] .token.url,
    *[data-theme=dark] .text code[data-lang] .language-markdown .token.url,
    *[data-theme=dark] .text pre[data-lang] .language-markdown .token.url,
    *[data-theme=dark] .text code[data-lang][data-lang=markdown] .token.url>.token.operator,
    *[data-theme=dark] .text pre[data-lang][data-lang=markdown] .token.url>.token.operator,
    *[data-theme=dark] .text code[data-lang] .language-markdown .token.url>.token.operator,
    *[data-theme=dark] .text pre[data-lang] .language-markdown .token.url>.token.operator,
    *[data-theme=dark] .text code[data-lang][data-lang=markdown] .token.url-reference.url>.token.string,
    *[data-theme=dark] .text pre[data-lang][data-lang=markdown] .token.url-reference.url>.token.string,
    *[data-theme=dark] .text code[data-lang] .language-markdown .token.url-reference.url>.token.string,
    *[data-theme=dark] .text pre[data-lang] .language-markdown .token.url-reference.url>.token.string {
        color: #abb2bf
    }

    *[data-theme=dark] .text code[data-lang][data-lang=markdown] .token.url>.token.content,
    *[data-theme=dark] .text pre[data-lang][data-lang=markdown] .token.url>.token.content,
    *[data-theme=dark] .text code[data-lang] .language-markdown .token.url>.token.content,
    *[data-theme=dark] .text pre[data-lang] .language-markdown .token.url>.token.content {
        color: #61afef
    }

    *[data-theme=dark] .text code[data-lang][data-lang=markdown] .token.url>.token.url,
    *[data-theme=dark] .text pre[data-lang][data-lang=markdown] .token.url>.token.url,
    *[data-theme=dark] .text code[data-lang] .language-markdown .token.url>.token.url,
    *[data-theme=dark] .text pre[data-lang] .language-markdown .token.url>.token.url,
    *[data-theme=dark] .text code[data-lang][data-lang=markdown] .token.url-reference.url,
    *[data-theme=dark] .text pre[data-lang][data-lang=markdown] .token.url-reference.url,
    *[data-theme=dark] .text code[data-lang] .language-markdown .token.url-reference.url,
    *[data-theme=dark] .text pre[data-lang] .language-markdown .token.url-reference.url {
        color: #56b6c2
    }

    *[data-theme=dark] .text code[data-lang][data-lang=markdown] .token.blockquote.punctuation,
    *[data-theme=dark] .text pre[data-lang][data-lang=markdown] .token.blockquote.punctuation,
    *[data-theme=dark] .text code[data-lang] .language-markdown .token.blockquote.punctuation,
    *[data-theme=dark] .text pre[data-lang] .language-markdown .token.blockquote.punctuation,
    *[data-theme=dark] .text code[data-lang][data-lang=markdown] .token.hr.punctuation,
    *[data-theme=dark] .text pre[data-lang][data-lang=markdown] .token.hr.punctuation,
    *[data-theme=dark] .text code[data-lang] .language-markdown .token.hr.punctuation,
    *[data-theme=dark] .text pre[data-lang] .language-markdown .token.hr.punctuation {
        color: #5c6370;
        font-style: italic
    }

    *[data-theme=dark] .text code[data-lang][data-lang=markdown] .token.code-snippet,
    *[data-theme=dark] .text pre[data-lang][data-lang=markdown] .token.code-snippet,
    *[data-theme=dark] .text code[data-lang] .language-markdown .token.code-snippet,
    *[data-theme=dark] .text pre[data-lang] .language-markdown .token.code-snippet {
        color: #98c379
    }

    *[data-theme=dark] .text code[data-lang][data-lang=markdown] .token.bold .token.content,
    *[data-theme=dark] .text pre[data-lang][data-lang=markdown] .token.bold .token.content,
    *[data-theme=dark] .text code[data-lang] .language-markdown .token.bold .token.content,
    *[data-theme=dark] .text pre[data-lang] .language-markdown .token.bold .token.content {
        color: #d19a66
    }

    *[data-theme=dark] .text code[data-lang][data-lang=markdown] .token.italic .token.content,
    *[data-theme=dark] .text pre[data-lang][data-lang=markdown] .token.italic .token.content,
    *[data-theme=dark] .text code[data-lang] .language-markdown .token.italic .token.content,
    *[data-theme=dark] .text pre[data-lang] .language-markdown .token.italic .token.content {
        color: #c678dd
    }

    *[data-theme=dark] .text code[data-lang][data-lang=markdown] .token.strike .token.content,
    *[data-theme=dark] .text pre[data-lang][data-lang=markdown] .token.strike .token.content,
    *[data-theme=dark] .text code[data-lang] .language-markdown .token.strike .token.content,
    *[data-theme=dark] .text pre[data-lang] .language-markdown .token.strike .token.content,
    *[data-theme=dark] .text code[data-lang][data-lang=markdown] .token.strike .token.punctuation,
    *[data-theme=dark] .text pre[data-lang][data-lang=markdown] .token.strike .token.punctuation,
    *[data-theme=dark] .text code[data-lang] .language-markdown .token.strike .token.punctuation,
    *[data-theme=dark] .text pre[data-lang] .language-markdown .token.strike .token.punctuation,
    *[data-theme=dark] .text code[data-lang][data-lang=markdown] .token.list.punctuation,
    *[data-theme=dark] .text pre[data-lang][data-lang=markdown] .token.list.punctuation,
    *[data-theme=dark] .text code[data-lang] .language-markdown .token.list.punctuation,
    *[data-theme=dark] .text pre[data-lang] .language-markdown .token.list.punctuation,
    *[data-theme=dark] .text code[data-lang][data-lang=markdown] .token.title.important>.token.punctuation,
    *[data-theme=dark] .text pre[data-lang][data-lang=markdown] .token.title.important>.token.punctuation,
    *[data-theme=dark] .text code[data-lang] .language-markdown .token.title.important>.token.punctuation,
    *[data-theme=dark] .text pre[data-lang] .language-markdown .token.title.important>.token.punctuation {
        color: #e06c75
    }

    *[data-theme=dark] .text code[data-lang] .token.bold,
    *[data-theme=dark] .text pre[data-lang] .token.bold {
        font-weight: 700
    }

    *[data-theme=dark] .text code[data-lang] .token.comment,
    *[data-theme=dark] .text pre[data-lang] .token.comment,
    *[data-theme=dark] .text code[data-lang] .token.italic,
    *[data-theme=dark] .text pre[data-lang] .token.italic {
        font-style: italic
    }

    *[data-theme=dark] .text code[data-lang] .token.entity,
    *[data-theme=dark] .text pre[data-lang] .token.entity {
        cursor: help
    }

    *[data-theme=dark] .text code[data-lang] .token.namespace,
    *[data-theme=dark] .text pre[data-lang] .token.namespace {
        opacity: .8
    }

    *[data-theme=dark] .text code[data-lang] .token.token.tab:not(:empty):before,
    *[data-theme=dark] .text pre[data-lang] .token.token.tab:not(:empty):before,
    *[data-theme=dark] .text code[data-lang] .token.token.cr:before,
    *[data-theme=dark] .text pre[data-lang] .token.token.cr:before,
    *[data-theme=dark] .text code[data-lang] .token.token.lf:before,
    *[data-theme=dark] .text pre[data-lang] .token.token.lf:before,
    *[data-theme=dark] .text code[data-lang] .token.token.space:before,
    *[data-theme=dark] .text pre[data-lang] .token.token.space:before {
        color: #abb2bf26;
        text-shadow: none
    }


    @media (prefers-color-scheme: dark) {

        *[data-theme=auto] .text code[data-lang],
        *[data-theme=auto] .text pre[data-lang] {
            color: #abb2bf;
            text-shadow: 0 1px rgba(0, 0, 0, .3)
        }

        *[data-theme=auto] .text code[data-lang] .token.comment,
        *[data-theme=auto] .text pre[data-lang] .token.comment,
        *[data-theme=auto] .text code[data-lang] .token.prolog,
        *[data-theme=auto] .text pre[data-lang] .token.prolog,
        *[data-theme=auto] .text code[data-lang] .token.cdata,
        *[data-theme=auto] .text pre[data-lang] .token.cdata {
            color: #5c6370
        }

        *[data-theme=auto] .text code[data-lang] .token.doctype,
        *[data-theme=auto] .text pre[data-lang] .token.doctype,
        *[data-theme=auto] .text code[data-lang] .token.punctuation,
        *[data-theme=auto] .text pre[data-lang] .token.punctuation,
        *[data-theme=auto] .text code[data-lang] .token.entity,
        *[data-theme=auto] .text pre[data-lang] .token.entity {
            color: #abb2bf
        }

        *[data-theme=auto] .text code[data-lang] .token.attr-name,
        *[data-theme=auto] .text pre[data-lang] .token.attr-name,
        *[data-theme=auto] .text code[data-lang] .token.class-name,
        *[data-theme=auto] .text pre[data-lang] .token.class-name,
        *[data-theme=auto] .text code[data-lang] .token.boolean,
        *[data-theme=auto] .text pre[data-lang] .token.boolean,
        *[data-theme=auto] .text code[data-lang] .token.constant,
        *[data-theme=auto] .text pre[data-lang] .token.constant,
        *[data-theme=auto] .text code[data-lang] .token.number,
        *[data-theme=auto] .text pre[data-lang] .token.number,
        *[data-theme=auto] .text code[data-lang] .token.atrule,
        *[data-theme=auto] .text pre[data-lang] .token.atrule {
            color: #d19a66
        }

        *[data-theme=auto] .text code[data-lang] .token.keyword,
        *[data-theme=auto] .text pre[data-lang] .token.keyword {
            color: #c678dd
        }

        *[data-theme=auto] .text code[data-lang] .token.property,
        *[data-theme=auto] .text pre[data-lang] .token.property,
        *[data-theme=auto] .text code[data-lang] .token.tag,
        *[data-theme=auto] .text pre[data-lang] .token.tag,
        *[data-theme=auto] .text code[data-lang] .token.symbol,
        *[data-theme=auto] .text pre[data-lang] .token.symbol,
        *[data-theme=auto] .text code[data-lang] .token.deleted,
        *[data-theme=auto] .text pre[data-lang] .token.deleted,
        *[data-theme=auto] .text code[data-lang] .token.important,
        *[data-theme=auto] .text pre[data-lang] .token.important {
            color: #e06c75
        }

        *[data-theme=auto] .text code[data-lang] .token.selector,
        *[data-theme=auto] .text pre[data-lang] .token.selector,
        *[data-theme=auto] .text code[data-lang] .token.string,
        *[data-theme=auto] .text pre[data-lang] .token.string,
        *[data-theme=auto] .text code[data-lang] .token.char,
        *[data-theme=auto] .text pre[data-lang] .token.char,
        *[data-theme=auto] .text code[data-lang] .token.builtin,
        *[data-theme=auto] .text pre[data-lang] .token.builtin,
        *[data-theme=auto] .text code[data-lang] .token.inserted,
        *[data-theme=auto] .text pre[data-lang] .token.inserted,
        *[data-theme=auto] .text code[data-lang] .token.regex,
        *[data-theme=auto] .text pre[data-lang] .token.regex,
        *[data-theme=auto] .text code[data-lang] .token.attr-value,
        *[data-theme=auto] .text pre[data-lang] .token.attr-value,
        *[data-theme=auto] .text code[data-lang] .token.attr-value>.token.punctuation,
        *[data-theme=auto] .text pre[data-lang] .token.attr-value>.token.punctuation {
            color: #98c379
        }

        *[data-theme=auto] .text code[data-lang] .token.variable,
        *[data-theme=auto] .text pre[data-lang] .token.variable,
        *[data-theme=auto] .text code[data-lang] .token.operator,
        *[data-theme=auto] .text pre[data-lang] .token.operator,
        *[data-theme=auto] .text code[data-lang] .token.function,
        *[data-theme=auto] .text pre[data-lang] .token.function {
            color: #61afef
        }

        *[data-theme=auto] .text code[data-lang] .token.url,
        *[data-theme=auto] .text pre[data-lang] .token.url {
            color: #56b6c2
        }

        *[data-theme=auto] .text code[data-lang] .token.attr-value>.token.punctuation.attr-equals,
        *[data-theme=auto] .text pre[data-lang] .token.attr-value>.token.punctuation.attr-equals,
        *[data-theme=auto] .text code[data-lang] .token.special-attr>.token.attr-value>.token.value.css,
        *[data-theme=auto] .text pre[data-lang] .token.special-attr>.token.attr-value>.token.value.css {
            color: #abb2bf
        }

        *[data-theme=auto] .text code[data-lang][data-lang=css] .token.selector,
        *[data-theme=auto] .text pre[data-lang][data-lang=css] .token.selector,
        *[data-theme=auto] .text code[data-lang] .language-css .token.selector,
        *[data-theme=auto] .text pre[data-lang] .language-css .token.selector {
            color: #e06c75
        }

        *[data-theme=auto] .text code[data-lang][data-lang=css] .token.property,
        *[data-theme=auto] .text pre[data-lang][data-lang=css] .token.property,
        *[data-theme=auto] .text code[data-lang] .language-css .token.property,
        *[data-theme=auto] .text pre[data-lang] .language-css .token.property {
            color: #abb2bf
        }

        *[data-theme=auto] .text code[data-lang][data-lang=css] .token.function,
        *[data-theme=auto] .text pre[data-lang][data-lang=css] .token.function,
        *[data-theme=auto] .text code[data-lang] .language-css .token.function,
        *[data-theme=auto] .text pre[data-lang] .language-css .token.function,
        *[data-theme=auto] .text code[data-lang][data-lang=css] .token.url>.token.function,
        *[data-theme=auto] .text pre[data-lang][data-lang=css] .token.url>.token.function,
        *[data-theme=auto] .text code[data-lang] .language-css .token.url>.token.function,
        *[data-theme=auto] .text pre[data-lang] .language-css .token.url>.token.function {
            color: #56b6c2
        }

        *[data-theme=auto] .text code[data-lang][data-lang=css] .token.url>.token.string.url,
        *[data-theme=auto] .text pre[data-lang][data-lang=css] .token.url>.token.string.url,
        *[data-theme=auto] .text code[data-lang] .language-css .token.url>.token.string.url,
        *[data-theme=auto] .text pre[data-lang] .language-css .token.url>.token.string.url {
            color: #98c379
        }

        *[data-theme=auto] .text code[data-lang][data-lang=css] .token.important,
        *[data-theme=auto] .text pre[data-lang][data-lang=css] .token.important,
        *[data-theme=auto] .text code[data-lang] .language-css .token.important,
        *[data-theme=auto] .text pre[data-lang] .language-css .token.important,
        *[data-theme=auto] .text code[data-lang][data-lang=css] .token.atrule .token.rule,
        *[data-theme=auto] .text pre[data-lang][data-lang=css] .token.atrule .token.rule,
        *[data-theme=auto] .text code[data-lang] .language-css .token.atrule .token.rule,
        *[data-theme=auto] .text pre[data-lang] .language-css .token.atrule .token.rule,
        *[data-theme=auto] .text code[data-lang][data-lang=javascript] .token.operator .language-javascript .token.operator,
        *[data-theme=auto] .text pre[data-lang][data-lang=javascript] .token.operator .language-javascript .token.operator {
            color: #c678dd
        }

        *[data-theme=auto] .text code[data-lang][data-lang=javascript] .token.template-string>.token.interpolation>.token.interpolation-punctuation.punctuation,
        *[data-theme=auto] .text pre[data-lang][data-lang=javascript] .token.template-string>.token.interpolation>.token.interpolation-punctuation.punctuation,
        *[data-theme=auto] .text code[data-lang] .language-javascript .token.template-string>.token.interpolation>.token.interpolation-punctuation.punctuation,
        *[data-theme=auto] .text pre[data-lang] .language-javascript .token.template-string>.token.interpolation>.token.interpolation-punctuation.punctuation {
            color: #be5046
        }

        *[data-theme=auto] .text code[data-lang][data-lang=json] .token.operator,
        *[data-theme=auto] .text pre[data-lang][data-lang=json] .token.operator,
        *[data-theme=auto] .text code[data-lang] .language-json .token.operator,
        *[data-theme=auto] .text pre[data-lang] .language-json .token.operator {
            color: #abb2bf
        }

        *[data-theme=auto] .text code[data-lang][data-lang=json] .token.null.keyword,
        *[data-theme=auto] .text pre[data-lang][data-lang=json] .token.null.keyword,
        *[data-theme=auto] .text code[data-lang] .language-json .token.null.keyword,
        *[data-theme=auto] .text pre[data-lang] .language-json .token.null.keyword {
            color: #d19a66
        }

        *[data-theme=auto] .text code[data-lang][data-lang=markdown] .token.url,
        *[data-theme=auto] .text pre[data-lang][data-lang=markdown] .token.url,
        *[data-theme=auto] .text code[data-lang] .language-markdown .token.url,
        *[data-theme=auto] .text pre[data-lang] .language-markdown .token.url,
        *[data-theme=auto] .text code[data-lang][data-lang=markdown] .token.url>.token.operator,
        *[data-theme=auto] .text pre[data-lang][data-lang=markdown] .token.url>.token.operator,
        *[data-theme=auto] .text code[data-lang] .language-markdown .token.url>.token.operator,
        *[data-theme=auto] .text pre[data-lang] .language-markdown .token.url>.token.operator,
        *[data-theme=auto] .text code[data-lang][data-lang=markdown] .token.url-reference.url>.token.string,
        *[data-theme=auto] .text pre[data-lang][data-lang=markdown] .token.url-reference.url>.token.string,
        *[data-theme=auto] .text code[data-lang] .language-markdown .token.url-reference.url>.token.string,
        *[data-theme=auto] .text pre[data-lang] .language-markdown .token.url-reference.url>.token.string {
            color: #abb2bf
        }

        *[data-theme=auto] .text code[data-lang][data-lang=markdown] .token.url>.token.content,
        *[data-theme=auto] .text pre[data-lang][data-lang=markdown] .token.url>.token.content,
        *[data-theme=auto] .text code[data-lang] .language-markdown .token.url>.token.content,
        *[data-theme=auto] .text pre[data-lang] .language-markdown .token.url>.token.content {
            color: #61afef
        }

        *[data-theme=auto] .text code[data-lang][data-lang=markdown] .token.url>.token.url,
        *[data-theme=auto] .text pre[data-lang][data-lang=markdown] .token.url>.token.url,
        *[data-theme=auto] .text code[data-lang] .language-markdown .token.url>.token.url,
        *[data-theme=auto] .text pre[data-lang] .language-markdown .token.url>.token.url,
        *[data-theme=auto] .text code[data-lang][data-lang=markdown] .token.url-reference.url,
        *[data-theme=auto] .text pre[data-lang][data-lang=markdown] .token.url-reference.url,
        *[data-theme=auto] .text code[data-lang] .language-markdown .token.url-reference.url,
        *[data-theme=auto] .text pre[data-lang] .language-markdown .token.url-reference.url {
            color: #56b6c2
        }

        *[data-theme=auto] .text code[data-lang][data-lang=markdown] .token.blockquote.punctuation,
        *[data-theme=auto] .text pre[data-lang][data-lang=markdown] .token.blockquote.punctuation,
        *[data-theme=auto] .text code[data-lang] .language-markdown .token.blockquote.punctuation,
        *[data-theme=auto] .text pre[data-lang] .language-markdown .token.blockquote.punctuation,
        *[data-theme=auto] .text code[data-lang][data-lang=markdown] .token.hr.punctuation,
        *[data-theme=auto] .text pre[data-lang][data-lang=markdown] .token.hr.punctuation,
        *[data-theme=auto] .text code[data-lang] .language-markdown .token.hr.punctuation,
        *[data-theme=auto] .text pre[data-lang] .language-markdown .token.hr.punctuation {
            color: #5c6370;
            font-style: italic
        }

        *[data-theme=auto] .text code[data-lang][data-lang=markdown] .token.code-snippet,
        *[data-theme=auto] .text pre[data-lang][data-lang=markdown] .token.code-snippet,
        *[data-theme=auto] .text code[data-lang] .language-markdown .token.code-snippet,
        *[data-theme=auto] .text pre[data-lang] .language-markdown .token.code-snippet {
            color: #98c379
        }

        *[data-theme=auto] .text code[data-lang][data-lang=markdown] .token.bold .token.content,
        *[data-theme=auto] .text pre[data-lang][data-lang=markdown] .token.bold .token.content,
        *[data-theme=auto] .text code[data-lang] .language-markdown .token.bold .token.content,
        *[data-theme=auto] .text pre[data-lang] .language-markdown .token.bold .token.content {
            color: #d19a66
        }

        *[data-theme=auto] .text code[data-lang][data-lang=markdown] .token.italic .token.content,
        *[data-theme=auto] .text pre[data-lang][data-lang=markdown] .token.italic .token.content,
        *[data-theme=auto] .text code[data-lang] .language-markdown .token.italic .token.content,
        *[data-theme=auto] .text pre[data-lang] .language-markdown .token.italic .token.content {
            color: #c678dd
        }

        *[data-theme=auto] .text code[data-lang][data-lang=markdown] .token.strike .token.content,
        *[data-theme=auto] .text pre[data-lang][data-lang=markdown] .token.strike .token.content,
        *[data-theme=auto] .text code[data-lang] .language-markdown .token.strike .token.content,
        *[data-theme=auto] .text pre[data-lang] .language-markdown .token.strike .token.content,
        *[data-theme=auto] .text code[data-lang][data-lang=markdown] .token.strike .token.punctuation,
        *[data-theme=auto] .text pre[data-lang][data-lang=markdown] .token.strike .token.punctuation,
        *[data-theme=auto] .text code[data-lang] .language-markdown .token.strike .token.punctuation,
        *[data-theme=auto] .text pre[data-lang] .language-markdown .token.strike .token.punctuation,
        *[data-theme=auto] .text code[data-lang][data-lang=markdown] .token.list.punctuation,
        *[data-theme=auto] .text pre[data-lang][data-lang=markdown] .token.list.punctuation,
        *[data-theme=auto] .text code[data-lang] .language-markdown .token.list.punctuation,
        *[data-theme=auto] .text pre[data-lang] .language-markdown .token.list.punctuation,
        *[data-theme=auto] .text code[data-lang][data-lang=markdown] .token.title.important>.token.punctuation,
        *[data-theme=auto] .text pre[data-lang][data-lang=markdown] .token.title.important>.token.punctuation,
        *[data-theme=auto] .text code[data-lang] .language-markdown .token.title.important>.token.punctuation,
        *[data-theme=auto] .text pre[data-lang] .language-markdown .token.title.important>.token.punctuation {
            color: #e06c75
        }

        *[data-theme=auto] .text code[data-lang] .token.bold,
        *[data-theme=auto] .text pre[data-lang] .token.bold {
            font-weight: 700
        }

        *[data-theme=auto] .text code[data-lang] .token.comment,
        *[data-theme=auto] .text pre[data-lang] .token.comment,
        *[data-theme=auto] .text code[data-lang] .token.italic,
        *[data-theme=auto] .text pre[data-lang] .token.italic {
            font-style: italic
        }

        *[data-theme=auto] .text code[data-lang] .token.entity,
        *[data-theme=auto] .text pre[data-lang] .token.entity {
            cursor: help
        }

        *[data-theme=auto] .text code[data-lang] .token.namespace,
        *[data-theme=auto] .text pre[data-lang] .token.namespace {
            opacity: .8
        }

        *[data-theme=auto] .text code[data-lang] .token.token.tab:not(:empty):before,
        *[data-theme=auto] .text pre[data-lang] .token.token.tab:not(:empty):before,
        *[data-theme=auto] .text code[data-lang] .token.token.cr:before,
        *[data-theme=auto] .text pre[data-lang] .token.token.cr:before,
        *[data-theme=auto] .text code[data-lang] .token.token.lf:before,
        *[data-theme=auto] .text pre[data-lang] .token.token.lf:before,
        *[data-theme=auto] .text code[data-lang] .token.token.space:before,
        *[data-theme=auto] .text pre[data-lang] .token.token.space:before {
            color: #abb2bf26;
            text-shadow: none
        }
    }

    @media (prefers-color-scheme: dark) {

        *[data-theme=auto] .text code[data-lang],
        *[data-theme=auto] .text pre[data-lang] {
            text-shadow: none
        }
    }

    .text .tt-tags {
        min-width: 0;
        max-width: var(--document-column-size, auto);
        margin: 0 auto .88235294em;
        min-height: 1.5em;
        font-family: Inter, Helvetica Neue, Helvetica, sans-serif;
        font-stretch: normal;
        font-weight: 400;
        font-style: normal;
        font-size: 1em;
        line-height: 1.5em;
        color: var(--color-primary, #1A1919);
        white-space: pre-wrap;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: flex-start
    }

    .text .tt-tags:first-child {
        margin-top: 0
    }

    .text .tt-tags:last-child {
        margin-bottom: 0
    }

    .text .tt-tags[data-align=right] {
        text-align: right
    }

    .text .tt-tags[data-align=center] {
        text-align: center
    }

    .text .tt-tags[data-align=center] {
        justify-content: center
    }

    .text .tt-tags[data-align=right] {
        justify-content: flex-end
    }

    .text a,
    .text .link {
        border: 0;
        color: var(--color-accent-action, #5B41F5);
        text-decoration: underline;
        text-decoration-color: var(--color-accent-action-underline, rgba(91, 65, 245, .3));
        text-decoration-skip: ink;
        text-decoration-thickness: 1px;
        text-underline-offset: .15em;
        transition: all .4s;
        cursor: pointer
    }

    .text a:hover,
    .text .link:hover {
        color: var(--color-accent-action, #5B41F5);
        text-decoration-color: var(--color-accent-action, #5B41F5)
    }

    .text b,
    .text strong,
    .text .bold {
        font-weight: 700
    }

    .text pre b,
    .text pre strong,
    .text code b,
    .text code strong {
        font-family: FiraCode, SFMono-Regular, Consolas, Liberation Mono, Menlo, Monaco, Courier, monospace;
        font-stretch: normal;
        font-weight: 700;
        font-style: normal;
        letter-spacing: normal;
        font-feature-settings: "ss01"off, "ss02"off, "ss03"off, "ss04"on, "ss05"off, "ss06"on, "ss07"off, "zero"off, "onum"off
    }

    .text i,
    .text .italic {
        font-style: italic
    }

    .text u,
    .text .underline {
        text-decoration: underline
    }

    .text s,
    .text .strike {
        text-decoration: line-through
    }

    .text u s,
    .text s u,
    .text .underline.strike {
        text-decoration: underline line-through
    }

    .text code,
    .text .code {
        display: inline;
        border-radius: .3em;
        background-color: var(--color-bg-2, #F6F6F6);
        font-family: FiraCode, SFMono-Regular, Consolas, Liberation Mono, Menlo, Monaco, Courier, monospace;
        font-stretch: normal;
        font-weight: 400;
        font-style: normal;
        letter-spacing: normal;
        font-feature-settings: "ss01"off, "ss02"off, "ss03"off, "ss04"on, "ss05"off, "ss06"on, "ss07"off, "zero"off, "onum"off;
        font-size: .87em;
        color: var(--color-primary, #1A1919)
    }

    .text code:before,
    .text .code:before,
    .text code:after,
    .text .code:after {
        content: "\a0";
        letter-spacing: -.3em
    }

    .text .tt-tag {
        display: inline-block;
        margin-right: 10px;
        border: 0;
        color: var(--color-accent-action, #5B41F5);
        text-decoration: none;
        cursor: pointer;
        white-space: nowrap
    }

    .grecaptcha-badge {
        top: -9999px !important;
        left: -9999px !important;
        opacity: .01 !important;
        pointer-events: none !important
    }

    .layout {
        display: flex;
        flex-direction: column;
        width: 100%;
        min-height: 100vh
    }

    .layout__content {
        display: flex;
        flex-direction: column;
        flex: 0 0 auto;
        width: 100%;
        vertical-align: top
    }

    .layout__content.m_main {
        flex: 1 1 auto
    }

    .layout__content.m_scroll {
        overflow: auto
    }

    .layout__content.m_central {
        align-items: center;
        justify-content: center;
        text-align: center;
        vertical-align: middle
    }

    #text.inline .us u s,
    #text.inline .us s u,
    #text.inline .us .underline.strike {
        text-decoration: underline line-through
    }

    .editor {
        position: relative;
        min-height: 1.44444444em;
        tab-size: 4;
        -moz-tab-size: 4;
        line-height: 1.5em;
        word-wrap: break-word;
        cursor: text
    }

    .editor[contenteditable],
    .editor [contenteditable] {
        outline: none
    }

    .editor a,
    .editor .link {
        border: 0;
        color: var(--color-accent-action, #5B41F5);
        text-decoration: underline;
        text-decoration-color: var(--color-accent-action-underline, rgba(91, 65, 245, .3));
        text-decoration-skip: ink;
        text-decoration-thickness: 1px;
        text-underline-offset: .15em;
        transition: all .4s;
        cursor: pointer
    }

    .editor a:hover,
    .editor .link:hover {
        color: var(--color-accent-action, #5B41F5);
        text-decoration-color: var(--color-accent-action, #5B41F5)
    }

    .editor b,
    .editor strong,
    .editor .bold {
        font-weight: 700
    }

    .editor pre b,
    .editor pre strong,
    .editor code b,
    .editor code strong {
        font-family: FiraCode, SFMono-Regular, Consolas, Liberation Mono, Menlo, Monaco, Courier, monospace;
        font-stretch: normal;
        font-weight: 700;
        font-style: normal;
        letter-spacing: normal;
        font-feature-settings: "ss01"off, "ss02"off, "ss03"off, "ss04"on, "ss05"off, "ss06"on, "ss07"off, "zero"off, "onum"off
    }

    .editor i,
    .editor .italic {
        font-style: italic
    }

    .editor u,
    .editor .underline {
        text-decoration: underline
    }

    .editor s,
    .editor .strike {
        text-decoration: line-through
    }

    .editor u s,
    .editor s u,
    .editor .underline.strike {
        text-decoration: underline line-through
    }

    .editor code,
    .editor .code {
        display: inline;
        border-radius: .3em;
        background-color: var(--color-bg-2, #F6F6F6);
        font-family: FiraCode, SFMono-Regular, Consolas, Liberation Mono, Menlo, Monaco, Courier, monospace;
        font-stretch: normal;
        font-weight: 400;
        font-style: normal;
        letter-spacing: normal;
        font-feature-settings: "ss01"off, "ss02"off, "ss03"off, "ss04"on, "ss05"off, "ss06"on, "ss07"off, "zero"off, "onum"off;
        font-size: .87em;
        color: var(--color-primary, #1A1919)
    }

    .editor code:before,
    .editor .code:before,
    .editor code:after,
    .editor .code:after {
        content: "\a0";
        letter-spacing: -.3em
    }

    .editor .tt-tag {
        display: inline-block;
        margin-right: 10px;
        border: 0;
        color: var(--color-accent-action, #5B41F5);
        text-decoration: none;
        cursor: pointer;
        white-space: nowrap
    }

    .editor blockquote:empty:before,
    .editor li:empty:before {
        content: " ";
        display: inline
    }

    .editor figure>div {
        margin: 0
    }

    .editor .w-figure_wrap {
        position: relative;
        display: inline-block;
        max-width: 100%;
        font-size: 0;
        -webkit-user-select: none;
        user-select: none;
        transition: opacity .2s;
        vertical-align: top
    }

    .editor .w-figure_preventer {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: 0;
        background-color: transparent;
        -webkit-user-select: none;
        user-select: none
    }

    .editor .w-figure_image,
    .editor .w-figure_video {
        transition: opacity .4s, filter .4s;
        -webkit-user-select: none;
        user-select: none
    }

    .editor .w-figure_frame {
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        margin: 0 auto;
        border: 0;
        background-color: transparent;
        -webkit-user-select: none;
        user-select: none
    }

    .editor .w-figure_frame_spacer {
        display: block;
        min-width: 100px;
        max-width: 100%;
        min-height: 15px;
        box-sizing: border-box
    }

    .editor .w-figure_widget {
        display: block;
        margin: 0 auto;
        border: 0;
        background-color: transparent;
        -webkit-user-select: none;
        user-select: none
    }

    .editor .w-figure_widget.m_container:after {
        content: "";
        display: block;
        width: 550px;
        height: 1px;
        margin-top: -1px;
        pointer-events: none
    }

    .editor .w-figure_widget.m_container:empty:after {
        max-width: 100%;
        height: 150px;
        border-radius: 5px;
        background: var(--color-bg-2, #F6F6F6)
    }

    .editor .w-figure_resize {
        position: absolute;
        background: var(--color-accent-action, #5B41F5);
        opacity: 0;
        transition: opacity .2s;
        pointer-events: none;
        -webkit-user-select: none;
        user-select: none
    }

    .editor .w-figure_resize__x {
        top: 0;
        right: -5px;
        width: 2px;
        height: 100%;
        margin: 0;
        padding: 0 5px;
        background-clip: content-box;
        cursor: e-resize
    }

    .editor .w-figure_resize__y {
        bottom: -5px;
        left: 0;
        width: 100%;
        height: 2px;
        margin: 0;
        padding: 5px 0;
        background-clip: content-box;
        cursor: s-resize
    }

    .editor .w-figure_resize__xy {
        width: 10px;
        height: 10px;
        border: 2px solid var(--color-accent-action, #5B41F5);
        background: var(--color-bg-1, #FFFFFF);
        border-radius: 50%;
        bottom: -5px;
        right: -5px;
        margin: 0;
        cursor: nw-resize
    }

    .editor .w-figure_cursor {
        position: absolute;
        font-size: 1px;
        left: 50%;
        bottom: 10%;
        z-index: -1
    }

    .editor .w-figure_caption {
        cursor: text;
        -webkit-user-select: auto;
        user-select: auto
    }

    .editor .w-figure_caption_wrap {
        display: inline-block;
        max-width: 100%
    }

    .editor .w-figure_caption_wrap:after {
        content: attr(placeholder);
        display: none;
        opacity: .25;
        vertical-align: text-bottom
    }

    .editor .w-figure_caption.m_empty .w-figure_caption_wrap:after {
        display: inline-block
    }

    .editor .w-figure.m_resize {
        -moz-user-select: none;
        user-select: none
    }

    .editor .w-figure.m_loading .w-figure_loading {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 32px;
        height: 32px;
        margin: -16px 0 0 -16px;
        border-radius: 100%;
        background-color: var(--color-bg-blur, rgba(255, 255, 255, .6));
        -webkit-backdrop-filter: blur(10px);
        -moz-backdrop-filter: blur(10px);
        backdrop-filter: blur(10px)
    }

    @supports (not ((backdrop-filter: blur(10px)) or (-webkit-backdrop-filter: blur(10px)) or (-moz-backdrop-filter: blur(10px)))) {
        .editor .w-figure.m_loading .w-figure_loading {
            background-color: var(--color-bg-3, #AEAEAE)
        }
    }

    .editor .w-figure.m_loading .w-figure_loading:after {
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        background: var(--color-primary, #1A1919);
        // -webkit-mask-image: url(./preloader.6ee00a28.svg);
        // mask-image: url(./preloader.6ee00a28.svg);
        -webkit-mask-repeat: no-repeat;
        mask-repeat: no-repeat;
        -webkit-mask-position: center;
        mask-position: center;
        background-color: var(--color-primary, #1A1919);
        animation: preloader .7s infinite linear
    }

    .editor .w-figure:hover .w-figure_resize,
    .editor .w-figure.m_focus .w-figure_resize {
        pointer-events: auto;
        opacity: 1
    }

    .editor .w-figure.m_focus .w-figure_wrap {
        opacity: .5
    }

    .editor .tags {
        max-width: var(--document-column-size, auto);
        margin: 0 auto .88235294em;
        min-height: 1.5em;
        font-family: Inter, Helvetica Neue, Helvetica, sans-serif;
        font-stretch: normal;
        font-weight: 400;
        font-style: normal;
        font-size: 1em;
        line-height: 1.5em;
        color: var(--color-primary, #1A1919);
        white-space: pre-wrap;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: flex-start;
        min-width: 0;
        word-wrap: break-word;
        cursor: text
    }

    .editor .tags:first-child {
        margin-top: 0
    }

    .editor .tags:last-child {
        margin-bottom: 0
    }

    .editor .tags[data-align=right] {
        text-align: right
    }

    .editor .tags[data-align=center] {
        text-align: center
    }

    .editor .tags[data-align=center] {
        justify-content: center
    }

    .editor .tags[data-align=right] {
        justify-content: flex-end
    }

    .editor .tag {
        display: inline
    }

    .editor .tag_wrap {
        display: inline-flex;
        margin-right: 10px;
        border: 0;
        color: var(--color-accent-action, #5B41F5);
        text-decoration: none;
        cursor: pointer;
        border-bottom: 0;
        white-space: nowrap
    }

    .editor .tag_wrap:before {
        content: "#";
        position: relative;
        display: inline-block
    }

    .editor .contents_list {
        -webkit-user-select: none;
        -moz-user-select: none;
        user-select: none
    }

    .editor .contents_item {
        pointer-events: none;
        transition: all .2s
    }

    .editor .contents_cursor {
        position: absolute;
        font-size: 1px;
        left: 50%;
        bottom: 10%;
        z-index: -1
    }

    .editor .contents_empty {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        max-width: 100%;
        min-width: 0;
        padding: 16px;
        box-sizing: border-box;
        -webkit-user-select: none;
        -moz-user-select: none;
        user-select: none;
        border-radius: 7px;
        background-color: var(--color-bg-2, #F6F6F6)
    }

    .editor .contents_empty_icon {
        position: relative;
        flex: 0 0 auto;
        width: 32px;
        height: 32px;
        margin-right: 10px;
        border-radius: 8px;
        border: 1px solid currentColor;
        color: var(--color-secondary, #949494);
        opacity: .5
    }

    .editor .contents_empty_text {
        position: relative;
        flex: 1 1 auto;
        min-width: 0;
        max-width: 100%;
        font-family: Inter, Helvetica Neue, Helvetica, sans-serif;
        font-stretch: normal;
        font-weight: 400;
        font-style: normal;
        color: var(--color-secondary, #949494)
    }

    .editor .contents:after {
        content: "";
        position: absolute;
        top: -5px;
        bottom: -5px;
        left: -5px;
        right: -5px;
        border-radius: 7px;
        background-color: var(--color-accent-action, #5B41F5);
        transition: all .4s;
        pointer-events: none;
        opacity: 0
    }

    .editor .contents:hover:after {
        opacity: .05
    }

    .editor .contents.m_focus:after {
        opacity: .12
    }

    .editor .contents.m_empty:after {
        top: 0;
        bottom: 0;
        left: 0;
        right: 0
    }

    .editorDrop {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        text-align: center;
        pointer-events: none;
        z-index: 10;
        opacity: 0;
        transition: opacity .4s
    }

    .editorDrop svg {
        display: block;
        width: 100%;
        max-width: 700px;
        margin: 0 auto;
        pointer-events: none;
        opacity: .3
    }

    .editorDrop svg * {
        fill: var(--color-secondary, #949494)
    }

    .editorDrop div {
        display: block;
        width: 100%;
        height: 4px;
        border-radius: 4px;
        background: var(--color-accent-action, #5B41F5);
        opacity: .3
    }

    .editorDrop.m_show {
        opacity: 1
    }

    .editorCommentMedia {
        position: absolute;
        display: block;
        top: -12px;
        right: -12px;
        width: 24px;
        height: 24px;
        margin: 0;
        padding: 0;
        border-radius: 24px;
        border: 1px solid var(--color-border, #EEEEEE);
        background: var(--color-bg-1, #FFFFFF);
        cursor: pointer;
        transition: all .4s;
        z-index: 1
    }

    .editorCommentMedia__icon {
        display: block;
        width: 100%;
        height: 100%;
        background-color: currentColor;
        // -webkit-mask-image: url(./close.c0e49814.svg);
        // mask-image: url(./close.c0e49814.svg);
        -webkit-mask-repeat: no-repeat;
        mask-repeat: no-repeat;
        -webkit-mask-position: center;
        mask-position: center;
        -webkit-mask-size: 100% 100%;
        mask-size: 100% 100%;
        color: var(--color-primary, #1A1919)
    }

    .editorCommentMedia:hover {
        border-color: var(--color-primary, #1A1919)
    }

    .editor:after {
        content: attr(data-placeholder);
        position: absolute;
        display: none;
        top: 0;
        left: 50%;
        width: 100%;
        overflow: hidden;
        min-width: 0;
        max-width: var(--document-column-size, auto);
        margin-left: auto;
        margin-right: auto;
        box-sizing: border-box;
        text-overflow: ellipsis;
        white-space: nowrap;
        transform: translate(-50%);
        transition: color .4s;
        pointer-events: none;
        user-select: none
    }

    .editor.m_line {
        display: block;
        outline: 0;
        max-width: 100%;
        min-height: 1em;
        line-height: inherit
    }

    .editor.m_line:before {
        content: "#";
        display: none;
        opacity: 0;
        pointer-events: none;
        user-select: none;
        vertical-align: top
    }

    @media screen and (max-width: 680px) {
        .editor.m_line {
            margin-bottom: 20px;
            padding-left: 0;
            padding-right: 0
        }
    }

    .editor:empty:before,
    .editor.m_empty:before,
    .editor:empty:after,
    .editor.m_empty:after {
        display: inline-block
    }

    .editor:empty:focus:after,
    .editor.m_empty:focus:after {
        display: none
    }

    .editor:empty.m_line:focus:after,
    .editor.m_empty.m_line:focus:after {
        display: inline-block
    }

    .editor.m_block {
        min-height: 400px;
        padding-bottom: 250px
    }

    @media screen and (max-width: 680px) {
        .editor.m_block:after {
            padding-left: 16px;
            padding-right: 21px
        }
    }

    .editor.m_error:after {
        color: var(--color-accent-alarm, #F13939) !important
    }

    .editor.m_dragging>* {
        transition: transform .4s
    }
}