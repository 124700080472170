.animationBlock {
    visibility: hidden;

    &.active {
        visibility: visible;
    }
}

@keyframes bottomSwipe {
    0% {
        opacity: 0;
        transform: translateY(10vh);
    }

    35% {
        opacity: 0;
    }

    90% {
        opacity: 1;
    }

    100% {
        transform: translateY(0);
    }
}

@keyframes bottomSwipe {
    0% {
        opacity: 0;
        transform: translateY(10vh);
    }

    35% {
        opacity: 0;
    }

    90% {
        opacity: 1;
    }

    100% {
        transform: translateY(0);
    }
}