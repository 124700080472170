@import '../../../assets/scss/global.scss';


#section__title-black {
    color: #FFFFFF;
}

#header__content-black {
    padding: 160px 0px 36px 0px;
    margin-top: -1px;


    @media (max-width: $breakpoint1200) {

        padding: 160px 0px 30px 0px;
    }

    @media (max-width: $breakpoint480) {
        padding: 160px 0px 30px 0px;
    }


}

#header__black {
    background: #141414;
}

#menu__black {
    background: #141414;
}



// button

.start__copman-button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 10px 47px;
    border: 1px solid #68EC50;
    transition: all ease .3s;
    overflow: hidden;

    @media (max-width: $breakpoint480) {
        padding: 0px 20px;
    }

    &:not(:hover) {
        & .start__copman-button-svg {
            animation: form_close 0.6s ease-in-out;
            animation-fill-mode: forwards;
        }

        & .start__copman-button-text {
            transform: translatex(0%);
        }
    }

    &:hover {

        & .start__copman-button-svg {
            animation: form_open 1s ease-in-out;
            animation-fill-mode: forwards;
        }

        & .start__copman-button-text {
            transform: translatex(-40%);
            // width: 100%;
            // text-align: center;
            color: #141414;
        }


    }

    @keyframes form_open {

        from {
            transform: rotate(0deg);
        }

        40% {
            transform: rotate(40deg);
        }

        to {
            transform: rotate(40deg) scale(20);

        }

    }

    @keyframes form_close {
        from {
            transform: rotate(40deg) scale(15);
        }

        40% {
            transform: rotate(0deg);
        }

        to {
            transform: rotate(0deg) scale(1);

        }
    }

    &-svg {
        transition: all ease .3s;
    }

    &-text {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 73px;
        letter-spacing: -0.02em;
        color: #828282;
        transition: all ease 1s;


        @media (max-width: $breakpoint480) {
            font-size: 20px;
        }

    }

}

.section__brend {
    background-color: #141414;
}

.section__desc {

    & .approach {
        display: flex;
        flex-direction: column;
        gap: 40px;

        padding-top: 100px;

        @media (max-width: $breakpoint1200) {
            padding-top: 60px;

            gap: 30px;
        }



        &__title {
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 700;
            font-size: 40px;
            line-height: 46px;
            letter-spacing: -0.8px;

            color: #141414;

            @media (max-width: $breakpoint770) {
                font-size: 32px;
            }

            @media (max-width: $breakpoint480) {
                font-size: 25px;
                line-height: 140%;
            }
        }

        &__subtitle {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 24px;
            line-height: 36px;
            letter-spacing: -0.5px;
            color: #141414;
            max-width: 850px;

            @media (max-width: $breakpoint770) {
                font-size: 22px;
            }

            @media (max-width: $breakpoint480) {
                font-size: 20px;
            }

            &-link {

                font-family: 'Roboto';
                font-style: normal;
                font-weight: 400;
                font-size: 24px;
                line-height: 36px;
                letter-spacing: -0.5px;
                text-decoration: underline;
                color: #141414;
                transition: all ease .3s;



                &:hover {
                    color: #75FB5E;
                }
            }

        }

    }

    & .strategy {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 30px;


        padding-top: 90px;

        @media (max-width: $breakpoint1200) {
            padding-top: 60px;
        }

        @media (max-width: $breakpoint770) {
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
        }

        &.c2 {
            .strategy__item {
                min-width: 40%;
            }
        }

        &.c3 {
            .strategy__item {
                min-width: 30%;
            }
        }


        &__item {
            display: flex;
            flex-direction: column;

            &-label {
                margin-bottom: 3px;
                width: fit-content;

                & img {
                    margin-top: -15px;

                    @media (max-width: $breakpoint770) {
                        max-width: 200px;
                    }

                    @media (max-width: $breakpoint480) {
                        max-width: 180px;
                        margin-top: -10px;
                    }

                }

            }

            &-title2 {
                font-family: 'Montserrat';
                font-style: normal;
                font-weight: 700;
                font-size: 30px;
                line-height: 140%;
                letter-spacing: -0.8px;
                color: #141414;



                @media (max-width: $breakpoint770) {
                    font-size: 28px;
                }

                @media (max-width: $breakpoint480) {
                    font-size: 25px;
                }



            }


            &-title {
                font-family: 'Montserrat';
                font-style: normal;
                font-weight: 700;
                font-size: 30px;
                line-height: 140%;
                letter-spacing: -0.8px;
                color: #141414;
                margin-bottom: 13px;
                width: fit-content;
                overflow: hidden;

                // подчеркивание


                @media (max-width: $breakpoint770) {
                    font-size: 28px;
                }

                @media (max-width: $breakpoint480) {
                    font-size: 25px;
                    margin-bottom: 10px;
                }



            }

            &-subtitle {
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 400;
                font-size: 24px;
                line-height: 36px;
                letter-spacing: -0.5px;
                color: #141414;

                &::before {
                    content: "— ";
                }



                @media (max-width: $breakpoint770) {
                    font-size: 22px;
                }

                @media (max-width: $breakpoint480) {
                    font-size: 20px;

                }

            }

        }

    }

    & .addService {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        row-gap: 3rem;

        @media (max-width: $breakpoint1200) {
            grid-template-columns: 1fr 1fr;
            column-gap: 3rem;
            row-gap: 1.5rem;
        }

        @media (max-width: $breakpoint770) {
            grid-template-columns: 1fr;
        }

        .strategy__item-subtitle {
            font-size: 18px;
        }

        .strategy__item-label {
            overflow: hidden;
        }

        .strategy__item-title {
            font-size: 25px;
            margin-bottom: 2px;
            overflow: hidden;
            cursor: pointer;

            &::after {
                content: "";
                display: block;
                width: 100%;
                height: 2px;
                transition: .2s;
                overflow: hidden;
                transform: translateX(-10%);
                background-color: #747b73;
                margin-top: 2px;
            }
        }

        .strategy__item-subtitle::before {
            content: "";
        }

        .strategy__item:hover {

            & .strategy__item-title::after {
                content: "";
                display: block;
                transform: translateX(0%);
                background-color: #75FB5E;
            }
        }

    }


    & .copm {
        margin-top: 60px;
        background: #FAFAFA;

        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        &__svg {
            flex: 0 1 18%;
            height: auto;
            width: 100%;

            @media (max-width: $breakpoint992) {
                max-width: 200px;
            }

            @media (max-width: $breakpoint480) {
                max-width: 150px;

            }

            animation: blink random()*2+1s ease-in-out infinite;

            @keyframes blink {

                from {
                    opacity: .6;
                }

                50% {
                    opacity: 1;

                }

                to {
                    opacity: .6;
                }
            }


        }

    }

}


.section__clients {

    padding: 100px 0px;

    @media (max-width: $breakpoint1200) {
        padding: 80px 0px;
    }

    @media (max-width: $breakpoint770) {
        padding: 60px 0px;
    }

    & .clients {

        display: flex;
        flex-direction: column;
        align-items: center;


        &__title {
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 700;
            font-size: 40px;
            line-height: 46px;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            letter-spacing: -0.8px;

            color: #141414;

            @media (max-width: $breakpoint770) {
                font-size: 32px;
            }

            @media (max-width: $breakpoint480) {
                font-size: 24px;
            }

        }

        &__item {
            margin-top: 50px;

            display: flex;
            flex-direction: column;

            &-title {
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 400;
                font-size: 30px;
                line-height: 36px;
                letter-spacing: -0.4px;

                color: #141414;

                max-width: 825px;

                @media (max-width: $breakpoint770) {
                    font-size: 25px;
                }

                @media (max-width: $breakpoint480) {
                    font-size: 21px;
                }
            }

            &-info {
                display: flex;
                align-items: center;
                gap: 16px;

                margin-top: 23px;

                &-titles {


                    &-title {
                        font-family: 'Roboto';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 20px;
                        line-height: 28px;
                        letter-spacing: -0.42px;

                        color: #141414;

                        @media (max-width: $breakpoint480) {
                            font-size: 18px;
                        }

                    }

                    &-subtitle {
                        font-family: 'Roboto';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 20px;
                        line-height: 24px;
                        letter-spacing: -0.7px;

                        color: rgba(34, 34, 34, 0.6);

                        @media (max-width: $breakpoint480) {
                            font-size: 18px;
                        }

                    }

                }

            }
        }

    }

}