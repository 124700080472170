@import '../../../../assets/scss/global.scss';

.a {
    // display: block;
    line-height: 15px;
}

.HeaderNews {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 4rem;
    margin-bottom: 20px;
    font-family: "Soyuz Grotesk PRO", Arial, Helvetica, sans-serif;
    font-style: normal;
    font-size: 66px;
    letter-spacing: 0.025em;
    color: #000000;

    @media (max-width: $breakpoint992) {
        grid-template-columns: 1fr;
        grid-gap: 2rem;
    }

    .main {
        // padding-top: 20px;
        display: flex;
        flex-direction: column;

        .a {
            line-height: 30px;

            @media (max-width: $breakpoint770) {
                line-height: 20px;
            }



        }


        // gap: 30px;
        .desc {
            display: flex;
            flex-direction: column;
            margin-top: 2rem;
            gap: 10px;

            span {
                font-size: 46px;

                @media (max-width: $breakpoint770) {
                    font-size: 26px;
                }
            }

            .time {
                font-size: 18px;

                @media (max-width: $breakpoint770) {
                    font-size: 14px;
                }

                span {
                    font-size: inherit;
                }
            }
        }
    }

    .desc {
        display: flex;
        flex-direction: column;
        margin-top: 1.2rem;
        gap: 15px;

        @media (max-width: $breakpoint770) {
            margin-top: 1rem;
        }


        .dsc {
            background: linear-gradient(0deg, #000000 0%, #000000 0%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }

        .a:hover .dsc {
            cursor: pointer;
            color: #76fb5e;
            // градиент
            background: linear-gradient(45deg, #5aba45 0%, #4ce431 100%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }






        span {
            font-size: 24px;

            @media (max-width: $breakpoint770) {
                font-size: 18px;
            }
        }

        .time {
            margin-top: 0px;
            font-size: 18px;
            color: #9E9E9E;
            display: flex;
            align-items: center;
            gap: 10px;

            span {
                font-family: 'Courier New', Courier, monospace;
                font-size: inherit;
            }
        }
    }

    // первый блок с классом desc
    .desc:first-child {
        margin-top: 0;
    }

    .dopNews {
        display: flex;
        flex-direction: column;
    }

    img {
        object-fit: cover;
        object-position: center center;
        max-height: 250px;
        border-radius: 4px;
        width: 100%;

        @media (max-width: $breakpoint992) {
            max-height: 200px;
        }


    }

    .dopNewses {
        display: flex;
        flex-direction: column;
        grid-gap: 20px;
    }
}