.BoobleLoader {
    visibility: visible;
    width: 100vw;
    height: 100vh;
    max-width: 100vw;
    max-height: 100vh;
    position: fixed;
    z-index: 999999999;
    overflow: hidden;
    will-change: transform;
}