@import './reset.scss';
@import './vars.scss';
@import './fonts.scss';



.loader {
    transition: .8s;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    background-color: #555555;
    position: relative;
    position: fixed;
    z-index: 999999999;

    transform: scale(1);
    opacity: 1;

    &__inner {
        transition: .3s;
        display: contents;
        opacity: 1;
    }

    &.end {
        transform: scale(1.5);
        opacity: 0;
        border-radius: 20%;

        .loader__inner {
            transition: .3s;
            display: contents;
            opacity: 0;
        }

        &.ender {
            display: none;
        }

        // visibility: hidden;
    }
}


.tager {
    font-size: 25.5px;
    font-weight: bold;
}



// Container
.container {
    max-width: ($containerWidth + $containerPadding) + px;
    width: 100%;
    margin: 0 auto;
    padding: 0 $containerSidePadding + px;

    @media (max-width: $breakpoint1450) {
        max-width: 1200px;
    }

    @media (max-width: $breakpoint1200) {
        max-width: 1140px;
    }

    @media (max-width: $breakpoint992) {
        max-width: 970px;
    }

    @media (max-width: $breakpoint770) {
        max-width: 735px;
    }

    @media (max-width: $breakpoint480) {
        max-width: none;
    }

    @media (max-width: $breakpoint375) {
        max-width: none;
    }

    @media (max-width: $breakpoint300) {
        max-width: none;
    }
}

// Body Locked
body {
    &.locked {
        overflow: hidden;
    }
}



// Animations
// Fade
@keyframes fade {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}



// h1 тектс

.section {

    &__title {
        font-family: 'Soyuz Grotesk PRO';
        font-style: normal;

        font-size: 66px;
        letter-spacing: 0.025em;
        color: #000000;
        z-index: 10;
        width: 80%;
        // display: flex;
        // align-items: flex-start;
        gap: 5px;

        &.ss {
            display: block;
        }


        @media (max-width: $breakpoint1200) {
            width: 100%;
            font-size: 55px;
            line-height: 60px;

            &.ss {
                display: flex;
                align-items: flex-start;
            }
        }

        @media (max-width: $breakpoint770) {
            font-size: 34px;
            line-height: 140%;
        }

        @media (max-width: $breakpoint480) {
            flex-wrap: wrap;
            line-height: 50px;
            font-size: 27px;
        }



        & div {
            display: flex;
            align-items: center;
            margin-left: 10px;

            @media (max-width: $breakpoint480) {
                margin-left: 0px;
            }

            & .section__title-span {
                font-size: 86px;

                @media (max-width: $breakpoint1200) {
                    font-size: 75px;

                }

                @media (max-width: $breakpoint770) {
                    font-size: 54px;
                    margin-right: 2px;


                }

                @media (max-width: $breakpoint480) {
                    font-size: 47px;
                    margin-right: 2px;

                }
            }
        }

        &-span {

            font-family: 'Street Soul Cyrillic', arial;
            color: #75FB5E;
            font-style: normal;
            font-weight: 400;
            font-size: 66px;
            line-height: 40px;
            letter-spacing: 0.065em;
            // margin-right: -20px;
            margin: 0;
            margin-left: 5px;

            @media (max-width: $breakpoint1200) {
                font-size: 55px;

            }

            @media (max-width: $breakpoint770) {
                font-size: 34px;
                margin-right: 2px;


            }

            @media (max-width: $breakpoint480) {
                font-size: 27px;
                margin-right: 2px;

            }

        }

        &-span2 {

            font-family: 'Street Soul Cyrillic', arial;
            color: #75FB5E;
            font-style: normal;
            font-weight: 400;
            font-size: 120px;
            line-height: 10px;
            letter-spacing: 0.015em;
            margin-right: 0px;
            margin-left: 5px;
            transform: translateY(200%);

            @media (max-width: $breakpoint1200) {
                font-size: 130px;

            }

            @media (max-width: $breakpoint770) {
                font-size: 70px;
                margin-right: 2px;
            }

            @media (max-width: $breakpoint480) {
                font-size: 70px;
                margin-right: 2px;

            }

        }


    }


}

// button

.start__copman-button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 10px 47px;
    border: 1px solid #68EC50;
    transition: all ease .3s;
    overflow: hidden;

    @media (max-width: $breakpoint480) {
        padding: 0px 20px;
    }

    & img,
    & p {
        pointer-events: none;
    }

    &:not(:hover) {
        & .start__copman-button-svg {
            animation: form_close 0.6s ease-in-out;
            animation-fill-mode: forwards;
        }

        & .start__copman-button-text {
            transform: translatex(0%);
        }
    }

    &:hover {

        & .start__copman-button-svg {
            animation: form_open 1s ease-in-out;
            animation-fill-mode: forwards;
        }

        & .start__copman-button-text {
            transform: translatex(-40%);
            // width: 100%;
            // text-align: center;
            color: #141414;
        }


    }

    @keyframes form_open {

        from {
            transform: rotate(0deg);
        }

        40% {
            transform: rotate(40deg);
        }

        to {
            transform: rotate(40deg) scale(20);

        }

    }

    @keyframes form_close {
        from {
            transform: rotate(40deg) scale(15);
        }

        40% {
            transform: rotate(0deg);
        }

        to {
            transform: rotate(0deg) scale(1);

        }
    }

    &-svg {
        transition: all ease .3s;
    }

    &-text {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 73px;
        letter-spacing: -0.02em;
        color: #828282;
        transition: all ease 1s;


        @media (max-width: $breakpoint480) {
            font-size: 20px;
        }

    }

}