// импортировать global.scss
@import '../../assets/scss/global.scss';

.maker {
    display: flex;
    flex-wrap: nowrap;
    gap: 15px;
    justify-content: center;
    align-items: center;
    padding: 10px 0px;

    span {
        color: black;
        font-size: 12px;
        font-family: 'Montserrat' !important;
    }
}