// импортировать global.scss
@import '../../assets/scss/global.scss';

body {
    &[theme="dark"] {
        background-color: $dark-theme;
    }
}

.header {

    // атрибут theme = dark
    &[theme="dark"] {
        background-color: $dark-theme;

        & .burger::before,
        .burger span,
        .burger::after {
            background: white !important;
        }

        & .menu__item-link {
            color: #e4e4e4 !important;
        }

        & .menu__item svg path {
            fill: #828282 !important;
        }

        & .menu__item .cols .col>strong {
            color: #e4e4e4 !important;
        }

        @media (min-width: $breakpoint770) {
            & .menu__item .cols {
                background-color: #242424 !important;
            }
        }

        & .menu__item:not(:last-child)::before {
            background-color: #8f8f8f1a !important;
        }
    }

    &[theme="lightNews"] {
        position: fixed;
        top: 0;
        width: 100vw;
        effect: blur(1px);
        background-color: #232323;
        // gradientz
        z-index: 999999999;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);

        & .burger::before,
        .burger span,
        .burger::after {
            background: white !important;
        }

        & .menu__item-link {
            color: #e4e4e4 !important;
        }

        & .menu__item svg path {
            fill: #828282 !important;
        }

        & .menu__item .cols .col>strong {
            color: #e4e4e4 !important;
        }

        @media (min-width: $breakpoint770) {
            & .menu__item .cols {
                background-color: #242424 !important;
            }
        }

        & .menu__item:not(:last-child)::before {
            background-color: #8f8f8f1a !important;
        }
    }



    @media screen and (min-width: $burgerBreakpoint) {
        .hd {
            display: flex;
            align-items: center;
        }
    }

    .header {

        &__content {
            padding: 160px 0px 300px 0px;

            @media (max-width: $breakpoint1200) {
                font-size: 55px;
                line-height: 60px;
                padding: 160px 0px;
            }

            @media (max-width: $breakpoint480) {
                padding: 140px 0px 60px 0px;
            }


        }

    }

    .logo {
        z-index: 1000;
    }

    .navbar {

        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        padding: 31px 0px;


        @media (max-width: $breakpoint480) {
            padding: 20px 0px;
        }

    }


    .menu {
        display: flex;
        align-items: center;
        gap: 10px;

        @media (max-width: $breakpoint770) {


            gap: 20px;

            .secondary-nav {
                cursor: pointer;

                svg {
                    transition: .3s;
                }
            }

            .menu__item>a,
            .menu__item-link {
                font-size: 28px;
                color: #000;

            }

            .cols {
                color: #dbdbdb;
                display: block;
                transition: max-height 1s;
                max-height: 0px;

                .col {
                    padding: 10px 0px 0px 0px !important;

                    ul {
                        padding-left: 20px !important;
                    }
                }

                &.is-visible {
                    display: block;
                    max-height: 100vh;


                }

                strong {
                    font-size: 18px !important;
                    font-family: "Roboto";
                    font-style: normal;
                    font-weight: 400;
                    color: #000000a6 !important;
                }
            }
        }

        &__item {
            position: relative;
            padding: 9px 16px;
            overflow: hidden;
            white-space: nowrap;

            @media (min-width: $breakpoint770) {

                // всем кроме последнего
                &:not(:last-child) {
                    &::before {
                        content: "";
                        left: 0;
                        top: 0;
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        background-color: rgba(0, 0, 0, .04);
                        border-radius: 3px;
                        transition: all 0.3s ease;
                        // transform: translateZ(-100%);
                        transform: translateY(100%);
                    }
                }



                // если первый
                &:first-child svg {

                    display: none;
                }

                &:first-child:hover {
                    overflow: inherit;


                    .cols {
                        display: flex;
                    }
                }

                &:hover::before {
                    transform: translateY(0);
                }
            }

            & .cols {
                @media (min-width: $breakpoint770) {
                    background: #fafafa;
                    border: 1px solid rgba(20, 20, 20, 0.12);
                    border-radius: 4px;
                    display: none;
                    // display: flex;
                    position: relative;
                    left: -24px;
                    list-style: none;
                    margin: 0;
                    min-height: 300px;
                    padding: 40px 8px;
                    position: absolute;
                    top: 100%;
                    z-index: 999999;
                }

                .col {
                    padding: 0 32px;
                    width: 200px;

                    &>strong {
                        font-size: 24px;
                        line-height: 1.5;
                        font-family: "Roboto";
                        font-style: normal;
                        font-weight: 700;
                        color: black;
                    }

                    &+.col {
                        @media (min-width: $breakpoint770) {
                            border-left: 1px solid #d9d9d9;
                        }
                    }
                }



                ul {
                    list-style: none;
                    margin: 16px 0 0;
                }

                li {
                    line-height: 1.2;
                    margin: 0;
                    -webkit-tap-highlight-color: transparent;
                    border: none;
                    box-sizing: border-box;
                    margin: 0;
                    outline: none;
                    padding: 0;

                    a {
                        display: block;
                        color: #828282;
                        cursor: pointer;
                        text-decoration: none;
                        transition: color .3s ease-in-out;
                        font-family: "Roboto";
                        font-style: normal;
                        font-weight: 300;
                    }

                    &:hover {
                        a {
                            color: #52b740;
                        }
                    }
                }

                li:not(:first-child) {
                    margin-top: 16px;
                }

            }


        }

        // .menu__item-link
        &__item-link {

            font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 32px;
            // letter-spacing: -0.4px;
            color: #828282;
            transition: all ease .3s;
        }
    }

    .burger {
        display: none;
        cursor: pointer;
    }

    // Burger menu styles
    @media screen and (max-width: $burgerBreakpoint) {
        .rheader.active {
            height: var(--app-height_mh);
            overflow: hidden;
            overflow-y: scroll;
            // padding-bottom: 10px;
        }

        // .menu
        .menu {
            overflow: scroll;
            // position: fixed;
            background: inherit;
            left: 0;
            top: 0;
            height: 100vh;
            width: 100vw;
            display: none;
            padding-right: 30px;

            gap: 0px;

            &__item {
                transition: .3s;
                transform: translateX(-15vw);
                opacity: 0;
            }

            &.active {
                display: flex;
                left: 0;
                top: 0;
                height: inherit;
                width: 100vw;
                flex-direction: column;
                pointer-events: all;
                justify-content: center;
                // align-items: flex-end;
                align-items: flex-start;
                z-index: 22;
                animation: fade 0.1s linear;

                &.active_block {
                    padding-bottom: 40px;
                }
            }
        }

        // .burger
        .burger {
            display: block;
            position: relative;
            height: 20px;
            width: 30px;
            z-index: 102;
            cursor: pointer;

            &.active-burger {
                &:before {
                    background: $burgerActiveColor;
                    transform: rotate(45deg);
                    transition: cubic-bezier(0.785, 0.135, 0.15, 0.86) 0.3s all;
                    top: 8px;
                }

                &:after {
                    background: $burgerActiveColor;
                    transform: rotate(-45deg);
                    bottom: 10px;
                    transition: cubic-bezier(0.785, 0.135, 0.15, 0.86) 0.3s all;
                }

                & span {
                    transform: scale(0);
                }
            }

            &:before,
            &:after {
                content: '';
                position: absolute;
                height: 2px;
                width: 100%;
                background: $burgerColor;
                transition: cubic-bezier(0.785, 0.135, 0.15, 0.86) 0.3s all;
            }

            &:before {
                top: 0;
            }

            &::after {
                bottom: 0;
            }

            span {
                position: absolute;
                top: 9px;
                right: 0;
                width: 100%;
                background: $burgerColor;
                height: 2px;
                transform: scale(1);
                transition: cubic-bezier(0.785, 0.135, 0.15, 0.86) 0.3s all;
            }
        }


        // .menu__link
        .menu__item-link {
            color: #828282;
            font-size: 26px;
        }
    }
}