@import '../../../assets/scss/global.scss';

.nes {
    width: 70vw;
    margin: 0 auto;

    @media (max-width: $breakpoint992) {
        width: 90vw;
    }

    .line {
        margin-top: 0px;
        margin-bottom: 40px;
    }

    .menu {
        max-width: 100%;
        overflow: scroll;
        padding-bottom: 1rem;

        .items {
            white-space: nowrap;
            margin-top: 10px;
            display: flex;
            flex-direction: row;
            gap: 1em;


            .ds {
                color: rgb(91, 87, 87);
                font-size: .9em;
                font-family: "Roboto", Courier, monospace;
                font-weight: 400;
                // word-wrap: normal;
                white-space: nowrap;

                &:hover {
                    cursor: pointer;
                    // color: #68EC50;
                    position: relative;

                    &::after {
                        content: '';
                        position: absolute;
                        width: 100%;
                        height: 2px;
                        bottom: -1.05rem;
                        left: 0px;
                        background: #68EC50;
                        // margin-top: px;
                    }
                }

            }
        }
    }
}