@import '../../assets/scss/global.scss';


.brend {

    display: grid;
    gap: 40px;

    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr;

    padding: 60px 0px 60px 0px;

    @media (max-width: $breakpoint1450) {
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: 1fr;
    }

    @media (max-width: $breakpoint1200) {
        grid-template-columns: 1fr 1fr 1fr;
    }

    @media (max-width: $breakpoint770) {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
    }


    @media (max-width: $breakpoint1200) {
        padding: 60px 0px 30px 0px;
    }

    &__text {
        font-family: 'Segoe UI';
        font-style: normal;
        font-weight: 400;
        width: 70%;
        font-size: 24px;
        line-height: 36px;
        letter-spacing: -0.5px;
        color: #FFFFFF;

        @media (max-width: $breakpoint480) {
            font-size: 20px;
        }

        @media (max-width: $breakpoint770) {
            width: 100%;
        }

        &-link {
            font-family: 'Segoe UI';
            font-style: normal;
            font-weight: 400;
            font-size: 24px;
            line-height: 36px;
            letter-spacing: -0.5px;
            color: #FFFFFF;
            text-decoration: underline;
            transition: all ease .3s;

            @media (max-width: $breakpoint480) {
                font-size: 20px;
            }


            &:hover {
                color: #75FB5E;
            }

        }

    }

    // @media (max-width: $breakpoint992) {

    // первые 3 элементы с классом brend
    .brend__item:not(:nth-child(-n+3)) {
        display: none;
    }

    // }

    &__item {

        flex: 0 1 30%;
        width: 100%;
        max-width: 100%;
        overflow: hidden;

        .imgs {
            border-radius: 10px;
            overflow: hidden;

            position: relative;


            .brend__item-img,
            video {
                width: 100%;
                height: 265px;
                top: 0;
                left: 0;

                @media (max-width: $breakpoint992) {
                    height: 100%;
                }
            }

        }

        @media (max-width: $breakpoint1200) {
            flex: 0 1 45%;
        }

        @media (max-width: $breakpoint770) {
            flex: 0 1 100%;
        }

        &:hover {

            & .brend__item-img {

                // transform: scale(1.05);

            }

            & .brend__item-title {
                color: #75FB5E;
            }

        }


        &-img {
            border-radius: 9px;
            transition: all ease .3s;
            width: 100%;

            @media (max-width: $breakpoint1200) {
                flex: 0 1 100%;
                width: 100%;
                height: auto;
            }

        }

        &-title {
            transition: all ease .3s;
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 700;
            font-size: 35px;
            line-height: 42px;
            padding: 20px 0px;
            letter-spacing: -0.4px;
            display: flex;
            column-gap: 6px;
            color: #FFFFFF;
            align-items: center;

            @media (max-width: $breakpoint770) {
                font-size: 24px;
            }
        }

        &-subtitle {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 25px;
            letter-spacing: -0.5px;

            color: #8F8F8F;

            @media (max-width: $breakpoint480) {
                font-size: 18px;
            }
        }

    }

}