.flag {

    display: block;
    width: 30px;
    height: 30px;

    &[flag="ru"] {
        background-image: url('../../assets/img/flags/rus.svg');
        background-repeat: no-repeat;
        background-position: center;
    }

    &[flag="en"] {
        background-image: url('../../assets/img/flags/en.svg');
        background-repeat: no-repeat;
        background-position: center;
    }
}