@font-face {
    font-family: 'street_soul_cyrillicregular';
    src: url('../fonts/streetsoulcyrillic_regular-webfont.woff2') format('woff2'),
        url('../fonts/streetsoulcyrillic_regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'soyuz_groteskbold';
    src:
        url('../fonts/soyuz_grotesk_bold-webfont.woff2') format('woff2'),
        url('../fonts/soyuz_grotesk_bold-webfont.woff') format('woff'),
        url('../fonts/SoyuzGroteskBold.otf') format('otf');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Soyuz Grotesk PRO';
    src: url('../fonts/Soyuz Grotesk Bold.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}



@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@700&family=Roboto:wght@100;200;300;400;700;900&display=swap');