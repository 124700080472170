// импортировать global.scss
@import '../../assets/scss/global.scss';


.section__header {
    padding: 160px 0px 300px 0px;

    @media (max-width: $breakpoint1200) {
        font-size: 55px;
        line-height: 60px;
        padding: 160px 0px;
    }

    @media (max-width: $breakpoint480) {
        padding: 140px 0px 60px 0px;
    }


}



.start {

    display: flex;
    justify-content: space-between;

    @media (max-width: $breakpoint1200) {
        gap: 60px;
    }

    @media (max-width: $breakpoint992) {
        flex-direction: column;
    }


    &__item {

        &-subtitle {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 24px;
            line-height: 34px;
            letter-spacing: -1.2px;
            color: #141414;

            max-width: 520px;
            margin: 50px 0px;



            @media (max-width: $breakpoint992) {
                max-width: 100%;
                margin: 30px 0px;
            }

            @media (max-width: $breakpoint480) {
                margin: 20px 0px;
                font-size: 20px;
            }

        }

        & .link-hover {
            max-width: fit-content;
        }

        & .link-hover:hover {

            & .start__item-links-link {
                color: #73F85B;
            }

            & .start__item-links-link-hr {
                border: 1.5px solid #73F85B;
            }

        }

        &-links {
            display: flex;
            flex-direction: column;
            gap: 20px;

            @media (max-width: $breakpoint480) {
                gap: 15px;
            }

            &-link {
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 400;
                font-size: 24px;
                line-height: 36px;
                letter-spacing: -0.5px;
                color: #828282;
                max-width: max-content;

                position: relative;
                transition: all ease .3s;

                @media (max-width: $breakpoint480) {
                    font-size: 20px;
                }


                &-hr {
                    position: absolute;
                    width: 100%;
                    background: #828282;
                    border: 1px solid #A4A4A4;

                    transition: all ease .3s;
                }

            }
        }

    }

    &__copman {
        max-width: 530px;
        width: 100%;
        height: 410px;

        display: flex;
        flex-wrap: wrap;

        border-top: 1px solid rgba(219, 219, 219, 0.5);

        border-right: 1px solid rgba(219, 219, 219, 0.5);

        border-left: 1px solid rgba(219, 219, 219, 0.5);

        @media (max-width: $breakpoint992) {
            max-width: 100%;
            width: 100%;
            justify-content: space-between;
        }

        @media (max-width: $breakpoint560) {
            height: 340px;
        }



        &-svg {
            height: 100px;
            transition: background-color .3s ease-in-out;

            @media (max-width: $breakpoint560) {
                max-width: 170px;
                height: 60px;
                transform: scale(1.3);
            }

            &:hover {
                cursor: pointer;
                opacity: 1 !important;
                transition: 0.3s;
                animation: none !important;
                background-color: #c6c6c618;
                border-radius: 12px;
            }

            @keyframes blink {

                from {
                    opacity: .3;
                }

                50% {
                    opacity: 1;

                }

                to {
                    opacity: .3;
                }
            }
        }



    }

}

#section__static {
    padding: 150px 0px 0px 0px;

    @media (max-width: $breakpoint770) {
        padding: 80px 0px 0px 0px;
    }

    @media (max-width: $breakpoint480) {
        padding: 60px 0px 0px 0px;
    }

}

.section__static {
    margin-top: 103px;
    padding: 150px 0px 220px 0px;

    @media (max-width: $breakpoint992) {
        padding: 130px 0px;
    }

    @media (max-width: $breakpoint770) {
        padding: 80px 0px 60px 0px;
    }

    @media (max-width: $breakpoint480) {
        padding: 60px 0px 0px 0px;
    }




    & .static {
        display: flex;
        line-height: 140%;
        padding: 20px 0px;
        gap: 50px;

        @media (max-width: $breakpoint1200) {
            gap: 20px;
        }

        @media (max-width: $breakpoint770) {
            flex-direction: column;
            gap: 60px;
        }

        &__item {
            background-image: url('../../assets/img/border__static.jpg');
            background-size: 100% 100%;
            background-repeat: no-repeat;
            width: 100%;
            padding: 100px 50px;
            overflow: hidden;

            display: flex;
            flex-direction: column;

            @media (max-width: $breakpoint1200) {
                padding: 70px 30px;
                flex: 0 1 50%;
                width: 100%;
            }

            @media (max-width: $breakpoint992) {
                padding: 40px 30px;
            }

            @media (max-width: $breakpoint560) {
                padding: 30px 20px;
            }

            &-title {
                font-family: 'Soyuz Grotesk PRO';
                font-style: normal;
                font-weight: 700;
                font-size: 66px;
                line-height: 76px;
                // letter-spacing: -2px;
                color: #141414;

                @media (max-width: $breakpoint1200) {
                    font-size: 46px;
                }

                @media (max-width: $breakpoint992) {
                    line-height: 140%;
                    font-size: 65px;
                }

                @media (max-width: $breakpoint560) {
                    font-size: 50px;
                }

                & .title-span {
                    font-family: 'Montserrat';

                }

            }

            &-subtitle {
                font-family: 'Montserrat';
                font-style: normal;
                font-weight: 700;
                font-size: 30px;
                line-height: 42px;
                letter-spacing: -0.4px;

                color: #141414;

                @media (max-width: $breakpoint1200) {
                    font-size: 18px;
                }

                @media (max-width: $breakpoint992) {
                    line-height: 140%;
                }

                @media (max-width: $breakpoint560) {
                    font-size: 18px;
                }
            }

            &-text {
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 400;
                font-size: 20px;
                line-height: 30px;
                letter-spacing: -0.5px;
                padding: 23px 0px 35px 0px;
                color: #141414;
                max-width: 400px;


                @media (max-width: $breakpoint992) {
                    line-height: 140%;
                    padding: 20px 0px;
                }

            }

            &-info {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                max-width: 100%;

                img {
                    width: 130px;
                }

                @media (max-width: $breakpoint1200) {}

                & .static__label:hover {

                    & .static__item-info-link {

                        color: #68EC50;
                        transition: all ease .3s;

                    }

                    & .static__item-info-link-hr {

                        border: 1px solid #68EC50;
                        transition: all ease .3s;

                    }

                }



                &-link {

                    font-family: 'Roboto';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 20px;
                    line-height: 36px;
                    letter-spacing: -0.5px;
                    max-width: fit-content;

                    color: #141414;
                    transition: all ease .3s;


                    &-hr {
                        border: 1px solid #A6A6A6;
                        width: 100%;
                        transition: all ease .3s;

                    }

                }

            }

        }

    }

}