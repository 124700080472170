@import '../../assets/scss/global.scss';

.section__title-num {

    max-width: 900px;
    width: 100%;
    display: flex;

    justify-content: center;
    align-items: center;

    background-image: url('../../assets/img/ERROR.svg');
    background-size: contain;

    background-repeat: no-repeat;
    background-position-x: center;


    &-svg {
        width: 180px;
        height: 258px;
        transform: scale(1.1);
        margin-left: -24px;
        margin-right: -20px;
        z-index: 11;

        @media (max-width: $breakpoint770) {
            width: 100px;
            margin-left: -10px;
            margin-right: -10px;
            transform: scale(1);
            height: auto;

        }

    }

    &-p {
        font-family: 'Soyuz Grotesk PRO';
        font-style: normal;
        font-weight: 700;
        font-size: 300px;
        letter-spacing: 0.025em;
        color: #fff;
        z-index: 0;

        // всем но не первому
        &:not(:first-child) {
            transform: translateY(50px) rotate(180deg);
        }

        @media (max-width: $breakpoint770) {
            font-size: 140px;
            transform: translateY(27px) rotate(180deg);

        }
    }

}

.section__erorr {
    height: 90vh;
    width: 100%;
    background: $dark-theme;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;

    & .erorr {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;






        &__text {
            font-family: 'Segoe UI';
            font-style: normal;
            font-weight: 400;
            font-size: 24px;
            line-height: 36px;
            text-align: center;
            letter-spacing: -0.5px;

            color: #FFFFFF;

            margin: 45px 0px;

            @media (max-width: $breakpoint1200) {
                margin: 20px 0px;
            }

            @media (max-width: $breakpoint480) {
                max-width: 300px;
            }

        }

        &__btn {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 24px;
            line-height: 36px;
            letter-spacing: -0.5px;

            color: #828282;
            padding: 14px 64px;
            border: 1px solid #939393;
            background: #00000000;
            transition: all ease .3s;

            &:hover {
                background-color:
                    #75FB5E;
                color: #000;
                border: 1px solid #75FB5E;

            }

        }

    }

}